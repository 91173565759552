/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router'

import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'

import { translateApiValue } from '../utils'
import PriceAdvisor from '../ui/PriceAdvisor'
import PartnersHeader from '../utils/partners-header'
import { redirectToApp } from '../utils/redirectToApp'
import Ql from '../../api/ql'
import Layout from '../layout'
import Icons from '../ui/Icons'

class PolicyList extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()

    this.state = {
      loading: false,
      typeSelector: false,
      policiesExternal: null,
      policiesImported: null,
      policiesMudey: null,
      goTypeform: false,
      email: '',
      firstname: '',
      userid: '',
      selectedFilter: 'ACTIVE'
    }

    this.goTo = this.goTo.bind(this)
    this.getPoliciesList = this.getPoliciesList.bind(this)
    // this.setFiltersFunc=this.setFiltersFunc(this)
  }

  componentDidMount() {
    this.getPoliciesList()
    this.getUserData()
    // eslint-disable-next-line new-cap
    // window.Intercom('update')
    window.onbeforeunload = function() {
      localStorage.removeItem('filterValue')
      // return e.preventDefault()
      // return ''
    }
    if (!this.props?.isPhoneValidAndVerified) {
      return this.props.history.push('/verify-number')
    }
  }
  componentWillUnmount() {
    localStorage.removeItem('filterValue')
  }

  getPoliciesList() {
    this.setState({ loading: true })
    new Ql('userPolicies')
      .getFilters()
      .then(response => {
        this.setState({
          policiesExternal: response.data.userPoliciesGet.policiesExternal
        })
        this.setState({
          policiesMudey: response.data.userPoliciesGet.policiesMudey
        })
        this.setState({
          policiesImported: response.data.userPoliciesGet.policiesImported
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  setFilters(val) {
    this.setState({ loading: true, selectedFilter: val })
    // if (val == 'TODOS') {
    // localStorage.setItem('filterValue', null)
    // } else
    localStorage.setItem('filterValue', val)

    new Ql('userPolicies')
      .getFilters()
      .then(response => {
        this.setState({
          policiesExternal: response.data.userPoliciesGet.policiesExternal
        })
        this.setState({
          policiesMudey: response.data.userPoliciesGet.policiesMudey
        })
        this.setState({
          policiesImported: response.data.userPoliciesGet.policiesImported
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  getUserData() {
    this.setState({ loading: true })
    new Ql('userData').get().then(response => {
      this.setState({ userid: response.data.userGet.uuid })
      this.setState({ firstname: response.data.userGet.firstName })
      this.setState({ email: response.data.userGet.email })
    })
  }
  goTo(path) {
    this.props.history.push(path)
  }
  pushAction(item, value) {
    localStorage.setItem('selectedPolicy', JSON.stringify(item))
    Cookies.set('isMudey', `${value}`)
    this.props.history.push(`/seguros/${item.insuranceType.toLowerCase()}/${item.id}`)
    window.scrollTo(0, 0)
  }

  verifySubType = (item, insuranceTypeName) => {
    let f = ''
    if (item.insuranceSubtype === 'FAMILY') {
      f = item.insuranceSubtype
    } else {
      f = insuranceTypeName
    }
    return f
  }

  extractPremiumType = premiumType => {
    switch (premiumType) {
      case 'ANNUAL':
        return '/ano'
        break
      case 'MONTHLY':
        return '/mês'
        break
      case 'SEMESTRAL':
        return '/semestral'
        break
      case 'SEMESTER':
        return '/semestral'
        break
      case 'TRIMESTRAL':
        return '/trimestral'
        break
      case 'QUARTERLY':
        return '/trimestral'
        break
      case 'SINGLE':
        return '/único'
      case 'ONETIME':
        return '/único'
    }
  }

  render() {
    let {
      policiesExternal,
      policiesImported,
      policiesMudey,
      goTypeform,
      firstname,
      userid,
      email,
      linkType
    } = this.state
    if (policiesExternal !== null && policiesImported !== null && policiesMudey !== null) {
      delete policiesMudey.__typename
      delete policiesImported.__typename
      delete policiesExternal.__typename
    }

    let imported = [],
      external = [],
      mudey = []

    if (policiesMudey) {
      Object.keys(policiesMudey).map(key => {
        if (policiesMudey[key] && policiesMudey[key].length > 0) {
          let policy = policiesMudey[key]

          if (typeof policy === 'object') {
            policy.map(item => {
              let insuranceTypeName =
                item.insuranceType.charAt(0).toUpperCase() +
                item.insuranceType.substr(1).toLowerCase()

              let renewalDate = item.renewalDate
              let formattedRenewalDate = moment(renewalDate, 'YYYY-MM-DD').format('DD-MM-YYYY')

              let expiredPolicy = moment().isAfter(renewalDate)

              let islessThan30 = false
              let dateDiff = moment()
                .add(31, 'days')
                .diff(renewalDate, 'days')
              if (dateDiff >= 0 && dateDiff <= 30) {
                islessThan30 = true
              } else {
                islessThan30
              }
              mudey.push(
                <React.Fragment key={'fragment' + item.id}>
                  {goTypeform === true ? (
                    <div className="modal-info">
                      <div className="typeform-wrapper">
                        {new PriceAdvisor(linkType, userid, firstname, email).getForm()}
                        <a
                          className="button-solid"
                          href="#consider"
                          onClick={() => this.setState({ goTypeform: '' })}>
                          X
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className={`policy-item 
                    ${expiredPolicy === true ? ' expired' : ''}
                    ${
                      item.status === 'PENDING'
                        ? ' pending'
                        : item.status === 'ENDED'
                        ? ' ended'
                        : ''
                    }`}
                    key={item.id}>
                    <div
                      className="policy-item__title"
                      onClick={() => {
                        this.pushAction(item, 'positive')
                      }}>
                      <img alt="" src={new Icons(item.insuranceType).getPurple()} />
                      <span>
                        {item.insuranceSubtype === 'FAMILY' ||
                        item.insuranceSubtype === 'DEPENDENT' ||
                        item.insuranceSubtype === 'INDEPENDENT' ||
                        item.insuranceSubtype === 'MULTIRISK'
                          ? translateApiValue(item.insuranceSubtype)
                          : translateApiValue(insuranceTypeName)}
                      </span>
                    </div>
                    <div className="policy-item__content">
                      {islessThan30 == true && item.insuranceType != 'TRAVEL' && (
                        <div className="policy-item__alert">
                          <img
                            alt="refresh"
                            src={require('../../images/icons/refresh_black.svg')}
                          />
                          <span>Expira em breve</span>
                        </div>
                      )}

                      {item.status == 'ENDED' && (
                        <div className="policy-item__expired">
                          <img alt="buy" src={require('../../images/icons/buy.svg')} />
                          <span>Este seguro já terminou</span>
                        </div>
                      )}

                      {item.status === 'PENDING' && (
                        <div className="policy-item__pending">
                          <span>Ainda não está ativo!</span>
                        </div>
                      )}
                      <div
                        className="policy-item__node first"
                        onClick={() => {
                          this.pushAction(item, 'positive')
                        }}>
                        <span>Seguradora</span>
                        <strong>{item.insuranceBrand}</strong>
                      </div>
                      {item.premiumType && (
                        <div
                          className="policy-item__node hidden-size-lg"
                          onClick={() => {
                            this.pushAction(item, 'positive')
                          }}>
                          <span>
                            {item.insuranceType == 'TRAVEL' ? 'Produto' : 'Tipo Pagamento'}
                          </span>
                          <strong>
                            {item.insuranceType == 'TRAVEL'
                              ? item.packageName
                              : translateApiValue(
                                  _.startCase(_.toLower(item.premiumType)),
                                  'ALL',
                                  'PAYMENT'
                                )}
                          </strong>
                        </div>
                      )}
                      <div
                        className="policy-item__node"
                        onClick={() => {
                          this.pushAction(item, 'positive')
                        }}>
                        <span>Prémio Seguro</span>
                        <strong>
                          {item.premium} {this.extractPremiumType(item.premiumType)}
                        </strong>
                      </div>
                      <div
                        className="policy-item__node"
                        onClick={() => {
                          this.pushAction(item, 'positive')
                        }}>
                        <span>
                          {item.insuranceType == 'TRAVEL' ? 'Data Fim' : 'Data Renovação'}
                        </span>
                        <strong>
                          {formattedRenewalDate && formattedRenewalDate === 'Invalid date'
                            ? ''
                            : formattedRenewalDate}
                        </strong>
                      </div>
                      <div className="clickable-wrapper">
                        {expiredPolicy == true && item.insuranceType != 'CAR' && (
                          <div
                            className="clickable comprar"
                            onClick={() => {
                              {
                                redirectToApp(item.insuranceType) === 'typeform'
                                  ? (this.setState({ goTypeform: true }),
                                    this.setState({ linkType: item.insuranceType }),
                                    window.scrollTo(0, this.myRef.current.offsetTop))
                                  : (window.location.href = redirectToApp(item.insuranceType))
                              }
                            }}>
                            <span>Comprar seguro</span>
                          </div>
                        )}
                        {islessThan30 == true &&
                          (item.insuranceType != 'TRAVEL' && item.insuranceType != 'CAR') && (
                            <div
                              className="clickable renovar"
                              onClick={() => {
                                {
                                  redirectToApp(item.insuranceType) === 'typeform'
                                    ? (this.setState({ goTypeform: true }),
                                      this.setState({
                                        linkType: this.verifySubType(item, insuranceTypeName)
                                      }),
                                      window.scrollTo(0, this.myRef.current.offsetTop))
                                    : (window.location.href = redirectToApp(item.insuranceType))
                                }
                              }}>
                              <span>Renovar seguro</span>
                            </div>
                          )}
                        <div
                          className="clickable"
                          onClick={() => {
                            this.pushAction(item, 'positive')
                          }}>
                          <span>Mais Info</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
              return key
            })
          }
        }

        return key
      })
    }

    if (policiesExternal) {
      Object.keys(policiesExternal).map(key => {
        if (policiesExternal[key] && policiesExternal[key].length > 0) {
          let policy = policiesExternal[key]
          if (typeof policy === 'object') {
            policy.map(item => {
              let insuranceTypeName =
                item.insuranceType.charAt(0).toUpperCase() +
                item.insuranceType.substr(1).toLowerCase()

              let renewalDate = item.renewalDate
              let formattedRenewalDate = moment(renewalDate, 'YYYY-MM-DD').format('DD-MM-YYYY')

              let expiredPolicy = moment().isAfter(renewalDate)

              let islessThan30 = false
              let dateDiff = moment()
                .add(31, 'days')
                .diff(renewalDate, 'days')
              if (dateDiff >= 0 && dateDiff <= 30) {
                islessThan30 = true
              } else {
                islessThan30
              }

              external.push(
                <React.Fragment key={'fragment' + item.id}>
                  {goTypeform === true ? (
                    <div className="modal-info">
                      <div className="typeform-wrapper">
                        {new PriceAdvisor(linkType, userid, firstname, email).getForm()}
                        <a
                          className="button-solid"
                          href="#consider"
                          onClick={() => this.setState({ goTypeform: '' })}>
                          X
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className={`policy-item 
                    ${expiredPolicy === true ? ' expired' : ''}
                    ${
                      item.status === 'PENDING'
                        ? ' pending'
                        : item.status === 'ENDED'
                        ? ' ended'
                        : ''
                    }
                    `}
                    key={item.id}>
                    <div
                      className="policy-item__title"
                      onClick={() => {
                        this.pushAction(item, 'negative')
                      }}>
                      <img alt="" src={new Icons(item.insuranceType).getPurple()} />
                      <span>{translateApiValue(insuranceTypeName)}</span>
                    </div>
                    <div className="policy-item__content">
                      {islessThan30 == true && item.insuranceType != 'TRAVEL' && (
                        <div className="policy-item__alert">
                          <img
                            alt="refresh"
                            src={require('../../images/icons/refresh_black.svg')}
                          />
                          <span>Expira em breve</span>
                        </div>
                      )}
                      {item.status == 'ENDED' && (
                        <div className="policy-item__expired">
                          <img alt="buy" src={require('../../images/icons/buy.svg')} />
                          <span>Este seguro já terminou</span>
                        </div>
                      )}
                      {item.status === 'PENDING' && (
                        <div className="policy-item__pending">
                          <span>Ainda não está ativo!</span>
                        </div>
                      )}
                      <div
                        className="policy-item__node first"
                        onClick={() => {
                          this.pushAction(item, 'negative')
                        }}>
                        <span>Seguradora</span>
                        <strong>{item.insuranceBrand}</strong>
                      </div>
                      {item.premiumType && (
                        <div
                          className="policy-item__node hidden-size-lg"
                          onClick={() => {
                            this.pushAction(item, 'negative')
                          }}>
                          <span>
                            {item.insuranceType == 'TRAVEL' ? 'Produto' : 'Tipo Pagamento'}
                          </span>
                          <strong>
                            {/* //for travel tempario */}
                            {item.insuranceType == 'TRAVEL'
                              ? item.packageName
                              : translateApiValue(
                                  _.startCase(_.toLower(item.premiumType)),
                                  'ALL',
                                  'PAYMENT'
                                )}
                            {/* //for travel tempario end */}

                            {/* {translateApiValue(
                              item.premiumType.charAt(0).toUpperCase() +
                                item.premiumType.substr(1).toLowerCase()
                            )} */}
                          </strong>
                        </div>
                      )}
                      <div
                        className="policy-item__node"
                        onClick={() => {
                          this.pushAction(item, 'negative')
                        }}>
                        <span>Prémio Seguro</span>
                        <strong>{item.premium}</strong>
                      </div>
                      <div
                        className="policy-item__node"
                        onClick={() => {
                          this.pushAction(item, 'negative')
                        }}>
                        <span>
                          {item.insuranceType == 'TRAVEL' ? 'Data Fim' : 'Data Renovação'}
                        </span>
                        <strong>
                          {formattedRenewalDate && formattedRenewalDate === 'Invalid date'
                            ? ''
                            : formattedRenewalDate}
                        </strong>
                      </div>
                      <div className="clickable-wrapper">
                        {expiredPolicy === true && item.insuranceType != 'CAR' && (
                          <div
                            className="clickable comprar"
                            onClick={() => {
                              {
                                redirectToApp(item.insuranceType) === 'typeform'
                                  ? (this.setState({ goTypeform: true }),
                                    this.setState({ linkType: item.insuranceType }),
                                    window.scrollTo(0, this.myRef.current.offsetTop))
                                  : (window.location.href = redirectToApp(item.insuranceType))
                              }
                            }}>
                            <span>Comprar seguro</span>
                          </div>
                        )}
                        {islessThan30 == true &&
                          (item.insuranceType != 'TRAVEL' && item.insuranceType != 'CAR') && (
                            <div
                              className="clickable renovar"
                              onClick={() => {
                                {
                                  redirectToApp(item.insuranceType) === 'typeform'
                                    ? (this.setState({ goTypeform: true }),
                                      this.setState({
                                        linkType: this.verifySubType(item, insuranceTypeName)
                                      }),
                                      window.scrollTo(0, this.myRef.current.offsetTop))
                                    : (window.location.href = redirectToApp(item.insuranceType))
                                }
                              }}>
                              <span>Renovar seguro</span>
                            </div>
                          )}
                        <div
                          className="clickable"
                          onClick={() => {
                            this.pushAction(item, 'negative')
                          }}>
                          <span>Mais Info</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )

              return item
            })
          }
        }

        return key
      })
    }

    if (policiesImported) {
      Object.keys(policiesImported).map(key => {
        if (policiesImported[key] && policiesImported[key].length > 0) {
          let policy = policiesImported[key]

          if (typeof policy === 'object') {
            policy.map(item => {
              let renewalDate = item.renewalDate
              let renewalDateStartOfDay = moment(renewalDate).startOf('day')
              let formattedRenewalDate = moment(renewalDate, 'YYYY-MM-DD').format('DD-MM-YYYY')

              let insuranceTypeName =
                item.insuranceType.charAt(0).toUpperCase() +
                item.insuranceType.substr(1).toLowerCase()

              let expiredPolicy = moment()
                .startOf('day')
                .isAfter(renewalDateStartOfDay)
              // let adate = moment('2022-05-24').startOf('day')
              // let adate = moment('2022-05-23', 'YYYY-MM-DD').format('DD-MM-YYYY')
              // console.log(
              //   moment()
              //     .add(1, 'day')
              //     .startOf('day'),
              //   'adate'
              // )

              let islessThan30 = false
              let dateDiff = moment()
                .add(31, 'days')
                .diff(renewalDate, 'days')
              if (dateDiff >= 0 && dateDiff <= 30) {
                islessThan30 = true
              } else {
                islessThan30
              }

              imported.push(
                <React.Fragment key={'fragment' + item.id}>
                  {goTypeform === true ? (
                    <div className="modal-info">
                      <div className="typeform-wrapper">
                        {new PriceAdvisor(linkType, userid, firstname, email).getForm()}
                        <a
                          className="button-solid"
                          href="#consider"
                          onClick={() => this.setState({ goTypeform: '' })}>
                          X
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className={`policy-item 
                  ${expiredPolicy === true ? ' expired' : ''}
                  ${
                    item.status === 'PENDING' ? ' pending' : item.status === 'ENDED' ? ' ended' : ''
                  }`}
                    key={item.id}>
                    <div
                      className="policy-item__title"
                      onClick={() => {
                        this.pushAction(item, 'negative')
                      }}>
                      <img alt="" src={new Icons(item.insuranceType).getPurple()} />
                      <span>
                        {item.insuranceSubtype === 'FAMILY' ||
                        item.insuranceSubtype === 'DEPENDENT' ||
                        item.insuranceSubtype === 'INDEPENDENT' ||
                        item.insuranceSubtype === 'MULTIRISK'
                          ? translateApiValue(
                              item.insuranceSubtype === 'MULTIRISK'
                                ? 'MULTIRISKS'
                                : item.insuranceSubtype
                            )
                          : translateApiValue(insuranceTypeName)}
                      </span>
                    </div>
                    <div className="policy-item__content">
                      {islessThan30 == true && item.insuranceType != 'TRAVEL' && (
                        <div className="policy-item__alert">
                          <img
                            alt="refresh"
                            src={require('../../images/icons/refresh_black.svg')}
                          />
                          <span>Expira em breve</span>
                        </div>
                      )}

                      {item.status == 'ENDED' && (
                        <div className="policy-item__expired">
                          <img alt="buy" src={require('../../images/icons/buy.svg')} />
                          <span>Este seguro já terminou</span>
                        </div>
                      )}
                      <div
                        className="policy-item__node first"
                        onClick={() => {
                          this.pushAction(item, 'negative')
                        }}>
                        <span>Seguradora</span>
                        <strong>{item.insuranceBrand}</strong>
                      </div>
                      {item.premiumType && (
                        <div
                          className="policy-item__node hidden-size-lg"
                          onClick={() => {
                            this.pushAction(item, 'negative')
                          }}>
                          <span>
                            {item.insuranceType == 'TRAVEL' ? 'Produto' : 'Tipo Pagamento'}
                          </span>
                          <strong>
                            {/* //for travel tempario */}
                            {item.insuranceType == 'TRAVEL'
                              ? item.packageName
                              : translateApiValue(
                                  _.startCase(_.toLower(item.premiumType)),
                                  'ALL',
                                  'PAYMENT'
                                )}
                            {/* //for travel tempario end */}
                            {/* {translateApiValue(
                              item.premiumType.charAt(0).toUpperCase() +
                                item.premiumType.substr(1).toLowerCase()
                            )} */}
                          </strong>
                        </div>
                      )}
                      <div
                        className="policy-item__node"
                        onClick={() => {
                          this.pushAction(item, 'negative')
                        }}>
                        <span>Prémio Seguro</span>
                        <strong>{item.premium}</strong>
                      </div>
                      <div
                        className="policy-item__node"
                        onClick={() => {
                          this.pushAction(item, 'negative')
                        }}>
                        <span>
                          {item.insuranceType == 'TRAVEL' ? 'Data Fim' : 'Data Renovação'}
                        </span>
                        <strong>
                          {formattedRenewalDate && formattedRenewalDate === 'Invalid date'
                            ? ''
                            : formattedRenewalDate}
                        </strong>
                      </div>
                      <div className="clickable-wrapper">
                        {expiredPolicy === true && item.insuranceType != 'CAR' && (
                          <div
                            className="clickable comprar"
                            onClick={() => {
                              {
                                redirectToApp(item.insuranceType) === 'typeform'
                                  ? (this.setState({ goTypeform: true }),
                                    this.setState({ linkType: item.insuranceType }),
                                    window.scrollTo(0, this.myRef.current.offsetTop))
                                  : (window.location.href = redirectToApp(item.insuranceType))
                              }
                            }}>
                            <span>Comprar seguro</span>
                          </div>
                        )}
                        {islessThan30 == true &&
                          (item.insuranceType != 'TRAVEL' && item.insuranceType != 'CAR') && (
                            <div
                              className="clickable renovar"
                              onClick={() => {
                                {
                                  redirectToApp(item.insuranceType) === 'typeform'
                                    ? (this.setState({ goTypeform: true }),
                                      this.setState({
                                        linkType: this.verifySubType(item, insuranceTypeName)
                                      }),
                                      window.scrollTo(0, this.myRef.current.offsetTop))
                                    : (window.location.href = redirectToApp(item.insuranceType))
                                }
                              }}>
                              <span>Renovar seguro</span>
                            </div>
                          )}
                        <div
                          className="clickable"
                          onClick={() => {
                            this.pushAction(item, 'negative')
                          }}>
                          <span>Mais Info</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )

              return policy
            })
          }

          return key
        }

        return key
      })
    }

    return (
      <>
        <Layout loading={this.state.loading}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{process.env.REACT_APP_NAME} | Seguros</title>
          </Helmet>
          <div className={this.state.goTypeform === true ? 'BG' : ''} />
          <div>
            <div className="header-partner-wrapper">
              <h1>Os Meus Seguros</h1>
              <PartnersHeader />
            </div>
            <p className="subtitle-filter">Listar todos os seguros:</p>

            <div className="filter-buttons-wrapper">
              <button
                className="filter-buttons"
                style={{
                  backgroundColor: this.state.selectedFilter == 'ACTIVE' ? '#b181aa' : 'white',
                  color: this.state.selectedFilter == 'ACTIVE' ? 'white' : 'black'
                }}
                onClick={() => this.setFilters('ACTIVE')}>
                Activos
              </button>
              <button
                className="filter-buttons"
                style={{
                  backgroundColor: this.state.selectedFilter == 'ENDED' ? '#b181aa' : 'white',
                  color: this.state.selectedFilter == 'ENDED' ? 'white' : 'black'
                }}
                onClick={() => this.setFilters('ENDED')}>
                Arquivo
              </button>
              <button
                className="filter-buttons"
                style={{
                  backgroundColor: this.state.selectedFilter == 'TODOS' ? '#b181aa' : 'white',
                  color: this.state.selectedFilter == 'TODOS' ? 'white' : 'black'
                }}
                onClick={() => this.setFilters('TODOS')}>
                Todos
              </button>
            </div>
            {/* <p className="description-short-text page-description">
              Estes são os seguros que tens atualmente em vigor.
            </p> */}

            <div className="policies-list-container">
              <h2 className="subtitle-wallet" style={{ marginTop: '5px' }}>
                Com a MUDEY
              </h2>
              <button
                className="button-mainStructure button-yellow"
                onClick={() => {
                  this.goTo('/comprar')
                }}>
                Comprar Seguro
              </button>
              <div className="clearfix" />
              {mudey.length !== 0 ? (
                <div className="policies-list">
                  {mudey}
                  <div className="arrow-mb">
                    Desliza para a <span>direita</span> &gt;
                  </div>
                </div>
              ) : (
                <div className="noValue">Não tens seguros com a MUDEY!</div>
              )}

              <h2 className="subtitle-wallet" ref={this.myRef}>
                Outros
              </h2>

              {this.state.typeSelector && (
                <div className="add-policy-types">
                  <img
                    alt=""
                    onClick={() => {
                      this.setState({ typeSelector: false })
                    }}
                    src={require('../../images/icons/x.svg')}
                  />
                  <strong>Adicionar seguro</strong>
                  <div
                    className="button-transparent fs"
                    onClick={() => {
                      this.goTo('/seguros/adicionar')
                      window.scrollTo(0, 0)
                    }}>
                    Quero ser eu a adicionar
                  </div>
                  <div
                    className="button-transparent fs"
                    onClick={() => {
                      this.goTo('/seguros/email')
                      window.scrollTo(0, 0)
                    }}>
                    Quero que seja a MUDEY
                  </div>
                </div>
              )}
              {!this.state.typeSelector && (
                <button
                  className="button-mainStructure"
                  onClick={() => {
                    this.setState({ typeSelector: true })
                  }}>
                  Adicionar seguro
                </button>
              )}

              <div className="clearfix" />

              {(this.state.policiesImported || this.state.policiesExternal) &&
              imported.length === 0 &&
              external.length === 0 ? (
                <div className="noValue">Não adicionaste ainda os seguros à tua Wallet!</div>
              ) : (
                <div className="policies-list">
                  {imported}
                  {external}
                  <div className="arrow-mb">
                    Desliza para a <span>direita</span> &gt;
                  </div>
                </div>
              )}
            </div>
          </div>
        </Layout>
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    isPhoneValidAndVerified: state.isPhoneValidAndVerified
  }
}

PolicyList.propTypes = {
  history: PropTypes.any.isRequired,
  // eslint-disable-next-line
  isPhoneValidAndVerified: PropTypes.any
}

export default withRouter(connect(mapStateToProps)(PolicyList))
