import React from 'react'
import Select,{StylesConfig} from 'react-select'


const CustomReactSelect = ({name,options,placeholder,onChange,style,value}: any) => {
    const colourStyles: StylesConfig<ColourOption, true> = {
        control: (styles: any, { isFocused, isSelected }: any) => ({
          ...styles,
          backgroundColor: 'white',
          border: isFocused || isSelected ? '1px solid #d9d9d9' : '1px solid #d9d9d9',
          borderColor: '#967e94',
          boxShadow: isFocused || isSelected ? '0 0 0 2px rgba(137,93,133,.2)' : '',
          // boxShadow: 'none',
    
          '&:hover': {
            border: '1px solid #d9d9d9',
            cursor: 'pointer'
          }
        }),
        option: (styles: any, {  isFocused, isSelected, }: any) => {
          return {
            ...styles,
            backgroundColor: isFocused ? '#ccbccb' : isSelected ? '#ac72a7' : null,
            color: '#333333'
          }
        },
        indicatorSeparator: (defaultStyles: any) => {
          return {
            ...defaultStyles,
            display: 'none'
          }
        }
      }
  return (
    <Select
    // isDisabled={disabled}
    placeholder={placeholder}
    value={value}
    style={style}
    name={name}
    onChange={onChange}
    options={options}
    styles={colourStyles}
  />
  )
}
export default CustomReactSelect