export const SPORTS = [
  {
    type: 'Colectivos',
    sports: 'Andebol'
  },
  {
    type: 'Colectivos',
    sports: 'Andebol de campo'
  },
  {
    type: 'Colectivos',
    sports: 'Andebol de Praia'
  },
  {
    type: 'Colectivos',
    sports: 'Andebol Americano'
  },
  {
    type: 'Colectivos',
    sports: 'Arena Soccer'
  },
  {
    type: 'Colectivos',
    sports: 'Badminton'
  },
  {
    type: 'Colectivos',
    sports: 'Beisebol'
  },
  {
    type: 'Colectivos',
    sports: 'Basquetebol'
  },
  {
    type: 'Colectivos',
    sports: 'Bobsleigh'
  },
  {
    type: 'Colectivos',
    sports: 'Broomball'
  },
  {
    type: 'Colectivos',
    sports: 'Cabo de Guerra'
  },
  {
    type: 'Colectivos',
    sports: 'Cornerbol'
  },
  {
    type: 'Colectivos',
    sports: 'Críquete'
  },
  {
    type: 'Colectivos',
    sports: 'Corfebol'
  },
  {
    type: 'Colectivos',
    sports: 'Equitação'
  },
  {
    type: 'Colectivos',
    sports: 'Faustebol'
  },
  {
    type: 'Colectivos',
    sports: 'Flag football'
  },
  {
    type: 'Colectivos',
    sports: 'Floorball'
  },
  {
    type: 'Colectivos',
    sports: 'Free Soccer'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol Americano'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol Australiano'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol Canadense'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol de Cinco'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol de Praia'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol de salão (Futsal)'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol de salão-FIFUSA'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol Society'
  },
  {
    type: 'Colectivos',
    sports: 'Futebol Gaélico'
  },
  {
    type: 'Colectivos',
    sports: 'Futevolei'
  },
  {
    type: 'Colectivos',
    sports: 'Hóquei em campo'
  },
  {
    type: 'Colectivos',
    sports: 'Hóquei indoor'
  },
  {
    type: 'Colectivos',
    sports: 'Hóquei em linha'
  },
  {
    type: 'Colectivos',
    sports: 'Hóquei em patins'
  },
  {
    type: 'Colectivos',
    sports: 'Hóquei no gelo'
  },
  {
    type: 'Colectivos',
    sports: 'Hóquei subaquático'
  },
  {
    type: 'Colectivos',
    sports: 'Hurling'
  },
  {
    type: 'Colectivos',
    sports: 'Kabaddi'
  },
  {
    type: 'Colectivos',
    sports: 'Lacrosse'
  },
  {
    type: 'Colectivos',
    sports: 'Malha'
  },
  {
    type: 'Colectivos',
    sports: 'Netball'
  },
  {
    type: 'Colectivos',
    sports: 'Paintball'
  },
  {
    type: 'Colectivos',
    sports: 'Pesapallo'
  },
  {
    type: 'Colectivos',
    sports: 'Peteca'
  },
  {
    type: 'Colectivos',
    sports: 'Polo'
  },
  {
    type: 'Colectivos',
    sports: 'Pólo aquático'
  },
  {
    type: 'Colectivos',
    sports: 'Râguebi)'
  },
  {
    type: 'Colectivos',
    sports: 'Rugby League'
  },
  {
    type: 'Colectivos',
    sports: 'Rugby de praia'
  },
  {
    type: 'Colectivos',
    sports: 'Rugby subaquático'
  },
  {
    type: 'Colectivos',
    sports: 'Ringette'
  },
  {
    type: 'Colectivos',
    sports: 'Showbol'
  },
  {
    type: 'Colectivos',
    sports: 'Softbol'
  },
  {
    type: 'Colectivos',
    sports: 'Street Soccer'
  },
  {
    type: 'Colectivos',
    sports: 'Tchouckball'
  },
  {
    type: 'Colectivos',
    sports: 'Ténis'
  },
  {
    type: 'Colectivos',
    sports: 'Trekking'
  },
  {
    type: 'Colectivos',
    sports: 'Ultimate'
  },
  {
    type: 'Colectivos',
    sports: 'Ultimate de praia'
  },
  {
    type: 'Colectivos',
    sports: 'Voleibol'
  },
  {
    type: 'Colectivos',
    sports: 'Voleibol de praia'
  },
  {
    type: 'Motorizados',
    sports: 'Automobilismo'
  },
  {
    type: 'Motorizados',
    sports: 'Motociclismo'
  },
  {
    type: 'Motorizados',
    sports: 'Motonâutica'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Bare-foot'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Bodyboard'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Canoagem'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Caça Submarina'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Esqui Aquático'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Kitesurf'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Mergulho'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Motonáutica'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Natação'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'entatlo Moderno'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Remo'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Surf'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Bodysurf'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Triatlo'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Vela'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Wakeboard'
  },
  {
    type: 'Náuticos e Aquáticos',
    sports: 'Windsurfe'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Air hockey'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Balonismo'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Biatlo'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Ciclismo'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Columbofilia'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Escalada'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Esgrima'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Esqui'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Golfe'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Halterofilismo'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Hipismo'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Marcha Nórdica'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Manbol'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Minigolfe'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Orientação'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Paddle'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Parapente'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Pentatlo Aeronáutico'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Pentatlo Moderno'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Patinagem'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Pelota Basca'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Pesca'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Raquetebol'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Snow Papelão'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Squash'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Softbol'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Surf'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Ténis'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Ténis de Mesa'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Triatlo'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Tiro'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Tiro com arco'
  },
  {
    type: 'Desportos Individuais',
    sports: 'Xadrez'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Aggressive Inline'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Corrida de Aventura'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Mountain Bike'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Skate'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Esqui'
  },
  {
    type: 'Desportos Radicais',
    sports: 'BMX'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Windsurf'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Rapel'
  },
  {
    type: 'Desportos Radicais',
    sports: 'SURF'
  },
  {
    type: 'Desportos Radicais',
    sports: 'Snowboard'
  },
  {
    type: 'Desportos Militares',
    sports: 'Pentatlo Aeronáutico'
  },
  {
    type: 'Desportos Militares',
    sports: 'Pentatlo Militar'
  },
  {
    type: 'Desportos Militares',
    sports: 'Pentatlo Naval'
  },
  {
    type: 'Atletismo',
    sports: 'Salto'
  },
  {
    type: 'Atletismo',
    sports: 'Corrida'
  },
  {
    type: 'Atletismo',
    sports: 'Arremesso e Lançamentos'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Aikido'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Arte marcial'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Boxe'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Capoeira'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Full Contact'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Galinjitsu'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Haidong Gumdo'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Hapkido'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'I-Chuan'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Jiu-Jitsu'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Judo'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Karaté'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Kempo'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Kendo'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Kung-Fu'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Muay Thai'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Ninjutsu'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Shotokai'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Taekwondo'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Tai Chi Chuan'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Wrestling'
  },
  {
    type: 'Artes Marciais e Lutas',
    sports: 'Wushu'
  }
]
