// @flow
import React from 'react'
import './styles.less'
import PartnersHeader from '../../../../../src/components/utils/partners-header'

export const Header = ({ userName = '', userEmail = '' }: any) => {
  return (
    <div className="referral_header header-partner-wrapper">
      <div>
        <div className="user-details-header-dashboard">
          <h1 style={{ display: 'flex', alignItems: 'center' }}>
            {userName ? `Olá, ${userName}` : 'Olá'}&nbsp;
          </h1>
          <span style={{ fontSize: '24px',fontWeight:"bold" }}>( {userEmail} )</span>
        </div>
        <span>A tua Wallet MUDEY - Plataforma de Seguros Digital</span>
      </div>
      <PartnersHeader />
    </div>
  )
}
