/* eslint-disable react/prop-types */
import React from 'react'
import onClickOutside from 'react-onclickoutside'
import { store } from 'react-notifications-component'

import moment from 'moment'
import _ from 'lodash'

import MaskedInput, { conformToMask } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import DatePicker from 'react-datepicker'

import { translateApiValue } from '../utils'
import Ql from '../../api/ql'
import { CustomToast } from '../../containers/referrals/components/customToast'

import 'react-datepicker/dist/react-datepicker.css'

const showNotification = (message, type) => {
  return store.addNotification({
    content: <CustomToast message={message} type={type} />,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000
    }
  })
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.obj = null

    this.naovida = [
      'Abarca',
      'Ace Europe Life',
      'ACP Mobilidade',
      'Aegon Santander Portugal',
      'Ageas Portugal',
      'AIG Europe',
      'Allianz',
      'April',
      'ARAG SE',
      'Asisa',
      'Atradius Crédito y Caución',
      'AWP P&C',
      'Axa France IARD',
      'Axa France Vie',
      'Bankinter Seguros de Vida',
      'BBVASeguros',
      'Caravela',
      'Cardif Assurances Risques Divers',
      'Chubb European',
      "Compagnie Française d'Assurance",
      'Companhia Portuguesa de Resseguros',
      'Compañia Española de Seguros de Credito a la Exportación',
      'Cosec',
      'Crédito Agrícola Seguros',
      'Crédito Agrícola Vida',
      'Europ Assistance',
      'Fidelidade Assistência',
      'Fidelidade',
      'Generali',
      'GNB',
      'Helvetia Compañía Suiza',
      'Hiscox',
      'Inter Partner Assistance',
      'Liberty Seguros',
      "Lloyd's Insurance Company",
      'Logo Seguros',
      'London General Insurance Company ',
      'Lusitania',
      'Mapfre Asistencia',
      'Mapfre Seguros Gerais',
      'MetLife Europe',
      'Multicare',
      'Médis',
      'Mútua dos Pescadores',
      'N Seguros',
      'Ocidental',
      'Ok Teleseguros',
      'Planicare - Companhia de Seguros, S.A',
      'Popular Seguros',
      'Prevision Sanitaria Nacional',
      'Prevoir-Vie S.A',
      'Real Vida Seguros',
      'RNA Seguros de Assistência',
      'Seguradoras Unidas',
      'Seguro Direto',
      "Society of Lloyd's",
      'Tranquilidade',
      'Una Seguros',
      'Via Directa',
      'Victoria',
      'Zurich Insurance'
    ]

    this.vida = [
      'Ace Europe Life SE',
      'Aegon Santander Portugal Vida',
      'Ageas Portugal',
      'Allianz Portugal',
      'April',
      'Asisa, Vida Seguros',
      'Axa France Vie',
      'AXA Life Europe DAC',
      'Bankinter Seguros de Vida',
      'BBVASeguros',
      'BPI Vida e Pensões',
      'Cardif Assurances Vie',
      'Crédito Agrícola Vida',
      'España, S.A. - Compañia Nacional de Seguros',
      'Fidelidade',
      'GamaLife - Companhia de Seguros de Vida, S.A',
      'Generali Vida',
      'GNB',
      'Helvetia Compañía Suiza',
      'Liberty Seguros',
      'Logo Seguros',
      'Lusitania Vida',
      'Mapfre Seguros',
      'MetLife Europe',
      'Ocidental',
      'Prevision Sanitaria Nacional',
      'Prevoir-Vie S.A',
      'Real Vida Seguros',
      'Saúde Prime',
      'Santander Totta Seguros',
      'Seguradoras Unidas',
      'Seguros Continente',
      'Tranquilidade',
      'Una Seguros',
      'Victoria Seguros',
      'Zurich'
    ]

    this.state = {
      showNaoVidaOptions: false,
      showVersionOptions: false,
      showVidaOptions: false,
      showJobs: false,
      naovida: '',
      naovidaSelected: '',
      versionSelected: '',
      vidaSelected: '',
      job: '',
      vida: '',
      pickDateValue: null,
      multiple: [],
      filename: '',
      carVersions: [],
      jobList: []
    }

    this.setObject = this.setObject.bind(this)
    this.CheckboxField = this.CheckboxField.bind(this)
    this.MultipleCheckboxField = this.MultipleCheckboxField.bind(this)
    this.MultipleShow = this.MultipleShow.bind(this)
    this.DateField = this.DateField.bind(this)
    this.BirthField = this.BirthField.bind(this)
    this.PreviousField = this.PreviousField.bind(this)
    this.LicenseDateField = this.LicenseDateField.bind(this)
    this.PlateNumberField = this.PlateNumberField.bind(this)
    this.SelectVidaField = this.SelectVidaField.bind(this)
    this.SelectCarVersion = this.SelectCarVersion.bind(this)
    this.SelectField = this.SelectField.bind(this)
    this.NumberField = this.NumberField.bind(this)
    this.PostCodeField = this.PostCodeField.bind(this)
    this.SelectNaoVidaField = this.SelectNaoVidaField.bind(this)
    this.jobs = this.jobs.bind(this)
    this.FileField = this.FileField.bind(this)
    this.InputField = this.InputField.bind(this)
    this.ShowBox = this.ShowBox.bind(this)
    this.ShowEmail = this.ShowEmail.bind(this)
    this.getNaoVida = this.getNaoVida.bind(this)
    this.getVida = this.getVida.bind(this)
    this.pickDate = this.pickDate.bind(this)
    this.addToMultiple = this.addToMultiple.bind(this)
    this.nif = this.nif.bind(this)
    this.nifInput = this.nifInput.bind(this)
    this.moneyInput = this.moneyInput.bind(this)

    switch (props.type) {
      case 'select-vida':
        this.setObject(this.SelectVidaField)
        break
      case 'select-nao':
        this.setObject(this.SelectNaoVidaField)
        break
      case 'car-version':
        this.setObject(this.SelectCarVersion)
        break
      case 'select-jobs':
        this.setObject(this.jobs)
        break
      case 'date':
        this.setObject(this.DateField)
        break
      case 'previous-date':
        this.setObject(this.PreviousField)
        break
      case 'birth-date':
        this.setObject(this.BirthField)
        break
      case 'license-date':
        this.setObject(this.LicenseDateField)
        break
      case 'file':
        this.setObject(this.FileField)
        break
      case 'number':
        this.setObject(this.NumberField)
        break
      case 'postCode':
        this.setObject(this.PostCodeField)
        break
      case 'plate':
        this.setObject(this.PlateNumberField)
        break
      case 'select':
        this.setObject(this.SelectField)
        break
      case 'checkbox':
        this.setObject(this.CheckboxField)
        break
      case 'multiple-checkbox':
        this.setObject(this.MultipleCheckboxField)
        break
      case 'multiple-show':
        this.setObject(this.MultipleShow)
        break
      case 'show-box':
        this.setObject(this.ShowBox)
        break
      case 'show-email':
        this.setObject(this.ShowEmail)
        break
      case 'nif':
        this.setObject(this.nif)
        break
      case 'input-nif':
        this.setObject(this.nifInput)
        break
      case 'input-money':
        this.setObject(this.moneyInput)
        break
      default:
        this.setObject(this.InputField)
    }

    if (props.value) {
      this.value = props.value
      this.state.pickDateValue = new Date(props.value)
      this.state.naovidaget = props.value
      this.state.vidaget = props.value
      // this.props.onValueChange(props.value)
      this.state.multiple = props.value
      this.state.readonly = props.readonly
    }
    this.currentDate = new Date()
    this.maxDate = new Date().setFullYear(2100)
    this.minDate = new Date().setFullYear(1900)
    if (props.maxDate) {
      this.maxDate = props.maxDate
    }
  }

  addToMultiple(item) {
    let multiple = this.state.multiple

    if (multiple.indexOf(item) === -1) {
      multiple.push(item)
    } else {
      multiple.splice(multiple.indexOf(item), 1)
    }

    this.setState({
      multiple
    })
  }

  PreviousField() {
    return (
      <div className="form-object">
        <input
          className="input-md mb-only"
          readOnly
          defaultValue={this.value}
          format="dd-MM-yyyy"
          onChange={date => {
            this.pickDate(date)
          }}
          type="date"
        />
        <DatePicker
          className="input-md mb-off"
          dateFormat="dd-MM-yyyy"
          maxDate={this.currentDate}
          minDate={this.minDate}
          onChange={date => {
            this.pickDate(date)
          }}
          readonly="readonly"
          scrollableYearDropdown
          selected={this.state.pickDateValue}
          showYearDropdown
          yearDropdownItemNumber={100}
        />
      </div>
    )
  }

  BirthField() {
    return (
      <div className="form-object">
        {/* <input
          className="input-md mb-only"
          // readOnly
          defaultValue={this.value}
          format="YYYY-MM-DD"
          onChange={date => {
            console.log(date,'date12')
            if (date === null) {
              date = ''
            }
            this.pickDate(date.value)
          }}
          type="date"
        /> */}
        <DatePicker
          className="input-md mb-on"
          customInput={
            <MaskedInput
              guide={false}
              mask={[
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/
              ]}
              placeholder={[
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/
              ]}
            />
          }
          dateFormat="dd-MM-yyyy"
          maxDate={new Date(moment().subtract('18', 'years'))}
          minDate={this.minDate}
          onChange={date => {
            if (date === null) {
              date = ''
            }
            this.pickDate(date)
          }}
          readonly="readonly"
          scrollableYearDropdown
          selected={this.state.pickDateValue}
          showYearDropdown
          yearDropdownItemNumber={100}
        />
      </div>
    )
  }

  CheckboxField() {
    let checkboxes = this.props.data

    if (checkboxes) {
      let cb = checkboxes.map(checkbox => {
        let checked = false

        let name

        name = translateApiValue(checkbox.name, this.props.id)

        if (this.props.value) {
          if (checkbox.name.toLowerCase() === this.props.value.toLowerCase()) {
            checked = true
          }
        }

        return (
          <label className="checkbox-md" key={checkbox.name}>
            <input
              defaultChecked={checked}
              name={'checkbox-' + this.props.id}
              onClick={e => {
                this.props.onValueChange(e.target.value)
              }}
              type="radio"
              value={checkbox.name}
            />
            <span>{name}</span>
          </label>
        )
      })

      return cb
    }

    return ''
  }

  componentDidMount() {
    if (this.props.type === 'select-jobs') {
      new Ql('getProfessions')
        .get()
        .then(response => {
          this.setState({ jobList: response.data.getProfessions.professions })
        })
        .finally(() => {
          this.setState({ loading: false })
        })
    }
  }

  DateField() {
    return (
      <div className="form-object">
        <input
          className="input-md mb-only"
          defaultValue={this.value}
          format="dd-MM-yyyy"
          onChange={date => {
            this.pickDate(date)
          }}
          type="date"
        />
        <DatePicker
          className="input-md mb-off"
          dateFormat="dd-MM-yyyy"
          maxDate={this.maxDate}
          minDate={this.currentDate}
          onChange={date => {
            this.pickDate(date)
          }}
          readonly="readonly"
          scrollableYearDropdown
          selected={this.state.pickDateValue}
          showYearDropdown
          yearDropdownItemNumber={100}
        />
      </div>
    )
  }
  FileField() {
    return (
      <div className="inline va-top filefield" id={'file' + this.props.pkey}>
        <label className="file-input-style">
          <div className="text">Procurar arquivo</div>
          <input
            className="file-input"
            name={'file-' + this.props.pkey}
            onChange={e => {
              if (e.target.files.length) {
                if (e.target.files[0].size > 10000000) {
                  return showNotification('O tamanho do arquivo é muito grande', 'warning')
                }
                this.props.onValueChange(e.target)
                this.setState({ filename: e.target.files[0].name })
              }
            }}
            // size="40"
            accept=".pdf,.doc,.docx,.docs"
            type="file"
          />
        </label>
      </div>
    )
  }
  getNaoVida() {
    let list = this.naovida

    if (this.state.naovida.length > 1) {
      list = list.filter(item => {
        return item.toLowerCase().indexOf(this.state.naovida.toLowerCase()) !== -1
      })
    }

    return list
  }

  getVida() {
    let list = this.vida

    if (this.state.vida.length > 1) {
      list = list.filter(item => {
        return item.toLowerCase().indexOf(this.state.vida.toLowerCase()) !== -1
      })
    }

    return list
  }

  handleClickOutside = evt => {
    if (this.myRef && this.myRef.current !== null && !this.myRef.current.contains(evt.target)) {
      this.setState({ showJobs: false })
    }
  }

  InputField() {
    return (
      <input
        className="input-md"
        defaultValue={this.value}
        name={'input-' + this.props.id}
        onChange={e => {
          this.props.onValueChange(e.target.value)
        }}
        type="text"
      />
    )
  }
  jobs() {
    const { job, jobList } = this.state
    const dynamicList = jobList.filter(n => n.toLowerCase().includes(job.toLowerCase()))
    let options = dynamicList.map(item => {
      return (
        <span
          key={item}
          onClick={() => {
            //this.props.onBlockStateChange('false')
            this.props.onValueChange(item)

            this.setState({ job: item, showJobs: false })
          }}
          ref={this.myRef}>
          {item}
        </span>
      )
    })
    return (
      <div className="form-object">
        <input
          className="select-md"
          name={'selectjob-' + this.props.id}
          onChange={e => {
            this.setState({ job: e.target.value })
            this.props.onValueChange(e.target.value)
            //this prop never change when target.value is changed, code is the same as nao vida and vida select.
            //console.log('state', job, 'prop', this.props.value, 'target', e.target.value, job.length)
          }}
          onClick={() => {
            this.setState({ showJobs: true })
          }}
          value={job || this.props.value}
        />

        {this.state.showJobs && options.length > 0 && (
          <div className="vida-selector">{options}</div>
        )}
      </div>
    )
  }

  LicenseDateField() {
    return (
      <div className="form-object">
        <input
          className="input-md mb-only"
          defaultValue={this.value}
          format="dd-MM-yyyy"
          onChange={date => {
            this.pickDate(date)
          }}
          type="date"
        />
        <DatePicker
          className="input-md mb-off"
          dateFormat="dd-MM-yyyy"
          maxDate={this.maxDate}
          minDate={this.minDate}
          onChange={date => {
            this.pickDate(date)
          }}
          readonly="readonly"
          scrollableYearDropdown
          selected={this.state.pickDateValue}
          showYearDropdown
          yearDropdownItemNumber={100}
        />
      </div>
    )
  }

  moneyInput() {
    const numberMask = createNumberMask({
      allowNegative: false,
      allowDecimal: false,
      prefix: '',
      thousandsSeparatorSymbol: '',
      suffix: ' €'
    })
    return (
      <MaskedInput
        className="input-md"
        defaultValue={this.value}
        guide={false}
        mask={numberMask}
        name={'input-' + this.props.id}
        onChange={e => {
          this.props.onValueChange(e.target.value)
        }}
      />
    )
  }
  MultipleCheckboxField() {
    let checkboxes = this.props.data
    if (checkboxes) {
      let cb = checkboxes.map(checkbox => {
        let checked = false

        let name = ''

        name = translateApiValue(checkbox.name, this.props.id)

        if (this.props.value) {
          if (this.props.value.indexOf(checkbox.name) !== -1) {
            checked = true
          }
        }

        return (
          <label className="checkbox-md" key={checkbox.name}>
            <input
              defaultChecked={checked}
              name={'mcheckbox-' + this.props.id}
              onClick={e => {
                this.addToMultiple(e.target.value)
                this.props.onValueChange(this.state.multiple)
              }}
              type="checkbox"
              value={checkbox.name}
            />
            <span>{name}</span>
          </label>
        )
      })

      return cb
    }

    return ''
  }
  MultipleShow() {
    let viewValue = this.props.value
    let name = ''
    let nameCapitalize = ''

    if (viewValue) {
      let sb = viewValue.map(e => {
        // eslint-disable-next-line prettier/prettier
        name = translateApiValue(e, this.props.id)
        nameCapitalize = _.upperFirst(_.toLower(name))

        return (
          <p className="show-box" key={e}>
            {nameCapitalize}
          </p>
        )
      })

      return sb
    } else {
      return (
        <p className="show-box-none" key>
          Nenhuma opção selecionada
        </p>
      )
    }
  }
  nif() {
    var nif = this.value
    var nifMask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
    var conformedNif = conformToMask(nif, nifMask, { guide: false })

    return (
      <span
        className="show-box"
        defaultValue={this.value}
        onChange={e => {
          this.props.onValueChange(e.target.value)
        }}>
        {conformedNif.conformedValue}
      </span>
    )
  }
  nifInput() {
    return (
      <MaskedInput
        className="input-md"
        defaultValue={this.value}
        guide={false}
        mask={[
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/
        ]}
        name={'input-' + this.props.id}
        onChange={e => {
          this.props.onValueChange(e.target.value)
        }}
      />
    )
  }
  NumberField() {
    return (
      <input
        className="input-md"
        defaultValue={this.value}
        min="0"
        name={'input-' + this.props.id}
        onChange={e => {
          this.props.onValueChange(e.target.value)
        }}
        pattern="[0-9]{10}"
        step="1"
        type="number"
      />
    )
  }
  pickDate(date) {
    let force = false

    if (typeof date.target !== 'undefined') {
      date = moment(new Date(date.target.value)).format('DD-MM-YYYY')
      force = true
    }
    if (date === null) {
      date = ''
    }
    if (moment(date).isValid()) {
      if (!force) {
        let parseDate = moment(date).format('DD-MM-YYYY')
        this.props.onValueChange(parseDate)
      } else {
        this.props.onValueChange(date)
        date = new Date(date)
      }

      this.setState({ pickDateValue: date })
    }
  }
  PlateNumberField() {
    return (
      <MaskedInput
        className="input-md"
        defaultValue={this.value}
        guide={false}
        mask={[
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
          '-',
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
          '-',
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/
        ]}
        name={'input-' + this.props.id}
        onChange={e => {
          this.props.onValueChange(e.target.value)
        }}
        placeholder="xx-xx-xx"
      />
    )
  }
  PostCodeField() {
    return (
      <MaskedInput
        className="input-md"
        defaultValue={this.value}
        guide={false}
        mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
        name={'input-' + this.props.id}
        onChange={e => {
          this.props.onValueChange(e.target.value)
        }}
        placeholder="0000-000"
      />
    )
  }

  render() {
    let InputObject = this.obj
    // if (this.props.type === 'select-jobs') {
    //   console.log('props ===>', this.props.value)
    // }
    return <InputObject />
  }

  SelectCarVersion() {
    const { versionSelected } = this.state
    const { versions } = this.props
    const dynamicList = versions.filter(n =>
      n.toLowerCase().includes(versionSelected.toLowerCase())
    )

    let options = dynamicList.map(item => {
      return (
        <span
          key={item}
          onClick={() => {
            this.props.onBlockStateChange('false')
            this.props.onValueChange(item)
            this.setState({ versionSelected: item, showVersionOptions: false })
          }}>
          {item}
        </span>
      )
    })
    return (
      <div className="form-object">
        <input
          className="select-md"
          name={'version-' + this.props.id}
          onChange={e => {
            this.props.onValueChange(e.target.value)
            this.setState({ versionSelected: e.target.value })
          }}
          onClick={() => {
            this.setState({ showVersionOptions: true })
          }}
          value={versionSelected ? versionSelected : this.props.value}
        />

        {this.state.showVersionOptions && options.length > 0 && (
          <div className="vida-selector">{options}</div>
        )}
      </div>
    )
  }

  SelectField() {
    let options = this.props.data.map(item => {
      //passing type in this case to properly translate the item.name value
      let name = translateApiValue(
        item.name,
        item.type ? item.type : '',
        item.context ? item.context : ''
      )

      return (
        <option key={item.name.toLowerCase()} value={item.name}>
          {name}
        </option>
      )
    })

    return (
      <div className="form-object">
        <div className="select-wrapper">
          <select
            className="selectList"
            defaultValue={this.value}
            onChange={e => {
              this.props.onValueChange(e.target.value)
            }}>
            <option key="none" value="-">
              -
            </option>
            {options}
          </select>
        </div>
      </div>
    )
  }
  SelectNaoVidaField() {
    const { naovidaSelected } = this.state
    let list = this.getNaoVida()

    const dynamicList = list.filter(n => n.toLowerCase().includes(naovidaSelected.toLowerCase()))

    let options = dynamicList.map(item => {
      return (
        <span
          key={item}
          onClick={() => {
            this.props.onBlockStateChange('false')
            this.props.onValueChange(item)
            this.setState({ naovidaSelected: item, showNaoVidaOptions: false })
          }}>
          {item}
        </span>
      )
    })
    return (
      <div className="form-object">
        <input
          className="select-md"
          name={'selectnaovida-' + this.props.id}
          onChange={e => {
            this.props.onValueChange(e.target.value)
            this.setState({ naovidaSelected: e.target.value })
            //console.log('state', naovidaSelected, 'prop', this.props.value, 'target', e.target.value)
          }}
          onClick={() => {
            this.setState({ showNaoVidaOptions: true })
          }}
          value={naovidaSelected ? naovidaSelected : this.props.value}
        />

        {this.state.showNaoVidaOptions && options.length > 0 && (
          <div className="vida-selector">{options}</div>
        )}
      </div>
    )
  }
  SelectVidaField() {
    const { vidaSelected } = this.state
    let vidaList = this.getVida()
    const dynamicList = vidaList.filter(n => n.toLowerCase().includes(vidaSelected.toLowerCase()))

    let options = dynamicList.map(item => {
      return (
        <span
          key={item}
          onClick={() => {
            this.props.onBlockStateChange('false')
            this.props.onValueChange(item)
            this.setState({ vidaSelected: item, showVidaOptions: false })
          }}>
          {item}
        </span>
      )
    })

    return (
      <div className="form-object">
        <input
          className="select-md"
          name={'selectvida-' + this.props.id}
          onChange={e => {
            this.props.onValueChange(e.target.value)
            this.setState({ vidaSelected: e.target.value })
          }}
          onClick={() => {
            this.setState({ showVidaOptions: true })
          }}
          value={vidaSelected ? vidaSelected : this.props.value}
        />
        {this.state.showVidaOptions && options.length > 0 && (
          <div className="vida-selector">{options}</div>
        )}
      </div>
    )
  }

  setObject(obj) {
    this.obj = obj
  }
  ShowBox() {
    return <span className="show-box">{translateApiValue(this.value, this.props.id)}</span>
  }
  ShowEmail() {
    return <span className="show-email">{translateApiValue(this.value, this.props.id)}</span>
  }
}

export default onClickOutside(Form)
