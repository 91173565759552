import React from 'react'
import PropTypes from 'prop-types'

export default function CheckableAntTag({ tag, value, setValue }) {
  return (
    <div
      style={{
        backgroundColor: value && tag === value ? '#AACECD' : 'white',
        borderColor: value && tag === value ? '#AACECD' : '#ddd'
      }}
      className="selectable-ant-tags"
      key={tag}
      //   checked={selectedTags.indexOf(tag) > -1 ? true : false}
      //   onChange={checked => handleChange1(tag, checked)}>
      checked={tag === value}
      onClick={() => setValue(tag)}>
      <p> {tag}</p>
    </div>
  )
}

CheckableAntTag.propTypes = {
  tag: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired
}
