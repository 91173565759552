// @flow
import React from 'react'
import './styles.less'

export const Card = ({ backgroundImage, text, count }: any) => {
  return (
    <div className="card_container" style={{ backgroundImage }}>
      <span>{text}</span>
      <span>{count}</span>
    </div>
  )
}
