import FormBuilder from '../ui/FormBuilder'
import Layout from '../layout'
import PropTypes from 'prop-types'
import Ql from '../../api/ql'
import React from 'react'
import PartnersHeader from '../../../src/components/utils/partners-header'

class AddExistingPolicy extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()

    this.formData = {}
    this.files = {}

    this.policiesGroups = []
    this.policiesGroups.vehicle = [
      {
        name: 'Carro',
        id: 1,
        form: (
          <FormBuilder
            index="1"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, car: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.car = ref.formData
              if (ref) this.files.car = ref.files
            }}
            type="CarInsurance"
          />
        ),
        node: 'car',
        icon: 'Car'
      },
      {
        name: 'Moto',
        id: 2,
        form: (
          <FormBuilder
            index="2"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, bike: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.bike = ref.formData
              if (ref) this.files.bike = ref.files
            }}
            type="BikeInsurance"
          />
        ),
        node: 'bike',
        icon: 'Motorbike'
      },
      {
        name: 'Barco',
        id: 3,
        form: (
          <FormBuilder
            index="3"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, boat: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.boat = ref.formData
              if (ref) this.files.boat = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'boat',
        icon: 'Boat'
      },
      {
        name: 'Bicicleta',
        id: 4,
        form: (
          <FormBuilder
            index="4"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, bicycle: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.bicycle = ref.formData
              if (ref) this.files.bicycle = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'bicycle',
        icon: 'Bicycle'
      },
      {
        name: 'Trotinete',
        id: 28,
        form: (
          <FormBuilder
            index="28"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, scooter: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.scooter = ref.formData
              if (ref) this.files.scooter = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'scooter',
        icon: 'Scooter'
      },
      {
        name: 'Drone',
        id: 23,
        form: (
          <FormBuilder
            index="23"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, drone: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.drone = ref.formData
              if (ref) this.files.drone = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'drone',
        icon: 'Drone'
      }
    ]
    this.policiesGroups.home = [
      {
        name: 'Casa (Multirriscos)',
        id: 5,
        form: (
          <FormBuilder
            index="5"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, home: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.home = ref.formData
              if (ref) this.files.home = ref.files
            }}
            type="HomeInsurance"
          />
        ),
        node: 'home',
        icon: 'Buildings'
      },
      {
        name: 'Vida (Crédito Habitação)',
        id: 9,
        form: (
          <FormBuilder
            index="9"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, life_mortgage: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.life_mortgage = ref.formData
              if (ref) this.files.life_mortgage = ref.files
            }}
            type="MortgageInsurance"
          />
        ),
        node: 'life_mortgage',
        icon: 'Mortgage'
      },
      {
        name: 'Empregada Doméstica',
        id: 7,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, housekeeper: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.housekeeper = ref.formData
              if (ref) this.files.housekeeper = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'housekeeper',
        icon: 'Housekeeper'
      },
      {
        name: 'Eletrodomésticos',
        id: 29,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, whitegoods: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.whitegoods = ref.formData
              if (ref) this.files.whitegoods = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'whitegoods',
        icon: 'White_goods'
      },
      {
        name: 'Aparelhos Eletrónicos',
        id: 30,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, gadget: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.gadget = ref.formData
              if (ref) this.files.gadget = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'gadget',
        icon: 'Gadget'
      },
      {
        name: 'Extensão de Garantia',
        id: 32,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, extendedwarranty: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.extendedwarranty = ref.formData
              if (ref) this.files.extendedwarranty = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'extendedwarranty',
        icon: 'ExtendedWarranty'
      },
      {
        name: 'Garantia',
        id: 34,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, warranty: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.warranty = ref.formData
              if (ref) this.files.warranty = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'warranty',
        icon: 'Warranty'
      }
    ]
    this.policiesGroups.health = [
      {
        name: 'Saúde',
        id: 8,
        form: (
          <FormBuilder
            index="8"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, health: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.health = ref.formData
              if (ref) this.files.health = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'health',
        icon: 'Health'
      },
      {
        name: 'Vida (Crédito Habitação)',
        id: 9,
        form: (
          <FormBuilder
            index="9"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, life_mortgage: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.life_mortgage = ref.formData
              if (ref) this.files.life_mortgage = ref.files
            }}
            type="MortgageInsurance"
          />
        ),
        node: 'life_mortgage',
        icon: 'Mortgage'
      },
      {
        name: 'Vida Proteção',
        id: 10,
        form: (
          <FormBuilder
            index="10"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, life: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.life = ref.formData
              if (ref) this.files.life = ref.files
            }}
            type="LifeInsurance"
          />
        ),
        node: 'life',
        icon: 'Life'
      },
      {
        name: 'Dentário',
        id: 11,
        form: (
          <FormBuilder
            index="11"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, dental: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.dental = ref.formData
              if (ref) this.files.dental = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'dental',
        icon: 'Dental'
      },
      {
        name: 'Criança',
        id: 20,
        form: (
          <FormBuilder
            index="20"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, child: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.child = ref.formData
              if (ref) this.files.child = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'child',
        icon: 'Child'
      },
      {
        name: 'Família',
        id: 31,
        form: (
          <FormBuilder
            index="20"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, family: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.family = ref.formData
              if (ref) this.files.family = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'family',
        icon: 'Family'
      },
      {
        name: 'Doenças Graves',
        id: 36,
        form: (
          <FormBuilder
            index="20"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, criticalillness: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.criticalillness = ref.formData
              if (ref) this.files.criticalillness = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'criticalillness',
        icon: 'CriticalIllness'
      },
      {
        name: 'Hospitalização',
        id: 37,
        form: (
          <FormBuilder
            index="20"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, hospitalization: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.hospitalization = ref.formData
              if (ref) this.files.hospitalization = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'hospitalization',
        icon: 'Hospitalização'
      }
    ]
    this.policiesGroups.pet = [
      {
        name: 'Animais Domésticos',
        id: 12,
        form: (
          <FormBuilder
            index="12"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, pet: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.pet = ref.formData
              if (ref) this.files.pet = ref.files
            }}
            type="PetInsurance"
          />
        ),
        node: 'pet',
        icon: 'Pet'
      }
    ]
    this.policiesGroups.travel = [
      {
        name: 'Viagem',
        id: 13,
        form: (
          <FormBuilder
            index="13"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, travel: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.travel = ref.formData
              if (ref) this.files.travel = ref.files
            }}
            type="TravelInsurance"
          />
        ),
        node: 'travel',
        icon: 'Travel'
      }
    ]
    this.policiesGroups.pa = [
      {
        name: 'Acidentes Pessoais',
        id: 14,
        form: (
          <FormBuilder
            index="14"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, personal_accident: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.personal_accident = ref.formData
              if (ref) this.files.personal_accident = ref.files
            }}
            type="PersonalAccidentInsurance"
          />
        ),
        node: 'personal_accident',
        icon: 'Accident'
      },
      {
        name: 'Acid. Trabalho (Outrém)',
        id: 26,
        form: (
          <FormBuilder
            index="26"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, work_accident: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.work_accident = ref.formData
              if (ref) this.files.work_accident = ref.files
            }}
            subtype="DEPENDENT"
            type="PersonalAccidentInsurance"
          />
        ),
        node: 'work_accident',
        icon: 'WorkAccident'
      },
      {
        name: 'Acid. Trabalho (Independentes)',
        id: 27,
        form: (
          <FormBuilder
            index="27"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, work_accident: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.work_accident = ref.formData
              if (ref) this.files.work_accident = ref.files
            }}
            subtype="INDEPENDENT"
            type="PersonalAccidentInsurance"
          />
        ),
        node: 'work_accident',
        icon: 'WorkAccident'
      },
      {
        name: 'Criança',
        id: 21,
        form: (
          <FormBuilder
            index="21"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, child: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.child = ref.formData
              if (ref) this.files.child = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'child',
        icon: 'Child'
      },
      {
        name: 'Empregada Doméstica',
        id: 22,
        form: (
          <FormBuilder
            index="22"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, housekeeper: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.housekeeper = ref.formData
              if (ref) this.files.housekeeper = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'housekeeper',
        icon: 'Housekeeper'
      }
    ]
    this.policiesGroups.thirdparty = [
      {
        name: 'Responsabilidade Civil',
        id: 15,
        form: (
          <FormBuilder
            index="15"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, third_party: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.third_party = ref.formData
              if (ref) this.files.third_party = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'third_party',
        icon: '3rdParty'
      },
      {
        name: 'Drone',
        id: 25,
        form: (
          <FormBuilder
            index="25"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, drone: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.drone = ref.formData
              if (ref) this.files.drone = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'drone',
        icon: 'Drone'
      }
    ]
    this.policiesGroups.other = [
      {
        name: 'Telemóvel',
        id: 16,
        form: (
          <FormBuilder
            index="1"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, mobile: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.mobile = ref.formData
              if (ref) this.files.mobile = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'mobile',
        icon: 'Mobile'
      },
      {
        name: 'Caçador',
        id: 17,
        form: (
          <FormBuilder
            index="1"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, hunter: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.hunter = ref.formData
              if (ref) this.files.hunter = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'hunter',
        icon: 'Hunter'
      },
      {
        name: 'Proteção de Rendimento',
        id: 18,
        form: (
          <FormBuilder
            index="1"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, income: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.income = ref.formData
              if (ref) this.files.income = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'income',
        icon: 'Income'
      },
      {
        name: 'Drone',
        id: 24,
        form: (
          <FormBuilder
            index="24"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, drone: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.drone = ref.formData
              if (ref) this.files.drone = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'drone',
        icon: 'Drone'
      },
      {
        name: 'Aparelhos Eletrónicos',
        id: 31,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, gadget: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.gadget = ref.formData
              if (ref) this.files.gadget = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'gadget',
        icon: 'Gadget'
      },
      {
        name: 'Extensão de Garantia',
        id: 33,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, extendedwarranty: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.extendedwarranty = ref.formData
              if (ref) this.files.extendedwarranty = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'extendedwarranty',
        icon: 'ExtendedWarranty'
      },
      {
        name: 'Garantia',
        id: 35,
        form: (
          <FormBuilder
            index="7"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, warranty: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.warranty = ref.formData
              if (ref) this.files.warranty = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'warranty',
        icon: 'Warranty'
      },
      {
        name: 'Outro',
        id: 19,
        form: (
          <FormBuilder
            index="1"
            onBlockStateChange={v => {
              this.setState(p => ({
                blocked: { ...p.blocked, other: v === 'true' }
              }))
            }}
            ref={ref => {
              if (ref) this.formData.other = ref.formData
              if (ref) this.files.other = ref.files
            }}
            type="OtherInsurance"
          />
        ),
        node: 'other',
        icon: 'Other'
      }
    ]

    this.policies = [
      {
        name: 'Veículos',
        id: 1,
        categories: this.policiesGroups.vehicle,
        icon: 'Car'
      },
      {
        name: 'Casa',
        id: 2,
        categories: this.policiesGroups.home,
        icon: 'Buildings'
      },
      {
        name: 'Saúde & Vida',
        id: 3,
        categories: this.policiesGroups.health,
        icon: 'Health'
      },
      {
        name: 'Animais Domésticos',
        id: 4,
        categories: this.policiesGroups.pet,
        icon: 'Pet'
      },
      {
        name: 'Viagem',
        id: 5,
        categories: this.policiesGroups.travel,
        icon: 'Travel'
      },
      {
        name: 'Acidentes',
        id: 6,
        categories: this.policiesGroups.pa,
        icon: 'Accident'
      },
      {
        name: 'Responsabilidade Civil',
        id: 7,
        categories: this.policiesGroups.thirdparty,
        icon: '3rdParty'
      },
      {
        name: 'Outro',
        id: 8,
        categories: this.policiesGroups.other,
        icon: 'Other'
      }
    ]

    this.state = {
      loading: false,
      selectedPolicy: null,
      selectedPolicyNodes: [],
      selectedInsurance: null,
      selectedInsuranceNodes: [],
      isFillingStarted: false,
      fillingForm: null,
      blocked: {
        car: true,
        bike: true,
        boat: true,
        bicycle: true,
        scooter: true,
        home: true,
        housekeeper: true,
        health: true,
        child: true,
        drone: true,
        life_mortgage: true,
        life: true,
        dental: true,
        pet: true,
        travel: true,
        personal_accident: true,
        work_accident: true,
        third_party: true,
        mobile: true,
        hunter: true,
        income: true,
        other: true
      }
    }

    this.selectPrimaryPolicy = this.selectPrimaryPolicy.bind(this)
    this.selectInsurance = this.selectInsurance.bind(this)
    this.buildForms = this.buildForms.bind(this)
    this.pickEquivalent = this.pickEquivalent.bind(this)
    this.unselectAll = this.unselectAll.bind(this)
  }

  addPolicy() {
    let importedCount = 0

    Object.keys(this.formData).map(key => {
      // console.log(key,"key")
      let queryFields = ''
      let queryData = []
      let specialQueryType = false
      let requestPayload
      let files = this.files[key]

      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let node of this.formData[key].entries()) {
        queryData[node[0]] = node[1]
        queryFields = queryFields + node[0] + '\n'
      }
    //added housekeeper key to fix api policy import issue
      switch (key) {
        case 'bike':
        case 'car':
        case 'home':
        case 'life':
        case 'life_mortgage':
        case 'personal_accident':
        case 'work_accident':
        case 'pet':
        case 'housekeeper':
        case 'travel':
          specialQueryType = true
          break
        default:
          break
      }

      requestPayload = {
        fields: queryFields,
        data: { ...queryData },
        specialQueryType
      }

      if (key === 'third_party') {
        key = 'thirdparty'
      }

      if (key === 'life_mortgage') {
        key = 'mortgage'
      }

      this.setState({ loading: true })
      new Ql('policyImport')
        .set(requestPayload, key)
        .then(r => {
          importedCount++

          if (files.size > 0) {
            //eslint-disable-next-line unused-imports/no-unused-vars
            for (let file of files.values()) {
              this.setState({ loading: true })
              new Ql('policyFiles').set({ id: r.id, file }).finally(() => {
                this.setState({ loading: false })
              })
            }
          }

          if (importedCount === Object.keys(this.formData).length) {
            this.props.history.push('/seguros')
          }
        })
        .finally(() => {
          this.setState({ loading: false })
        })

      return key
    })
  }
  authQuery() {}
  buildForms() {
    this.setState({ isFillingStarted: true, fillingForm: 0 })
  }
  pickEquivalent(str) {
    let description

    switch (str) {
      case 'Outro':
      case 'Saúde & Vida':
      case 'Casa (Multirriscos)':
      case 'Veículos':
        description = 'Seleciona pelo menos um tipo de seguro'
        break
      case 'Animais Domésticos':
      case 'Viagem':
      case 'Acidentes Pessoais':
      case 'Responsabilidade Civil':
        description = 'Seleciona o tipo de seguro'
        break
      default:
        description = str
        break
    }

    return description
  }
  render() {
    let insuranceTypes,
      insuranceType,
      forms,
      renderForms,
      policyTypes = this.policies.map(policy => {
        let checkedPolicy = ''

        if (this.state.selectedPolicy) {
          if (this.state.selectedPolicy.id === policy.id) {
            checkedPolicy = ' selected'
          }
          if (this.state.selectedPolicyNodes.indexOf(policy.id) !== -1) {
            checkedPolicy = ' active'
          }
        }

        return (
          <div
            className={'card-block' + checkedPolicy}
            key={policy.id}
            onClick={() => {
              this.selectPrimaryPolicy(policy)
              window.scrollTo({ behavior: 'smooth', left: 0, top: this.myRef.current.offsetTop })
            }}>
            <div className="image">
              <img
                alt=""
                className="shield"
                src={require('../../images/types/purple/' + policy.icon + '.svg')}
              />
              <img
                alt=""
                className="shield shield-white"
                src={require('../../images/types/white/' + policy.icon + '.svg')}
              />
              <div className="title">{policy.name}</div>
            </div>
          </div>
        )
      })
    if (this.state.selectedPolicy && this.state.selectedPolicy.name === 'Outro') {
      insuranceType = 'Outros'
    } else {
      insuranceType = this.state.selectedPolicy ? this.state.selectedPolicy.name : ''
    }

    if (insuranceType) {
      insuranceTypes = this.state.selectedPolicy.categories.map(category => {
        let checkedInsurance = ''

        if (this.state.selectedInsurance) {
          checkedInsurance =
            this.state.selectedInsuranceNodes.indexOf(category) !== -1 ? ' active' : ''
        }

        return (
          <div
            className={'card-block' + checkedInsurance}
            key={category.id}
            onClick={() => {
              this.selectInsurance(category)
            }}>
            <div className="image">
              <img
                alt=""
                className="shield"
                src={require('../../images/types/purple/' + category.icon + '.svg')}
              />
              <img
                alt=""
                className="shield shield-white"
                src={require('../../images/types/white/' + category.icon + '.svg')}
              />
              <div className="title">{category.name}</div>
            </div>
          </div>
        )
      })
    }

    if (this.state.selectedInsuranceNodes.length > 0) {
      let insuranceTitle

      forms = this.state.selectedInsuranceNodes.filter(node => {
        if (node.form) {
          return true
        }
        return false
      })

      renderForms = forms.map((item, idx) => {
        let form = item.form,
          next = '',
          prev = '',
          done = ''

        if (idx + 1 < forms.length && !this.state.blocked[item.node]) {
          next = (
            <div className="next-button">
              <div
                className="button-solid fs"
                onClick={() => {
                  this.setState({ fillingForm: idx + 1 })
                  window.scrollTo(0, 0)
                }}>
                Seguinte
              </div>
            </div>
          )
        }

        if (idx > 0) {
          prev = (
            <div className="next-button">
              <div
                className="button-solid fs"
                onClick={() => {
                  this.setState({ fillingForm: idx - 1 })
                  window.scrollTo(0, 0)
                }}>
                Anterior
              </div>
            </div>
          )
        } else {
          prev = (
            <div className="next-button">
              <div
                className="button-solid fs"
                onClick={() => this.setState({ isFillingStarted: false, fillingForm: null })}>
                Para trás
              </div>
            </div>
          )
        }

        const isSubmitButtonDisabled =
          idx + 1 === this.state.selectedInsuranceNodes.length && !this.state.blocked[item.node]
            ? false
            : true
        done = (
          <button
            className="next-button button-solid fs"
            disabled={isSubmitButtonDisabled}
            onClick={() => {
              this.addPolicy(1)
              // eslint-disable-next-line new-cap
              // window.Intercom('update')
            }}
            style={{ background: isSubmitButtonDisabled ? '#dedede' : null }}>
            Concluir
          </button>
        )

        if (this.state.selectedInsuranceNodes.length > 1) {
          insuranceTitle = (
            <div className="title-num">
              <div className="header-partner-wrapper">
                <h1>{'Seguro de ' + item.name}</h1>
                <PartnersHeader />
              </div>
              <span>
                {idx + 1} of {this.state.selectedInsuranceNodes.length}
              </span>
            </div>
          )
        } else {
          insuranceTitle = (
            <div className="header-partner-wrapper">
              <h1>{'Seguro de ' + item.name}</h1>
              <PartnersHeader />
            </div>
          )
        }

        return (
          <div className={this.state.fillingForm !== idx ? 'hidden' : ''} key={item.id}>
            {insuranceTitle}
            <div className="form-content">
              {form}
              {done}
              {next}
              {prev}
            </div>
          </div>
        )
      })
    }

    return (
      <Layout loading={this.state.loading}>
        <div>
          <div className={this.state.isFillingStarted ? 'hidden' : ''}>
            <div
              className="back-arrow"
              onClick={() => {
                this.props.history.push('/seguros')
              }}>
              <img alt="" src={require('../../images/icons/arrow-left.svg')} />
            </div>

            <div className="header-partner-wrapper">
              <h1 className="for-selected">Seguros</h1>
              <PartnersHeader />
            </div>

            <div className="clearfix" />
            <p className="description-short-text page-description with-action">
              Seleciona pelo menos uma categoria para os seguros que queres adicionar à tua wallet
            </p>
            <div className="next-button unselect">
              <div
                className="button-solid"
                onClick={() => {
                  this.unselectAll()
                }}>
                Apagar Seleção
              </div>
            </div>
            {this.state.selectedInsuranceNodes.length > 0 && (
              <div className="policies-selected">
                <span className="count">{this.state.selectedInsuranceNodes.length}</span>
                <span className="text">opções selecionadas</span>
              </div>
            )}

            <div className="clearfix" />

            <div className="cards">
              {policyTypes}
              <div className="arrow-mb">
                Desliza para a <span>direita</span> &gt;
              </div>
            </div>
            <div className="hidden-ref" ref={this.myRef} />
            {insuranceType && (
              <div className="extra-details">
                <h2>{insuranceType}</h2>
                {this.state.selectedPolicy && (
                  <p className="description-short-text">
                    {this.pickEquivalent(this.state.selectedPolicy.name)}
                  </p>
                )}
                <div className="cards">
                  {insuranceTypes}
                  <div className="arrow-mb">
                    Desliza para a <span>direita</span> &gt;
                  </div>
                </div>
              </div>
            )}

            {this.state.selectedInsuranceNodes.length > 0 && (
              <div>
                <div className="next-button">
                  <div
                    className="button-solid"
                    onClick={() => {
                      this.buildForms()
                      window.scrollTo(0, 0)
                    }}>
                    Seguinte
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={this.state.isFillingStarted ? '' : 'hidden'}>
            <div
              className="back-arrow"
              onClick={() => this.setState({ isFillingStarted: false, fillingForm: null })}>
              <img alt="" src={require('../../images/icons/arrow-left.svg')} />
            </div>
            {renderForms}
          </div>
        </div>
      </Layout>
    )
  }
  selectInsurance(insurance) {
    this.setState({ selectedInsurance: insurance })

    let insuranceIndex = this.state.selectedInsuranceNodes.indexOf(insurance)
    let policyIndex = this.state.selectedPolicyNodes.indexOf(this.state.selectedPolicy.id)

    if (insuranceIndex !== -1) {
      this.state.selectedInsuranceNodes.splice(insuranceIndex, 1)

      delete this.formData[insurance.node]

      if (policyIndex !== -1) {
        let items = this.state.selectedPolicy.categories.filter(category => {
          if (this.state.selectedInsuranceNodes.indexOf(category) !== -1) {
            return category.id
          }
          return category
        })

        if (items.length < 1) {
          this.state.selectedPolicyNodes.splice(policyIndex, 1)
        }
      }
    } else {
      this.state.selectedInsuranceNodes.push(insurance)

      if (policyIndex === -1) {
        this.state.selectedPolicyNodes.push(this.state.selectedPolicy.id)
      }
    }
  }
  selectPrimaryPolicy(policy) {
    this.setState({ selectedPolicy: policy })
  }
  UNSAFE_componentWillMount() {
    this.authQuery()
  }

  unselectAll() {
    this.setState({
      selectedInsurance: null,
      selectedPolicyNodes: [],
      selectedPolicy: null,
      selectedInsuranceNodes: []
    })
  }
}

AddExistingPolicy.propTypes = {
  history: PropTypes.any.isRequired
}

export default AddExistingPolicy
