import React from 'react'
import { Badge } from 'antd'
import PropTypes from 'prop-types'

export default function ThirdSectionHeading({
  formValues,
  showSavedDetails,
  heading,
  badge,
  questions,
  showForm
}) {
  if (questions === null) {
    return ''
  }

  const getFormattedValue = value => {
    if (value == undefined) {
      return ''
    }

    if (typeof value == 'object' && Object.values(value).length > 0) {
      return value.join(', ')
    } else {
      return value
    }
  }

  return (
    <section className="heading" onClick={event => event.stopPropagation()} style={{}}>
      <div className="badge-text">
        <div className="inner-badge-text">
          <Badge className="badge">{badge}</Badge>
          <span className="text">
            <strong>{heading}</strong>
          </span>
        </div>
        {showSavedDetails && (
          <span onClick={() => showForm(false)} className="edit-text">
            Alterar
          </span>
        )}
      </div>
      <div className="saved-details" style={{ display: showSavedDetails ? 'block' : 'none' }}>
        {Object.entries(questions).map(q => {
          return (
            <>
              <p key={q[1].uuid}>
                <span className="question-heading"> {q[1].question} </span>&nbsp;&nbsp;
                <span className="answer-heading">{getFormattedValue(formValues[q[1].uuid])}</span>
              </p>
              {q[1].child !== null &&
                q[1].child.map(c => {
                  return (
                    formValues[c.uuid] != null &&
                    formValues[c.uuid] != undefined &&
                    formValues[c.uuid][0] != '' && (
                      <p key={c.uuid}>
                        <span className="question-heading"> {c.question} </span>&nbsp;&nbsp;
                        <span className="answer-heading">
                          {getFormattedValue(formValues[c.uuid])}
                        </span>
                      </p>
                    )
                  )
                })}
            </>
          )
        })}
      </div>
    </section>
  )
}

ThirdSectionHeading.propTypes = {
  formValues: PropTypes.object.isRequired,
  showSavedDetails: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  badge: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  showForm: PropTypes.func.isRequired
}
