// @flow
import React from 'react'
import iconSofia from '../../../../images/sofia.png'
import './styles.less'

export const ReferralCard = ({ goTo }: any) => {
  return (
    <div className="referral_card">
      <div className="referral_card_header">
        <div className="referral_card_header-title">
          Convida os teus amigos e familiares!
        </div>
      </div>
      <div className="referral_card_content">
        <div className="referral_card_avatar_bg" />
        <div className="referral_card_avatar_wrapper">
          <div className="referral_card_avatar" style={{ backgroundImage: `url(${iconSofia})` }} />
        </div>
        <div className="referral_card_title">Convida os teus amigos a juntarem-se à MUDEY</div>
      </div>
      <div className="referral_card_footer">
        <div onClick={goTo} className="referral_card_footer-title">
          Convidar
        </div>
      </div>
    </div>
  )
}
