import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import './progress.scss'

export const Progress = ({ size, progress, strokeWidth, circleOneStroke, circleTwoStroke }) => {
  const [offset, setOffset] = useState(0)
  const [mainOffset, setMainOffset] = useState(0)
  const circleRef = useRef(null)

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    // as we don't need to fill all round this 0.33 this that empty space
    const progressOffset = ((100 - (progress - progress * 0.33)) / 100) * circumference
    setOffset(progressOffset)

    // same logic is here - minus 33% from full round
    const mainOffset = (33 / 100) * circumference
    setMainOffset(mainOffset)

    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out'
  }, [setOffset, progress, circumference, offset])

  return (
    <>
      <svg className="svg" width={size} height={size}>
        <circle
          className="svg-circle-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={mainOffset}
          transform={`rotate(-210.5 ${center} ${center})`}
        />
        <circle
          className="svg-circle"
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={`rotate(-210.5 ${center} ${center})`}
        />
        <text x={`${center}`} y={`${center}`} className="svg-circle-progress">
          {progress}%
        </text>
        <text x={`${center}`} y={`${center + 40}`} className="svg-circle-text">
          <tspan x={`${center}`} y={`${center + 50}`}>
            {progress < 50 ? 'O teu perfil ainda ' : 'O teu perfil está'}
          </tspan>
          <tspan x={`${center}`} y={`${center + 70}`}>
            {progress < 50 ? 'não está completo!' : 'completo'}
          </tspan>
        </text>
      </svg>
    </>
  )
}

Progress.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  circleOneStroke: PropTypes.string.isRequired,
  circleTwoStroke: PropTypes.string.isRequired
}
