import 'element-theme-default'

import FormBuilder from '../ui/FormBuilder'
import Layout from '../layout'
import { Link } from 'react-router-dom'
import Ql from '../../api/ql'
import React from 'react'
import { Helmet } from 'react-helmet'
import PartnersHeader from '../../../src/components/utils/partners-header'

class UserView extends React.Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      userData: null,
      dataObtained: false
    }

    this.getUserData = this.getUserData.bind(this)

    this.reference = null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getUserData()
  }

  getUserData() {
    this.setState({ loading: true })
    new Ql('userData')
      .get()
      .then(data => {
        this.setState({
          dataObtained: true,
          userData: data.data.userGet
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <Layout loading={this.state.loading}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{process.env.REACT_APP_NAME} | Dados</title>
        </Helmet>
        <div>
          <div className="header-partner-wrapper">
            <Link className="button-edit" to="/dados/perfil/editar">
              <span>Editar</span>
            </Link>
            <PartnersHeader />
          </div>
          {this.state.dataObtained ? (
            <div>
              <FormBuilder
                data={this.state.userData}
                index="1"
                ref={ref => {
                  if (ref) this.reference = ref.formData
                }}
                type="UserView"
              />
            </div>
          ) : (
            <p>loading...</p>
          )}
        </div>
      </Layout>
    )
  }
}

export default UserView
