import 'firebase/auth'
import 'firebase/database'
import './index.less'

import React from 'react'
import { Provider } from 'react-redux'
import { store } from './store'
import ReactNotification from 'react-notifications-component'
import App from './containers/app'
import { BrowserRouter as Router } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import TagManager from 'react-gtm-module'
import client from './api/client'
import { createBrowserHistory } from 'history'
import { render } from 'react-dom'

import 'react-notifications-component/dist/theme.css'


if (process.env.REACT_APP_GTM_KEY.length != 0) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY
  }

  TagManager.initialize(tagManagerArgs)
}

const history = createBrowserHistory()

const target = document.querySelector('#root')

if (target)
  render(
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          
        <ReactNotification />
          <App client={client} history={history} />
        </ScrollToTop>
      </Router>
    </Provider>,
    target
  )
