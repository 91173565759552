/* eslint-disable react/no-unescaped-entities */
import React from 'react'

export default class InfoTexts {
  constructor(text) {
    this.type = text

    const loremipsun = <p className="description">Under Construction....</p>

    const carText = (
      <p className="description">
        Como tens um automóvel, és obrigado(a) por lei a ter um seguro de responsabilidade civil
        automóvel <strong>quanto a terceiros</strong>{' '}
        <span aria-labelledby="" role="img">
          🙄
        </span>
        . Conheces certamente como &quot;seguro contra terceiros&quot;. <br />
        <br /> O que significa: em caso de acidente, e se fores tu o responsável pelo acidente ,
        este seguro cobre os <strong>danos</strong> corporais (até ao valor de 6.070.000 €) e
        materiais (até ao valor de 1.220.000 €){' '}
        <strong>causados a terceiros e às pessoas transportadas.</strong> <br />
        <br />O que significa isto na prática{' '}
        <span aria-labelledby="" role="img">
          👀
        </span>
        ? O seguro paga as despesas hospitalares e tratamentos médicos desses terceiros, os danos do
        outro veículo, os danos do edifício onde foste embater, etc. Mas atenção,{' '}
        <strong>
          o seguro não paga os teus{' '}
          <span aria-labelledby="" role="img">
            🤕
          </span>{' '}
          e os do teu 🚙 , por seres o condutor que causou o acidente.
        </strong>
        <br /> <br /> Existe também o <strong>seguro de danos próprios</strong>- mais completo -
        vulgarmente designado como &quot;seguro contra todos os riscos&quot;. Mas a expressão
        &quot;“todos os riscos”&quot; é um pouco enganadora{' '}
        <span aria-labelledby="" role="img">
          😳
        </span>
        .
        <br /> <br /> Este tipo de seguro inclui todas as coberturas do “seguro legal obrigatório
        contra terceiros”. Mas também{' '}
        <strong>
          os danos causados no teu próprio veículo, mesmo quando és o responsável pelo acidente. No
          entanto, os danos que possas sofrer enquanto condutor responsável pelo acidente continuam
          a não estar cobertos por este seguro, ou seja tu continuas sem proteção!!
        </strong>
        <br /> <br />
        💡Claro que podes sempre
        <strong>acrescentar coberturas ao teu seguro automóvel! </strong>
        <br /> <br />
        <strong>
          Estas coberturas são opcionais e/ou complementares, quando não estão incluídas no teu
          seguro.
        </strong>
        Tais como, acidentes pessoais do condutor, quebra isolada de vidros, veículo de
        substituição, actos de vandalismo e fenómenos da natureza… enfim uma panóplia de opções para
        escolheres!
      </p>
    )

    const motoText = (
      <p className="description">
        Como tens uma moto, és obrigado(a) por lei a ter um seguro de responsabilidade civil
        automóvel <strong>quanto a terceiros</strong> 🙄. Conheces certamente como &quot;seguro
        contra terceiros&quot;.
        <br /> <br /> O que significa: em caso de acidente, e se fores tu o responsável pelo
        acidente , este seguro cobre os <strong>danos</strong> corporais (até ao valor de 6.070.000
        €) e materiais (até ao valor de 1.220.000 €)
        <strong> causados a terceiros e às pessoas transportadas.</strong> <br />
        <br /> O que significa isto na prática 👀? O seguro paga as despesas hospitalares e
        tratamentos médicos desses terceiros, os danos do outro veículo, os danos do edifício onde
        foste embater, etc. Mas atenção,
        <strong>
          o seguro não paga os teus{' '}
          <span aria-labelledby="" role="img">
            🤕
          </span>{' '}
          e os da tua 🏍 , por seres o condutor que causou o acidente.
        </strong>
        <br /> <br /> Existe também o <strong>seguro de danos próprios </strong>- mais completo -
        vulgarmente designado como &quot;seguro contra todos os riscos&quot;. Mas a expressão “todos
        os riscos” é um pouco enganadora
        <span aria-labelledby="" role="img">
          😳
        </span>
        .
        <br />
        <br />
        Este tipo de seguro inclui todas as coberturas do &quot;“seguro legal obrigatório contra
        terceiros”&quot;. Mas também{' '}
        <strong>
          os danos causados na tua moto, mesmo quando és o responsável pelo acidente. No entanto, os
          danos que possas sofrer enquanto condutor responsável pelo acidente continuam a não estar
          cobertos por este seguro, ou seja tu continuas sem proteção!!
        </strong>
        <br /> <br /> 💡Claro que podes sempre{' '}
        <strong> acrescentar coberturas ao teu seguro de moto!</strong>
        <br /> <br />
        <strong>
          Estas coberturas são opcionais e/ou complementares, quando não estão incluídas no teu
          seguro.
        </strong>
        Tais como, acidentes pessoais do condutor, danos no capacete, actos de vandalismo e
        fenómenos da natureza… enfim uma panóplia de opções para escolheres!
      </p>
    )

    const houseText = (
      <p className="description">
        Como és proprietário de uma{' '}
        <span aria-labelledby="" role="img">
          🏠
        </span>
        , e recorreste a um crédito bancário para a adquirir, então certamente o Seguro Multirriscos
        Habitação já não é, para ti, uma novidade! <br />
        <br />É prática habitual, os bancos{' '}
        <span aria-labelledby="" role="img">
          🏦
        </span>{' '}
        que dão o crédito bancário exigirem um seguro para o imóvel. Um seguro que
        <strong> cubra não só o risco de incêndio</strong> - este apenas obrigatório para os
        edifícios em regime de propriedade horizontal,
        <strong> mas também outros riscos que podem acontecer na tua casa ou apartamento.</strong>
        <br /> <br />
        <strong>
          Isto é, riscos causados por acontecimentos não intencionais, tais como{' '}
        </strong>{' '}
        catástrofes naturais, incêndios, explosões, furto/roubo, estragos causados por água,
        problemas elétricos, entre outros.
        <span aria-labelledby="" role="img">
          😱
        </span>{' '}
        <br /> <br /> Porque é que os Bancos exigem isto, perguntas{' '}
        <span aria-labelledby="" role="img">
          🤔
        </span>
        ? A intenção dos Bancos é salvaguardar o valor da tua casa 👍, quando algo de mal acontece.
        Porque a tua casa, habitualmente, é o bem que serve de garantia ao teu empréstimo.
      </p>
    )

    const lifeCreditText = (
      <p className="description">
        O seguro vida crédito habitação
        <strong> não é obrigatório por lei.</strong>
        <span aria-labelledby="" role="img">
          😳
        </span>
        <br /> <br />
        Mas quando fizeste o
        <strong>
          empréstimo bancário para aquisição da tua habitação, o Banco certamente te exigiu{' '}
        </strong>{' '}
        como condição que fizesses um seguro de vida, certo? <br /> <br /> O seguro de vida ligado
        ao crédito habitação funciona como uma
        <strong>garantia para o Banco</strong> de que este irá receber o que lhe é devido no caso de
        te acontecer alguma coisa muito má. Sim, estamos a falar de
        <span aria-labelledby="" role="img">
          ⚰️
        </span>
        <span aria-labelledby="" role="img">
          💀
        </span>
        ou invalidez
        <span aria-labelledby="" role="img">
          🤕
        </span>
        . <br /> <br />
        Neste tipo de seguro, <strong> o Banco é o beneficiário irrevogável</strong>, o que
        significa que caso deixes de poder pagar, o seguro está lá para garantir o pagamento
        <span aria-labelledby="" role="img">
          💰
        </span>
        . <br /> <br />
        No entanto,{' '}
        <strong>
          {' '}
          não és obrigado a fazer o seguro de vida que te é proposto pelo banco.{' '}
        </strong>{' '}
        Podes comprar um seguro de vida crédito por outra via.
      </p>
    )

    const petText = (
      <p className="description">
        Disseste que tinhas um
        <span aria-labelledby="" role="img">
          🐕
        </span>
        que é considerado, por lei, como &quot;“perigoso ou potencialmente perigoso”&quot;.
        <span aria-labelledby="" role="img">
          😱
        </span>
        <br /> <br />
        Para além de um dever de vigilância, a <strong>lei obriga</strong> a que tenhas um seguro de
        responsabilidade civil para o teu fiel parceiro, com um capital mínimo de 50.000,00 €
        <span aria-labelledby="" role="img">
          😳
        </span>
        .
        <br /> <br /> <strong>Para que serve este seguro?</strong> Numa situação (muito hipotética,
        já sei
        <span aria-labelledby="" role="img">
          🙄
        </span>
        ) este seguro cobre os danos, materiais e corporais, que o teu animal provoque, tais como
        reparar bens que danificou, pagar despesas médicas das pessoas atacadas, entre outros.
        <br /> <br /> Se não tiveres este seguro, podes ser multado entre 500€ e 3.740€ (se fores
        uma pessoa colectiva por ir até 44.890€
        <span aria-labelledby="" role="img">
          😨
        </span>
        ). E ainda, consoante a gravidade da situação podes perder o teu animal.
      </p>
    )

    const selfEmployedText = (
      <p className="description">
        És um trabalhador independente, trabalhas por conta própria
        <span aria-labelledby="" role="img">
          💪
        </span>
        . Por outras palavras passas “a recibos verdes”.
        <br /> <br />
        Então, <strong>tens a obrigação legal</strong> de teres um{' '}
        <strong>seguro de acidentes de trabalho para trabalhador independente</strong>
        <span aria-labelledby="" role="img">
          😳
        </span>
        .
        <br /> <br />
        <span aria-labelledby="" role="img">
          ⚠️
        </span>
        Isto é verdade, mesmo que exerças em simultâneo com uma atividade por contra de outrém, ou
        seja que também trabalhes como trabalhador dependente. <br /> <br /> Caso sofras um
        acidente, tu e os teus familiares, se for o caso, estão protegidos, pois o seguro
        assegura-te rendimentos, pagamento de despesas médicas, entre outros.
      </p>
    )
    const employeeText = (
      <p className="description">
        Como dono de uma empresa com colaboradores à tua responsabilidade, a lei obriga a que a tua
        empresa tenha um seguro de acidentes de trabalho para os teus colaboradores.
        <br /> <br /> Este seguro cobre os acidentes que ocorram aos teus colaboradores no
        desempenho das suas funções na empresa. Os encargos resultantes desses acidentes ficam a
        cargo da Seguradora em alternativa a ter de ser a tua empresa a pagar…
      </p>
    )
    const lifeText = (
      <p className="description">
        De uma forma muito simples, recomendamos-te um seguro de vida porque para ti a família é
        importante e/ou tens filhos menores. <br /> <br /> E como tal, se algo de grave te
        acontecer, tens a situação financeira salvaguardada. A tua e a deles...
        <br /> <br /> Num seguro de vida, o que está a ser seguro é a tua pessoa. Isto é, a
        Seguradora paga um valor (alto) em euros, no caso de te acontecer algo de muito grave. Sim,
        estamos a falar de
        <span aria-labelledby="" role="img">
          💀
        </span>
        ou invalidez séria.
        <br /> <br /> Se o pior te acontecer, a Seguradora paga o valor que contrataste aos teus
        herdeiros (por exemplo, os teus filhos) ou outra(s) pessoa(s) que nomeies. <br /> <br />
        Se tiveres uma doença ou acidente que te impeça de desempenhar a tua profissão, a Seguradora
        dá-te a ti esse valor, garantindo que continuas a ter dinheiro para o que precisas.
      </p>
    )
    const contentText = (
      <p className="description">
        Não és proprietário da
        <span aria-labelledby="" role="img">
          🏠
        </span>
        onde habitas. Mas o que está lá dentro
        <span aria-labelledby="" role="img">
          📺💻💍
        </span>
        é teu, certo?
        <br /> <br />
        Acidentes acontecem
        <span aria-labelledby="" role="img">
          🙏
        </span>
        … roubos, inundações, curto circuitos...Este seguro, em caso de acidente, cobre as despesas
        de reparação e/ou substituição dos teus bens. <br /> <br />
        Só tens de te preocupar em aproveitá-los ao máximo .
      </p>
    )
    const extraPetText = (
      <p className="description">
        Um seguro de animais domésticos mais simples, dá-te proteção financeira para os estragos que
        o teu animal possa causar. <br /> <br />
        Mas pode ir mais longe, e funcionar como um seguro de saúde mas para o teu
        <span aria-labelledby="" role="img">
          🐱
        </span>
        ou
        <span aria-labelledby="" role="img">
          🐶
        </span>
        . <br /> <br /> Pode assegurar o reembolso de despesas com o veterinário - vacinas,
        consultas médicas, cirurgias -, com medicamentos e até com o bem-estar do teu fiel
        companheiro.
      </p>
    )
    const healthText = (
      <p className="description">
        A tua saúde e da tua família é, para ti, importante. Percebo-te bem
        <span aria-labelledby="" role="img">
          😊
        </span>
        ! <br /> <br />
        Este seguro apoia-te a ti, e se quiseres, os teus, com as despesas com a saúde. <br />{' '}
        <br /> Este tipo de seguro pode ser menos ou mais completo - incluir ou não medicina
        dentária, oncologia, entre outros. E pode também variar na forma de apoio - por reembolso ou
        por comparticipação.
      </p>
    )
    const housekeeperText = (
      <p className="description">
        Quase que adivinho que tens uma empregada doméstica. Acertei? <br />
        <br />
        Se tens uma empregada doméstica, mesmo que seja por algumas horas por semana, então{' '}
        <strong>a lei obriga-te</strong> a ter um seguro de acidentes de trabalho para a tua
        empregada doméstica.
        <br /> <br /> Se não tiveres, habilitas-te a sofrer uma multa! Para que serve este seguro?{' '}
        <br /> <br />
        Se algum acidente acontecer nas deslocações de e para a tua casa, ou enquanto estiver a pôr
        a tua casa num brinco… o seguro paga as despesas médicas, de tratamento, entre outras, assim
        como lhe garante uma indemnização pela sua incapacidade temporária de trabalhar, enquanto
        estiver a recuperar…
      </p>
    )
    const equipmentText = (
      <p className="description">
        Quão importante é o teu telemóvel para o teu trabalho? Ou o teu portátil ou computador?{' '}
        <br /> <br />
        Se são importantes, então deverias pensar em ter um seguro que cubra as despesas de
        reparação ou substituição desses equipamentos, caso algo lhes aconteça (por exemplo, danos
        por água, roubo)
      </p>
    )
    const civilresponsabilityText = (
      <p className="description">
        Para determinadas profissões ou atividades, o seguro de{' '}
        <strong>responsabilidade civil é obrigatório</strong>. A tua profissão ou atividade é uma
        delas
        <span aria-labelledby="" role="img">
          🙄
        </span>
        !
        <br /> <br /> Este seguro visa indemnizar os danos que causes a terceiros, no exercício do
        teu trabalho.
        <br /> <br /> O que o seguro deve abranger está definido por lei, incluindo o capital mínimo
        a segurar. E varia de profissão para profissão, e de atividade para atividade.
      </p>
    )
    const childText = (
      <p className="description">
        Quem tem filhos, tem alegrias, mas também tem trabalhos dobrados!! Dois olhos e duas mãos,
        por vezes não chegam, sobretudo quando são mais de dois!
        <span aria-labelledby="" role="img">
          😉
        </span>
        <br /> <br /> Se és Pai ou Mãe, isto não é novidade! Por vezes os nossos "pestinhas",
        involuntariamente, causam danos a terceiros. E no dia a dia, é fácil isso acontecer. Basta
        serem saudáveis e ... crianças!!
        <br /> <br /> Este seguro permite-te estares tranquilo, porque dá-te protecção para fazer
        face a um contratempo, uma despesa extra por danos a terceiros causados pela tua criança.
      </p>
    )
    const paAccidentText = (
      <p className="description">
        O seguro de acidentes pessoais é facultativo, ou seja, *não é obrigatório por lei. *
        <br /> <br /> Não confundas com o seguro de acidentes de trabalho, este sim obrigatório,
        quer para os trabalhadores dependentes, quer para os trabalhadores independentes.
        <br /> <br /> Quanto a este seguro, tu é que decides!
        <span aria-labelledby="" role="img">
          💪
        </span>
        <br /> <br />O seguro de acidentes pessoais cobre a tua pessoa. Dá-te proteção financeira,
        bem como à tua família, no caso de sofreres um acidente na tua vida particular ou
        profissional. Tem como principais coberturas a morte ou invalidez, despesas de funeral,
        despesas tratamento por lesões corporais, hospitalização, etc.
        <br /> <br />
        Como o “seguro morreu de velho”, é sempre bom pensares em fazer este seguro, mesmo que
        tenhas um estilo de vida tranquilo! Pois acidentes súbitos e imprevisíveis podem acontecer a
        todos … mesmo a ti!!! Nestas coisas, não basta ser optimista!
        <span aria-labelledby="" role="img">
          😜
        </span>
      </p>
    )
    const droneText = (
      <p className="description">
        És um entusiasta de gadgets e andas fascinado com o teu "drone"!
        <br /> <br />
        Mas não percas o "norte". 😜
        <br /> <br />
        Se o teu drone drone tem mais de 900 gramas, então é obrigatório teres um seguro de
        responsabilidade civil, que proteja terceiros dos danos que o teu drone possa causar.
        <br /> <br /> Este seguro "cabe" no teu smartphone… a mochila fica para os teus gadgets 😉
      </p>
    )
    const bicycleText = (
      <p className="description">
        Uma bicicleta é uma ótima companheira! Entendo-te bem… ☺️ Mas também é importante pensares
        no que de menos bom pode acontecer enquanto pedalas.
        <br /> <br />
        Um seguro de bicicleta protege os danos ou estragos que causes em terceiros ou coisas de
        terceiros…
        <br /> <br />
        Pode proteger-te a ti próprio, em caso de acidente 🤕, para que nada te falte, e para que
        possas retomar as tuas pedaladas o quanto antes.
      </p>
    )

    const scooterText = (
      <p className="description">
        Como andas de trotinete 🛴, deves pensar em ter um seguro que te protege a ti, uma vez que
        para além do capacete não tens qualquer proteção em caso de acidente...
        <br />
        <br />
        Assim como, considerar um seguro que repare a tua trotinete se ficar em mau estado… e
        assistência em viagem, para que possas ser transportado(a) em caso de acidente ou avaria…
      </p>
    )

    const dentalText = (
      <p className="description">
        Aquele dente 🦷 que não dói mas mói… difícil perceber como uma coisa tão pequena pode criar
        tanto desconforto e mau estar…
        <br />
        <br />O Seguro Dentário permite-te ter descontos em consultas, exames e tratamentos para que
        possa sempre sorrir!
      </p>
    )

    const criticalIllnessText = (
      <p className="description">
        O Seguro de Doenças Graves paga uma indemnização no momento de diagnóstico de uma doença
        grave como Cancro ou AVC.
        <br />
        <br />E podes usar esse valor como quiseres… no teu tratamento, ou simplesmente numa viagem,
        para que possas ter alguma boa notícia, e te focares apenas na tua recuperação.
      </p>
    )

    const hospitalizationText = (
      <p className="description">
        Sabias que nos primeiros 30 dias de baixa médica, apenas recebes 55% do teu salário? 😱
        <br />
        <br />
        Se estás internado, o mais provável é que as despesas aumentem, e deves estar apenas
        focado(a) na tua recuperação e não em como podes pagar as contas ao final do mês.
        <br />
        <br />O Seguro de Hospitalização 🏥 é um complemento aos teus rendimentos, enquanto estás
        internado(a) e durante a recuperação em casa.
      </p>
    )

    const familyText = (
      <p className="description">
        Os imprevistos acontecem… e partir ou estragar coisas que não são tuas, também!
        Principalmente, porque tens diabrete(s) em casa!🚸
        <br />
        <br />
        Com o Seguro Família, a Seguradora paga os prejuízos que o teu agregado familiar causem em
        terceiros ou coisas de terceiros. Animais domésticos incluídos 🐕🐈. Empregada doméstica
        incluída 🧹.
      </p>
    )

    const investmentText = (
      <p className="description">
        Um Seguro de Investimento é uma boa alternativa a depósitos a prazo ou outras aplicações com
        uma baixíssima taxa de juro..
        <br />
        <br />
        Mas será que faz sentido para ti? ☑️ Se tens algumas poupanças no mealheiro, e queres
        colocá-las a render, com rendimento e capital garantidos.
        <br />
        <br />
        ☑️ Se és um investidor conservador, que gosta de saber que as suas poupanças não estão em
        risco, mas também não estão paradas a ganhar pó.
      </p>
    )

    const pprText = (
      <p className="description">
        As últimas análises de especialistas indicam que, quando chegares à reforma, apenas vais
        receber cerca de 50% dos teus rendimentos. Isto porque a Seg. Social não vai conseguir pagar
        mais… apesar de estares a descontar todos os meses…
        <br />
        <br />
        Deves por isso pensar num PPR (plano poupança reforma), para que possas usufruir da tua
        liberdade e seres financeiramente independente quanto te reformares… colocas de lado, um
        pouco todos os meses, para teres esse capital mais tarde!
      </p>
    )

    const thirdPartyProfessionalText = (
      <p className="description">
        Para a tua profissão a lei impõe que tenhas um seguro de responsabilidade civil
        profissional. 📄
        <br />
        <br />
        Este seguro cobre danos ou estragos que possas causar a terceiros fruto da tua atividade
        profissional. No fundo, é a seguradora que vai indemnizar os teus clientes por erros ou
        omissões que possas causar, em vez de ter de sair do teu bolso!
      </p>
    )

    const thirdPartyPetText = (
      <p className="description">
        Pelo que me disseste o teu cão 🐕 enquadra-se no que é considerado um animal perigoso ou
        potencialmente perigoso… eu sei, para ti é dócil, mas a lei trata todos os animais desta
        raça dessa forma.
        <br />
        <br />
        Como tal, tens de ter um seguro de responsabilidade civil para o teu cão. Este seguro cobre
        os danos ou estragos que o teu cão possa causar em terceiros ou coisas de terceiros.
      </p>
    )

    const sportsText = (
      <p className="description">
        Praticas desporto… fazes muito bem, porque corpo são, mente sã!
        <br />
        <br />
        Mas acidentes podem acontecer 🤕… e como tal convém que tenhas o apoio necessários se isso
        acontecer… quer no pagamento de despesas médicas 🏥,, quer nos danos que possas causar a
        outros ou até, num acidente com gravidade, se ficares com alguma incapacidade!
      </p>
    )

    this.assoc = {
      thirdparty: thirdPartyProfessionalText,
      civilresponsability: civilresponsabilityText,
      personalaccident: paAccidentText,
      workaccidentselfemployeed: selfEmployedText,
      workaccidentemployee: employeeText,
      bicycle: bicycleText,
      scooter: scooterText,
      boat: loremipsun,
      home: houseText,
      buildings: houseText,
      car: carText,
      child: childText,
      contents: contentText,
      content: contentText,
      dental: dentalText,
      drone: droneText,
      health: healthText,
      housekeeper: housekeeperText,
      hunter: loremipsun,
      income: loremipsun,
      life: lifeText,
      lifecredit: lifeCreditText,
      mobile: loremipsun,
      equipment: equipmentText,
      mortgage: lifeCreditText,
      bike: motoText,
      other: loremipsun,
      pet: petText,
      extrapet: extraPetText,
      travel: loremipsun,
      criticalillness: criticalIllnessText,
      hospitalization: hospitalizationText,
      family: familyText,
      investment: investmentText,
      ppr: pprText,
      thirdpartyprofessional: thirdPartyProfessionalText,
      thirdpartypet: thirdPartyPetText,
      sports: sportsText
    }
  }

  getText() {
    return this.assoc[this.type.toLowerCase()]
  }
}
