export default class Icons {
  constructor(icon) {
    this.type = icon

    this.assoc = {
      thirdparty: '3rdParty.svg',
      civilresponsability: '3rdParty.svg',
      '3rdparty': '3rdParty.svg',
      'acidentes pessoais': 'Accident.svg',
      personalaccident: 'Accident.svg',
      workaccident: 'WorkAccident.svg',
      workaccidentselfemployeed: 'WorkAccident.svg',
      workaccidentemployee: 'WorkAccident.svg',
      'personal accident': 'Accident.svg',
      bicycle: 'Bicycle.svg',
      boat: 'Boat.svg',
      home: 'Buildings.svg',
      buildings: 'Buildings.svg',
      car: 'Car.svg',
      child: 'Child.svg',
      contents: 'Contents.svg',
      content: 'Contents.svg',
      criticalillness: 'CriticalIllness.svg',
      dental: 'Dental.svg',
      drone: 'Drone.svg',
      health: 'Health.svg',
      housekeeper: 'Housekeeper.svg',
      hunter: 'Hunter.svg',
      hospitalization: 'Hospitalização.svg',
      income: 'Income.svg',
      life: 'Life.svg',
      lifecredit: 'Mortgage.svg',
      mobile: 'Mobile.svg',
      equipment: 'Mobile.svg',
      mortgage: 'Mortgage.svg',
      dead: 'Mortgage.svg',
      bike: 'Motorbike.svg',
      other: 'Other.svg',
      pet: 'Pet.svg',
      extrapet: 'Pet.svg',
      bicycle: 'Bicycle.svg',
      travel: 'Travel.svg',
      scooter: 'Scooter.svg',
      family: 'Family.svg',
      gadget: 'Gadget.svg',
      extendedwarranty: 'ExtendedWarranty.svg',
      whitegoods: 'White_goods.svg',
      APRIL: 'april.jpg',
      ASISA: 'asisa.jpg',
      EUROP: 'europ.jpg',
      INTER: 'inter.jpg',
      LUSITANIA: 'lusitania.jpg',
      LIBERTY: 'liberty.png',
      ALLIANZ: 'allianz.png',
      MAPFRE: 'mapfre.png',
      METLIFE: 'metlife.png',
      PREVOIR: 'prevoir.png',
      child: 'Child.svg',
      sports: 'Sports.svg',
      investment: 'Investment.svg',
      locallodging:'LocalLodging.svg',
      ppr: 'PPR.svg',
      thirdpartypet: 'Thirdpartypet.svg',
      thirdpartyprofessional: '3rdParty.svg',
      warranty: 'Warranty.svg'
    }
  }

  getPurple() {
    if (this.assoc[this.type.toLowerCase()] == undefined) {
      console.log('purple image not found', this.type.toLowerCase())
      return ''
    }
    return require('../../images/types/purple/' + this.assoc[this.type.toLowerCase()])
  }
  getWhite() {
    if (this.assoc[this.type.toLowerCase()] == undefined) {
      console.log('white image not found', this.type.toLowerCase())
      return ''
    }
    return require('../../images/types/white/' + this.assoc[this.type.toLowerCase()])
  }
  getBrand() {
    if (this.assoc[this.type]) {
      const url = require('../../images/icons/insurers_logos/' + this.assoc[this.type])
      return url
    }
    return null
  }
}
