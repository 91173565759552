import Icons from '../ui/Icons'
import Layout from '../layout'
import PropTypes from 'prop-types'
import Ql from '../../api/ql'
import React from 'react'
import { translateApiValue } from '../utils'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import PartnersHeader from '../../../src/components/utils/partners-header'
import { mapCountryCodeToName } from '../../utils/countries'

class PolicyApplications extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      items: null
    }

    this.goTo = this.goTo.bind(this)
  }

  componentDidMount() {
    this.setState({ loading: true })
    new Ql('userApplications')
      .get()
      .then(response => {
        this.setState({ items: response.data.getUserOpenApplicationsList.data })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  goTo(path) {
    this.props.history.push(path)
  }

  render() {
    let policies = null

    if (this.state.items) {
      policies = this.state.items.map(item => {
        return (
          <div
            className="policy-item"
            key={item.id}
            onClick={() => {
              window.location.href = item.url
            }}>
            <div className="policy-item__title">
              <img alt="policy-icon" src={new Icons(item.type).getPurple()} />
              <span>
                {translateApiValue(
                  item.type.charAt(0).toUpperCase() + item.type.substr(1).toLowerCase()
                )}
              </span>
            </div>
            <div className="policy-item__content">
              <div className="policy-item__node hidden-size-md">
                {item.type === 'HEALTH' || item.type === 'DENTAL' ? (
                  <>
                    <span>Nº Pessoas</span>
                    <strong>{item.description ? item.description : '-'}</strong>
                  </>
                ) : (
                  <>
                    <span>Descrição</span>
                    {item.type === 'TRAVEL' ? (
                      <strong className="policy-item-content-travel">
                        {item.description.split(' ').map((e, i) => (
                          <span key={i} style={{ color: '#000', fontWeight: 'bold', fontSize: 18 }}>
                            {mapCountryCodeToName(e)}
                          </span>
                        ))}
                      </strong>
                    ) : item.type === 'LOCALLODGING' ? (
                      <strong>
                        {item.description
                          ? translateApiValue(item.description + '_LOCALLODGING')
                          : '-'}
                      </strong>
                    ) : (
                      <strong>
                        {item.description ? translateApiValue(item.description) : '-'}
                      </strong>
                    )}
                  </>
                )}
              </div>
              <div className="policy-item__node">
                <span>Criado em</span>
                <strong>
                  {item.createdAt
                    ? moment(moment(item.createdAt, 'YYYY-MM-DD')).format('DD-MM-YYYY')
                    : '-'}
                </strong>
              </div>
              <div className="policy-item__node">
                <span>Expira em</span>
                <strong>
                  {item.expiresAt
                    ? moment(moment(item.expiresAt, 'YYYY-MM-DD')).format('DD-MM-YYYY')
                    : '-'}
                </strong>
              </div>
              <div
                className="clickable"
                onClick={() => {
                  window.location.href = item.url
                }}>
                <p id="moreinfo">Mais Info</p>
              </div>
            </div>
          </div>
        )
      })
    }
    return (
      <Layout loading={this.state.loading}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{process.env.REACT_APP_NAME} | Aplicações</title>
        </Helmet>
        <div>
          <div className=" header-partner-wrapper">
            <h1>As Minhas Aplicações</h1>

            <PartnersHeader />
          </div>
          <button
            className="simular-seguro"
            >
            <a onClick={()=>this.props.history.push('/comprar')}>Simular Seguro</a>
          </button>
          <p className="description-short-text page-description">
            Guardamos as tuas aplicações para que possas continuar da página em que saíste.
          </p>

          <div className="policies-list-container">
            <div className="clearfix" />
            <div className="policies-list">{policies}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

PolicyApplications.propTypes = {
  history: PropTypes.any.isRequired
}

export default PolicyApplications
