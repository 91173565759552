import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import 'react-datepicker/dist/react-datepicker.css'
import { RenderInputField } from '../renderInputField'
import SingleSelectProvider from '../SingleSelectProvider'
import SingleTabSelect from '../SingleTabSelect'
import MultiTabSelect from '../MultiTabSelect'
import DualTabSelect from '../DualTabSelect'
import Form from '../../../../../../components/ui/Form'

export default function FormSection({ setForm, advisorQuestions, defaultValues }) {
  const [formValues, setFormValues] = useState({})
  const [isError, setIsError] = useState(null)

  const submitHandler = () => {
    let er = false
    if (Object.values(formValues).length == 0) {
      setIsError(true)
      er = true
    } else {
      for (var q of advisorQuestions.questions) {
        if (!Object.keys(formValues).includes(q.uuid)) {
          setIsError(true)
          er = true
        } else {
          if (formValues[q.uuid] == null || formValues[q.uuid] == '') {
            setIsError(true)
            er = true
          }

          if (q.questionType == 'DUAL_SELECT') {
            if (formValues[q.uuid].length != 2) {
              setIsError(true)
              er = true
            }
          }
        }

        if (q.child !== null) {
          for (var childQuestion of q.child) {
            var filteredArray = childQuestion.select.filter(function(n) {
              return typeof formValues[q.uuid] == 'object'
                ? formValues[q.uuid].indexOf(n) !== -1
                : formValues[q.uuid] == n
            })

            if (
              filteredArray.length > 0 &&
              (formValues[childQuestion.uuid] == undefined || formValues[childQuestion.uuid] == '')
            ) {
            
              setIsError(true)
              er = true
            }

            if (filteredArray.length == 0) {
              formValues[childQuestion.uuid] = ''
            }
          }
        }
      }
    }

    if (er === false) {
      setForm(formValues)
    }
  }

  const updateFormValues = (value, questionUUID) => {
    if (value.length != 0) {
      const currentFormValues = { ...formValues }
      currentFormValues[questionUUID] = value
      setFormValues(currentFormValues)
    }
  }

  useEffect(() => {
    setFormValues(defaultValues)
    if (defaultValues) {
      const newFormValues = { ...formValues }
      for (var q of advisorQuestions.questions) {
        if (defaultValues[q.uuid]) {
          newFormValues[q.uuid] =
            defaultValues[q.uuid].length > 0 ? defaultValues[q.uuid] : defaultValues[q.uuid][0]
        }

        if (q.child !== null) {
          for (var childQ of q.child) {
            if (defaultValues[childQ.uuid]) {
              newFormValues[childQ.uuid] =
                defaultValues[childQ.uuid].length > 0
                  ? defaultValues[childQ.uuid]
                  : defaultValues[childQ.uuid][0]
            }
          }
        }
      }
      setFormValues(newFormValues)
    }
  }, [defaultValues])

  return (
    <div className="secondSection">
      <form action="#">
        {advisorQuestions &&
          advisorQuestions.questions.map((item, index) => (
            <div
              className={`QA ${
                isError == true &&
                (formValues[item.uuid] == null ||
                  !formValues[item.uuid].length ||
                  (item.questionType == 'DUAL_SELECT' &&
                    formValues[item.uuid].length > 0 &&
                    formValues[item.uuid].length != 2))
                  ? ' input-error'
                  : ''
              }`}
              key={index}>
              {/* Question */}
              {/* eslint-disable react/no-danger */}
              {item.question.split('\n').map((qName, i) => (
                <p
                  dangerouslySetInnerHTML={{ __html: qName }}
                  className="question"
                  key={i}
                  style={{ paddingTop: i > 0 ? '0px' : '10px' }}
                />
              ))}

              {/* Input field */}
              {item.questionType == 'DATE' ? (
                <RenderInputField
                  onInput={value => {
                    updateFormValues(value, item.uuid)
                  }}
                  isError={isError}
                  defaultValue={defaultValues && defaultValues[item.uuid]}
                />
              ) : item.questionType === 'LIST' ? (
                <SingleSelectProvider
                  onSelect={value => {
                    updateFormValues(value, item.uuid)
                  }}
                  onChildInput={(value, quuid) => {
                    updateFormValues(value, quuid)
                  }}
                  defaultValue={defaultValues}
                  item={item}
                  formValues={formValues}
                  isError={isError}
                />
              ) : item.questionType == 'SINGLE_SELECT' ? (
                <SingleTabSelect
                  onSelect={value => {
                    updateFormValues(value, item.uuid)
                  }}
                  onChildInput={(value, quuid) => {
                    updateFormValues(value, quuid)
                  }}
                  defaultValue={defaultValues}
                  item={item}
                  formValues={formValues}
                  isError={isError}
                />
              ) : item.questionType == 'MULTI_SELECT' ? (
                <MultiTabSelect
                  onSelect={value => {
                    updateFormValues(value, item.uuid)
                  }}
                  onChildInput={(value, quuid) => {
                    updateFormValues(value, quuid)
                  }}
                  defaultValue={defaultValues}
                  item={item}
                  formValues={formValues}
                  isError={isError}
                />
              ) : item.questionType == 'DUAL_SELECT' ? (
                <DualTabSelect
                  onSelect={value => {
                    updateFormValues(value, item.uuid)
                  }}
                  onChildInput={(value, quuid) => {
                    updateFormValues(value, quuid)
                  }}
                  defaultValue={defaultValues}
                  item={item}
                  formValues={formValues}
                  isError={isError}
                />
              ) : (
                <Form
                  onValueChange={v => {
                    updateFormValues(v, item.uuid)
                  }}
                  value={defaultValues && defaultValues[item.uuid]}
                  type="input"
                />
              )}

              {/* Error Message */}
              <p className="error-text">
                {isError == true && (formValues[item.uuid] == null || !formValues[item.uuid].length)
                  ? 'Tens de preencher este campo'
                  : ''}
              </p>
              <p className="error-text">
                {isError == true &&
                formValues[item.uuid] != undefined &&
                item.questionType == 'DUAL_SELECT' &&
                formValues[item.uuid].length > 0 &&
                formValues[item.uuid].length != 2
                  ? 'Selecione 2 opções'
                  : ''}
              </p>
            </div>
          ))}

        <button type="button" onClick={submitHandler} className="button-enabled">
          CONTINUAR
        </button>
      </form>
    </div>
  )
}

FormSection.propTypes = {
  setForm: PropTypes.func.isRequired,
  advisorQuestions: PropTypes.any.isRequired,
  defaultValues: PropTypes.any.isRequired,
  isError: PropTypes.any.isRequired
}
