export const requiredKeysList = [
  'firstName',
  'lastName',
  'email',
  'vat',
  'idNumber',
  'licenseNumber',
  'licenseDate',
  'address',
  'postcode',
  'birthday',
  'profession',
  'jobTitle',
  'maritalStatus',
  'childrenNr',
  'annualSalary',
  'annualFixesExpenses',
  'houses',
  'vehicles',
  'hobbies',
  'pets'
]
