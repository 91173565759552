import _ from 'lodash';

export const translateApiValue = (value, type = '', context = '') => {
  let translatedString = ''
  if (!value || typeof value !== 'string') return value
  switch (value.toUpperCase()) {
    case 'YES':
      return 'Sim'
    case 'NO':
      return 'Não'
    case 'ANNUAL':
      return 'Anual'
    case 'SEMESTER':
      return 'Semestral'
    case 'QUARTERLY':
      return 'Trimestral'
    case 'MONTHLY':
      return 'Mensal'
    case 'APARTMENT':
      return 'Apartamento'
    case 'HOUSE':
      return 'Casa'
    case 'MULTIRISKS':
      return 'Casa (Multirriscos)'
    case 'PRIVATECONDOMINIUM':
      return 'Condominio Privado'
    case 'FARM':
      return 'Quinta'
    case 'OTHER':
      return 'Outro'
    case 'PERSONALACCIDENT':
      return 'Acidentes Pessoais'
    case 'WORKACCIDENT':
      return 'Acidentes Trabalho'
    case 'CAR':
      return 'Carro'
    case 'THIRDPARTY':
      return 'Responsabilidade Civil'
    case 'VAN':
      return 'Carrinha'
    case 'SCOOTER':
      return 'Trotinete'
    case 'MINIVAN':
      return 'Carrinha 2 lugares'
    case 'SUV':
      return 'Monovolume'
    case 'SCOOTER':
      return 'Scooter'
    case 'MOTO':
      return 'Moto'
    case 'COMERCIAL CAR':
      return 'Carro 2 lugares'
    case 'TRACTOR':
      return 'Trator'
    case 'COMERCIAL_CAR':
      return 'Veículo Comercial'
    case 'DOG':
      return 'Cão'
    case 'CAT':
      return 'Gato'
    case 'HORSE':
      return 'Cavalo'
    case 'RABBIT':
      return 'Coelho'
    case 'FISH_TURTLE':
      return 'Tartaruga'
    case 'SEPARATED':
      return 'Separado/a'
    case 'DIVORCED':
      return 'Divorciado/a'
    case 'WIDOWED':
      return 'Viúvo/a'
    case 'PROFESSIONAL':
      return 'Profissional'
    case 'MARRIED':
      return 'Casado/a'
    case 'SINGLE':
      // ! "type.indexOf('seguradoratype') !== -1" looks like stale code
      if (_.upperCase(context) === 'PAYMENT') {
        translatedString = 'Único'
      } else if (type.indexOf('seguradoratype') !== -1 || type === 'TRAVEL') {
        translatedString = 'Temporário'
      } else {
        translatedString = 'Solteiro/a'
      }
      return translatedString 
    case 'VOLUNTEERING':
      return 'Voluntariado'
    case 'PHOTOGRAPHY':
      return 'Fotografia'
    case 'SURF':
      return 'Surf'
    case 'CLIMBING':
      return 'Escalada'
    case 'BIKE':
      return 'Moto'
    case 'CHILD':
      return 'Criança'
    case 'DRONE':
      return 'Drone'
    case 'READING':
      return 'Ler'
    case 'COOKING':
      return 'Cozinhar'
    case 'PAINTING':
      return 'Pintar'
    case 'FISHING':
      return 'Pescar'
    case 'HUNTING':
      return 'Caçar'
    case 'GARDENING':
      return 'Jardinagem'
    case 'SEWING':
      return 'Costurar'
    case 'MULTIRISK':
      return 'Edificio'
    case 'FILLING':
      return 'Recheio'
    case 'ALL':
      return 'Morte e Invalidez'
    case 'HOME':
      return 'Casa (Multirriscos)'
    case 'PRINCIPAL':
      return 'Principal'
    case 'SECONDARY':
      return 'Secundária'
    case 'RENTING':
      return 'Arrendada'
    case 'PERMANENT':
      return 'Permanente'
    case 'LOCAL':
      return 'Local'
    case 'UNOCCUPIED':
      return 'Não ocupada'
    case 'DEAD':
      return 'Morte'
    case 'DISABLEMENT':
      return 'Invalidez'
    case 'SIXTHY':
      return '60%'
    case 'SIXTHYFIVE':
      return '65%'
    case 'SIXTHYSIX':
      return '66%'
    case 'SEVENTY':
      return '70%'
    case 'ABSOLUTE':
      return 'Definitiva e Absoluta'
    case 'THIRDPARTIES':
      return 'Contra Terceiros'
    case 'ALLRISKS':
      return 'Todos os Riscos'
    case 'MOTORBIKE':
      return 'Moto'
    case 'BOAT':
      return 'Barco'
    case 'BICYCLE':
      return 'Bicicleta'
    case 'BUILDING':
      return 'Casa (Multirriscos)'
    case 'HOUSEKEEPER':
      return 'Empregada Doméstica'
    case 'CRITICALILLNESS':
      return 'Doenças Graves'
    case 'HEALTH':
      return 'Saúde'
    case 'LIFE (MORTGAGE)':
      return 'Vida (Crédito Habitação)'
    case 'MORTGAGE':
      return 'Vida (Crédito Habitação)'
    case 'LIFE':
      return 'Vida Proteção'
    case 'DENTAL':
      return 'Dentário'
    case 'PET':
      return 'Animais Domésticos'
    case 'TRAVEL':
      return 'Viagem'
    case 'PERSONALACCIDENT':
    case 'PERSONAL ACCIDENT':
      return 'Acidentes Pessoais'
    case 'THIRDPARTY':
    case '3RD PARTY':
      return 'Responsabilidade Civil'
    case 'MOBILE':
      return 'Telemóvel'
    case 'HUNTER':
      return 'Caçador'
    case 'INCOME':
    case 'INCOME PROTECTION':
      return 'Proteção Rendimento'
    case 'FAMILY':
      return 'Família'
    case 'PROFESSIONAL':
      return 'Profissional'
    case 'DEPENDENT':
      return 'Acid. Trab. (Outrém)'
    case 'INDEPENDENT':
      return 'Acid. Trab. (Independentes)'
    case 'MEDICAL_EXAMS':
      return 'Ambulatório'
    case 'HOSPITALIZATION':
      return 'Hospitalização'
    case 'STOMATOLOGY':
      return 'Estomatologia'
    case 'SERIOUS_DISEASES':
      return 'Doenças Graves'
    case 'OPHTHALMOLOGY':
      return 'Oftalmologia'
    case 'MEDICAL_APPOINTMENTS':
      return 'Consultas Médicas'
    case 'DENTAL':
      return 'Dentista'
    case 'CHILDBIRTH':
      return 'Parto'
    case 'PHYSIOTHERAPY':
      return 'Fisioterapia'
    case 'WHITEGOODS':
      return 'Eletrodomésticos'
    case 'GADGET':
      return 'Aparelhos eletrónicos'
    case 'EXTENDEDWARRANTY':
      return 'Extensão de Garantia'
    case 'WARRANTY':
      return 'Garantia'
    case 'TRAVEL_ASSISTANCE':
      return 'Assistência Viagem'
    case 'BROKEN_GLASS':
      return 'Quebra Vidros'
    case 'THEFT':
      return 'Furto e Roubo'
    case 'CAR_REPLACEMENT':
      return 'Veículo Substituição'
    case 'NEW_VALUE':
      return 'Valor em novo'
    case 'PARTIALTIME':
      return 'Tempo Parcial'
    case 'FULLTIME':
      return 'Tempo Inteiro'
    case 'LEGAL':
      return 'Proteção jurídica'
    case 'PASSENGERS':
      return 'Proteção passageiros'
    case 'DRIVERS':
      return 'Proteção condutores'
    case 'ONETIME':
      return 'Único'
    case 'PPR':
      return 'PPR'
    case 'INVESTMENT':
      return 'Investimento'
    case 'LOCALLODGING':
      return 'Alojamento Local'
    case 'HOSTEL_LOCALLODGING':
      return 'Estabelecimento de Hospedagem'
    case 'HOME_LOCALLODGING':
      return 'Moradia'
    case 'APARTMENT_LOCALLODGING':
      return 'Apartamento'  
    case 'JUSTME':
      return 'Individual'
    case 'MORETHANTWO':
      return 'Familiar'
    case 'TOTAL':
      return 'Total'
    case 'ANNUAL':
      return 'Anual'
    case 'SEMESTER':
      return 'Semestral'
    case 'QUARTERLY':
      return 'Trimestral'
    case 'MONTHLY':
      return 'Mensal'
    case 'ONETIME':
      return 'Único'      
    default:
      return value
  }
}
