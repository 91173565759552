import React, { useState, useEffect } from 'react'
// import Select from 'react-select'
import PropTypes from 'prop-types'
import ChildComponent from './childComponent'
import CheckableAntTag from '../../../../../components/Tags'
// import { selectHttpOptionsAndBody } from '@apollo/client'

// import '../../../../../common.less'
// **************** UTILS ****************
// import getProvider from '../../../../../utils/getProviders'

// This is the topmost <select component which is used only once in this section
export default function SingleTabSelect({
  item,
  onSelect,
  onChildInput,
  defaultValue,
  isError,
  formValues
}) {
  const [selectedOption, setSelectedOption] = useState(null)
  const [showChild, setShowChild] = useState([])

  useEffect(() => {
    if (defaultValue && defaultValue[item.uuid]) {
      if (typeof defaultValue[item.uuid] == 'object') {
        setSelectedOption(defaultValue[item.uuid][0])
      } else {
        setSelectedOption(defaultValue[item.uuid])
      }
    }
    // setSelectedOption(defaultValue && defaultValue[item.uuid] && defaultValue[item.uuid][0])
  }, [defaultValue])

  useEffect(() => {
    // if (selectedOption && item.child) {

    //   let dependency = []
    //   for (let i = 0; i < item.child[0].select.length; i++) {
    //     dependency.push(item.child[0].select[i])
    //   }
    //   let check = dependency.some(e => e == selectedOption)
    //   if (check) {
    //     setShowChild(true)
    //   }
    // }

    if (selectedOption && item.child != null && item.child.length > 0) {
      const showChildQues = []
      for (var ch of item.child) {
        onChildInput('', ch.uuid)
        if (ch.select.length > 0 && ch.select.includes(selectedOption)) {
          showChildQues.push(ch.uuid)
        }
      }
      setShowChild(showChildQues)
    }
  }, [selectedOption])

  const handleChange = val => {
    setSelectedOption(val)
    onSelect(val)
  }

  return (
    <>
      <div className="selectable-tags-div">
        {item.choice.map((tag, index) => (
          <CheckableAntTag //Reusale component to select tags on click
            tag={tag}
            key={index}
            setValue={val => handleChange(val)}
            value={selectedOption}
          />
        ))}
      </div>
      {showChild.length > 0 &&
        item.child.map(c => {
          return (
            showChild.includes(c.uuid) && (
              <div className="child-div" key={c.uuid}>
                <ChildComponent
                  child={c}
                  onChildSelect={(v, quuid) => {
                    onChildInput(v, quuid)
                  }}
                  defaultValues={defaultValue}
                  formValues={formValues}
                  isError={isError}
                />
              </div>
            )
          )
        })}
    </>
  )
}

SingleTabSelect.propTypes = {
  item: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChildInput: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
  isError: PropTypes.any.isRequired
}
