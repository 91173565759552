import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import { MaskedInputComponent } from '../../components/Inputs/maskedInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import validator from 'validator'
import { Spin } from 'antd'
// import { Notification } from 'element-react'
import { gql } from 'apollo-boost-upload'
import apollo from '../../api/client'
import { useHistory, useLocation } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'
import './index.less'
import { useDispatch, useSelector } from 'react-redux'
import { IS_PHONE_VALID_AND_VERIFIED } from '../../store/actions'
import Swal from 'sweetalert2'

const SendOtp = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const from = history.location.state?.from
  let phone = useSelector(state => state?.phone)
  let MOBILE_BREAKPOINT = 700
  // eslint-disable-next-line
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  const [value, setValue] = useState('351')
  const [loading, setLoading] = useState(false)
  const isPhoneValidAndVerified = useSelector(state => state?.isPhoneValidAndVerified)

  useEffect(() => {
    if (from == '/verify-otp') {
      phone = location?.state?.state
    }
  }, [from])
  useEffect(() => {
    if (isPhoneValidAndVerified) return history.push('/seguros')
  }, [isPhoneValidAndVerified])

  useEffect(() => {
    if (phone) {
      let trimmedPhone = phone?.trim()
      if (trimmedPhone?.startsWith('+')) {
        let formattedPhone = trimmedPhone?.substring(1)?.split(' ')
        setValue(formattedPhone[0])
        formik.setFieldValue('phone', formattedPhone?.slice(1)?.join(' '))
      } else {
        let formattedPhone = trimmedPhone?.split(' ')
        setValue(formattedPhone[0])
        formik.setFieldValue('phone', formattedPhone?.slice(1)?.join(' '))
      }
    } else {
      formik.setFieldValue('phone', null)
    }
  }, [phone])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: null
    },
    onSubmit: (values: any) => {
      setLoading(true)
      setTimeout(() => {
        return apollo
          .query({
            query: gql`
              query SendOtp($phone: String!, $type: OtpType) {
                sendOtp(phone: $phone, type: $type) {
                  code
                  message
                }
              }
            `,
            variables: {
              phone: `+${value} ` + values?.phone,
              type: 'OTP'
            },
            fetchPolicy: 'no-cache'
          })
          .then(res => {
            let code = res?.data?.sendOtp?.code
            // let message = res?.data?.sendOtp?.message
            if (code == '200') {
              return history.push({
                pathname: '/verify-otp',
                state: { phone: `+${value} ` + values?.phone }
              })
            } else if (code == '202') {
              // if (!isMobile) {
              //   Notification.success({
              //     title: '',
              //     message: message,
              //     duration: 0
              //   })
              // }
              dispatch({
                type: IS_PHONE_VALID_AND_VERIFIED,
                payload: true
              })
              Swal.fire({
                confirmButtonText: 'Entrar na minha Wallet',
                confirmButtonColor: '#895d85',
                titleText: 'O número de telemóvel que inseriste já está validado'
              }).then(() => {
                return history.push('/seguros')
              })
            } else {
              // if (!isMobile) {
              //   Notification.success({
              //     title: '',
              //     message: message,
              //     duration: 0
              //   })
              // }
              return history.push({
                pathname: '/verify-otp',
                state: { phone: `+${value} ` + values?.phone }
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }, 2000)
    },
    validationSchema: Yup.object().shape({
      phone: Yup.mixed()
        .test('phone', 'Preenche este campo', (value: any, testContext: any) => {
          if (!value) {
            return testContext.createError({ message: 'Preenche este campo' })
          } else if (value) {
            let newStr = value?.replace(/\s/g, '')
            return validator.isMobilePhone(newStr, 'pt-PT')
          } else return false
        })
        .required('Preenche este campo')
        .typeError('Preenche este campo')
    })
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="verify-mobile">
        <div className="wrapper-div">
          <h1>
            Para terminar a criação da tua Wallet MUDEY, necessitamos apenas que valides o nº de
            telemóvel.
          </h1>
          <ol className="ordered-list">
            É muito simples:
            <br />
            <li>Introduzes o nº para onde devemos enviar o código de validação</li>
            <li>E depois introduzes o código que receberes por SMS.</li>
          </ol>
          <p className="phone-text">Qual o teu nº de telemóvel?</p>
          <div className="phone-input-wrapper">
            <PhoneInput
              country="pt"
              value={value}
              onChange={v => {
                setValue(v)
              }}
              readOnly
              inputProps={{
                readOnly: true
              }}
            />

            <MaskedInputComponent
              style={{ width: '100%' }}
              formik={formik}
              mask="111 111 111"
              name="phone"
              type="tel"
              placeholder="xxx-xxx-xxx"
            />
            
          </div>
          <div className="phone-input-wrapper">
            <button className="otp-button" type="submit">
              {loading ? <Spin size="small" /> : 'Enviar código'}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default SendOtp
