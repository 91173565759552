// @flow
import React from 'react'
import './styles.less'
import PartnersHeader from '../../../../../src/components/utils/partners-header'

export const Header = ({ title = 'Os Meus Convites' }: any) => {
  return (
    <div className="referral_header header-partner-wrapper">
      <h1>{title}</h1>
      <PartnersHeader />
      {/* <span>Subtitle text will be here later</span> */}
    </div>
  )
}
