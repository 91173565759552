import 'element-theme-default'

import FormBuilder from '../ui/FormBuilder'
import Layout from '../layout'
import { Notification } from 'element-react'
import Ql from '../../api/ql'
import React from 'react'
import { verifyErrorCode } from '../ui/LocalizeFirebaseErrorMessages'

class UserSettings extends React.Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      userData: null,
      dataObtained: false
    }

    this.getUserData = this.getUserData.bind(this)
    this.saveUserData = this.saveUserData.bind(this)

    this.reference = null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getUserData()
  }

  getUserData() {
    this.setState({ loading: true })
    new Ql('userData')
      .get()
      .then(data => {
        this.setState({
          dataObtained: true,
          userData: data.data.userGet
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <Layout loading={this.state.loading}>
        <div>
          {this.state.dataObtained ? (
            <div>
              <FormBuilder
                data={this.state.userData}
                index="1"
                onBlockStateChange={v => {
                  this.setState(p => ({
                    blocked: { ...p.blocked, job: v === 'true' }
                  }))
                }}
                ref={ref => {
                  if (ref) this.reference = ref.formData
                }}
                type="UserSettings"
              />
              <div className="row btn-wrapper">
                <div
                  className="button-solid savebtn"
                  onClick={() => {
                    this.saveUserData()
                  }}
                  style={{ width: '160px' }}>
                  Guardar
                </div>
              </div>
            </div>
          ) : (
            <p>loading...</p>
          )}
        </div>
      </Layout>
    )
  }
  saveUserData() {
    let ref = this.reference
    let info = {}

    //eslint-disable-next-line unused-imports/no-unused-vars
    for (let node of ref.entries()) {
      info[node[0]] = node[1]
    }

    if (info) {
      // Delete consents object if exists
      if (info.consents) {
        delete info.consents
      }

      this.setState({ loading: true })
      new Ql('userData')
        .set(info)
        .then(() => {
          Notification.success({
            title: 'Sucesso!',
            message: 'Guardado'
          })
          setTimeout(() => {
            window.location.assign('/dados/perfil')
          }, 1700)
        })
        .catch(e => {
          const errorMessage = verifyErrorCode(e.message)
          Notification.error({
            title: 'Whoops... deu erro!',
            message: errorMessage
          })
        })
        .finally(() => this.setState({ loading: false }))
    }
  }
}

export default UserSettings
