// @flow

import React from 'react'
import { Spin } from 'antd'

type PropsType = {
  children: any,
  loading: boolean
}
export default function Layout({ children, loading }: PropsType) {
  return loading ? (
    <Spin size="large" style={{ position: 'fixed', top: '40%' }}>
      {children}
    </Spin>
  ) : (
    children
  )
}
