import ApolloClient from 'apollo-boost-upload'
import firebase from 'firebase/app'
import Cookies from 'js-cookie'

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL || '',
  credentials: 'include',
  onError: ({ graphQLErrors, operation, forward }: any) => {
    if (graphQLErrors) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let err of graphQLErrors) {
        // handle errors differently based on its error code
        switch (err.extensions.code) {
          case 'REVOKED_FIREBASE_USER_TOKEN':
          case 'ERROR_AUTHENTICATION':
          case 'MISSING_COOKIE':
            firebase.auth().signOut()
            // Now, pass the modified operation to the next link
            // in the chain. This effectively intercepts the old
            // failed request, and retries it with a new token
            return forward(operation)

          // handle other errors
          default:
            console.log(err)
            return forward(operation)
        }
      }
    }
  },
  request: (req: any) => {
    // const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'
    const token = Cookies.get('MUDEY_wallet_token')
    req.setContext({
      headers: {
        MUDEY_AUTH_TOKEN: token
      }
    })
  }
})

export default client
