import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ChildComponent from './childComponent'
import CheckableMultiAntTag from '../../../../../components/Tags/MultiSelectTag'

// This is the topmost <select component which is used only once in this section
export default function DualTabSelect({
  item,
  onSelect,
  onChildInput,
  defaultValue,
  isError,
  formValues
}) {
  const [selectedOption, setSelectedOption] = useState([])
  const [showChild, setShowChild] = useState([])

  useEffect(() => {
    setSelectedOption(defaultValue && defaultValue[item.uuid] ? defaultValue[item.uuid] : [])
  }, [defaultValue])

  useEffect(() => {
    const showChildQues = []
    if (selectedOption.length > 0 && item.child != null && item.child.length > 0) {
      for (var ch of item.child) {
        onChildInput('', ch.uuid)
        var filteredArray = ch.select.filter(function(n) {
          return selectedOption.indexOf(n) !== -1
        })

        if (filteredArray.length > 0) {
          showChildQues.push(ch.uuid)
        }
      }
      setShowChild(showChildQues)
    }
  }, [selectedOption])

  const handleChange = (tag, checked) => {
    const preViousData = selectedOption
    let newData = []

    if (!checked) {
      if (preViousData.length < 2) {
        newData = [...preViousData, tag]
      } else {
        preViousData[0] = tag
        newData = preViousData
      }
    } else {
      newData = preViousData.filter(t => t !== tag)
    }

    onSelect(newData)
    setSelectedOption(newData)
  }

  return (
    <>
      <div className="selectable-tags-div">
        {item.choice.map((tag, index) => (
          <CheckableMultiAntTag //Reusale component to select tags on click
            tag={tag}
            key={index}
            setValue={(tag, checked) => handleChange(tag, checked)}
            value={selectedOption}
            isDisabled={false}
          />
        ))}
      </div>
      {showChild.length > 0 &&
        item.child.map(c => {
          return (
            showChild.includes(c.uuid) && (
              <div className="child-div" key={c.uuid}>
                <ChildComponent
                  child={c}
                  onChildSelect={(v, quuid) => {
                    onChildInput(v, quuid)
                  }}
                  defaultValues={defaultValue}
                  formValues={formValues}
                  isError={isError}
                />
              </div>
            )
          )
        })}
    </>
  )
}

DualTabSelect.propTypes = {
  item: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChildInput: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
  isError: PropTypes.any.isRequired
}
