import React, { useEffect } from 'react'
import MaskedInput from 'antd-mask-input'
import { InputWrapper } from './input-wrapper'
import './inputs.less'

export const MaskedInputComponent = (props: any) => {
  const { formik, name, disabled, className, mask, placeholder,style } = props
  const formValue=_.get(formik.values,name)
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if the pressed key is the delete key (keyCode 46 or key "Delete")
      if (e.keyCode === 46 || e.key === "Delete") {
        e.preventDefault(); // Prevent the default delete key behavior
        // You can also add a message or perform some other action here
        console.log("Delete key is disabled.");
      }
    };

    // Add the event listener to the component's element
    const componentElement = document.getElementById(name); // Replace with your component's HTML element ID
    componentElement.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      componentElement.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <InputWrapper {...props}>

        <MaskedInput
        id={name}
          name={name}
          disabled={disabled}
          style={style}
          type="tel"
          onChange={(e: any) => {
            formik.setFieldValue(name, e.target.value)
          }}
          className={className ? className : 'input-box'}
          value={formValue}
          placeholder={placeholder}
          mask={mask}
          onCut={(e) => e.preventDefault()}
        />
    </InputWrapper>
  )
}