import AddExistingPolicy from '../components/policy/AddExistingPolicy'
import AddViaEmail from '../components/policy/Email'
// import Advisor from '../components/advisor/advisor'
// import AdvisorTypeform from '../components/advisor/advisor-typeform/advisor-typeform'
import ArticleDetails from '../components/hc/Details'
import Auth from '../components/auth/Auth'
import HelpCenterList from '../components/hc/HelpCenter'
import Home from '../components/Home'
import NotificationsSettings from '../components/settings/Notifications'
import PolicyApplications from '../components/policy/Applications'
import PolicyDetails from '../components/policy/Details'
import PolicyList from '../components/policy/List'
import Restore from '../components/auth/Restore'
import Register from '../components/auth/Register'
import UserSettings from '../components/settings/User'
import UserView from '../components/settings/UserView'
import selectProduct from '../components/selectProduct/selectProduct'
import Dashboard from '../containers/dashboard'
import Referrals from '../containers/referrals'
import { Suggestions } from '../containers/advisor/pages/suggestions'
import UserForms from '../containers/advisor/pages/userForms'
import { Advisor } from '../containers/advisor'
import SendOtp from '../containers/validate/sendOTP'
import VerifyOTP from '../containers/validate/verifyOTP'

const Routes = {
  home: {
    path: '/',
    component: Home
  },
  validate: {
    path: '/verify-number',
    component: SendOtp
  },
  verify: {
    path: '/verify-otp',
    component: VerifyOTP
  },
  dashboard: {
    path: '/dashboard',
    component: Dashboard
  },
  auth: {
    path: '/login',
    component: Auth
  },
  register: {
    path: '/registar',
    component: Register
  },
  restore: {
    path: '/restore',
    component: Restore
  },
  advisor: {
    path: '/consultor',
    component: Advisor
    // questionnaire: {
    //   path: '/consultor/questionario',
    //   component: AdvisorTypeform
    // }
  },
  advisorSuggestion: {
    path: '/suggestion',
    component: Suggestions
  },
  advisorForms: {
    path: '/form/:surveyID?',
    component: UserForms
  },
  selectProduct: {
    path: '/comprar',
    component: selectProduct
  },
  settings: {
    notifications: {
      path: '/dados/notificacoes',
      component: NotificationsSettings
    },
    user: {
      path: '/dados/perfil/editar',
      component: UserSettings
    },
    userview: {
      path: '/dados/perfil',
      component: UserView
    }
  },
  policy: {
    addExisting: {
      path: '/seguros/adicionar',
      component: AddExistingPolicy
    },
    list: {
      path: '/seguros',
      component: PolicyList
    },
    applications: {
      path: '/seguros/aplicacoes',
      component: PolicyApplications
    },
    details: {
      path: '/seguros/:type/:id',
      component: PolicyDetails
    },
    email: {
      path: '/seguros/email',
      component: AddViaEmail
    }
  },
  helpcenter: {
    list: {
      path: '/hc',
      component: HelpCenterList
    },
    details: {
      path: '/hc/:id',
      component: ArticleDetails
    }
  },
  referrals: {
    path: '/convites',
    component: Referrals
  }
}

export default Routes
