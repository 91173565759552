// @flow

import type { Node } from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withRouter } from 'react-router'

type PropsType = {
  children: Node,
  location: any
}

const ScrollToTop = (props: PropsType) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location])

  return props.children
}

ScrollToTop.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired
}

export default withRouter(ScrollToTop)
