import React,{useEffect,useRef,useState} from 'react'
import downArrow from "../../images/downArrow.svg";
import upArrow from "../../images/upArrow.svg";
import mudeyGreyIcon from "../../images/mudeyGreyNew.png";
// import { useTranslation } from "react-i18next";
import './index.less'


const NewFooter = () => {
  const ref = useRef();
  const [show, setShow] = useState(false);
  // const { t, i18n } = useTranslation();

  const footerText =
    "A MUDEY é uma mediadora de seguros autorizada pela ASF com o número 420558967, no ramo vida e não-vida.";

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        ref &&
          ref.current &&
          ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 300);
    }
    if (show) {
      ref &&
        ref.current &&
        ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [show]);
  return (
    <footer className={`footer-new ${show ? "footer-increased" : ""}`}>
    <div className="container container-footer">
      <div className="footer-wrapper">
        <div className="footer-wrapper-iob">
          <img
            alt="Mudey Logo"
            className="footer-logo"
            src={mudeyGreyIcon}
            title="Mudey Logo"
          />
          <span>
            &copy; {new Date().getFullYear()} MUDEY&nbsp;
            Todos os direitos reservados.
          </span>
          {/* <img alt="Flag Icon" className="footer-flag" src={portugalFlagIcon} /> */}
          {/* <span>{t('footer.created')}</span> */}
        </div>

        <div className="footer-wrapper-col">
          <p style={{ textAlign: "center" }}> {footerText}</p>
        </div>
        <p className="main-text" onClick={() => setShow(!show)}>
          Informação Legal
          {!show ? (
            <img src={downArrow} className="arrow-img-down" />
          ) : (
            <img src={upArrow} className="arrow-img-up" />
          )}
        </p>
        {show && (
          <>
            <div className="hyperlinks-wrapper">
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/politica-de-privacidade/"
                >
                  Politica de Privacidade
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/politica-cookies/"
                >
                  Política de Cookies
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/termos-de-utilizacao/"
                >
                  Termos de Uso
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/politica-tratamento/"
                >
                  Política de Tratamento
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/aviso-privacidade/"
                >
                  Aviso de Privacidade
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/politica-pagamentos/"
                >
                  Regulamento sobre Pagamentos
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/gestao-de-reclamacoes/"
                >
                  Gestão de Reclamações
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mudey.pt/wp-content/uploads/2022/11/Ficha-Informativa-V10.pdf"
                  // ref={ref}
                >
                  Deveres Especiais de Informação
                </a>
              </span>
            </div>
            <div className="hyperlinks-end-div" ref={ref} />
          </>
        )}
      </div>
    </div>
  </footer>
  )
}

export default NewFooter