import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Ql from '../../api/ql'

import { Section } from './components/section/section'
import { Progress } from './components/progress'
import { Header } from './components/header'
import { PolicyList, PolicyItem } from './components/policies'
import { AdvicesList, AdvicesItem } from './components/advices'
import { ReferralCard } from './components/referral_card'

import { getProgressPercent, defineColor } from './utils'

import './styles.less'

const MAX_POLICIES_IN_LIST = 3
const MAX_ADVICES_IN_LIST = 6

const commonPolicyHint = 'Adiciona os teus seguros'
const expiredSoonPolicyHint = 'Tens seguros a renovar em breve'
const expiredPolicyHint = 'Tens seguros expirados. Actualiza a tua Wallet!'

let resizeTimer = null
let MOBILE_BREAKPOINT = 1280

const Dashboard = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const myRef = useRef(null)

  const [progress, setProgress] = useState(0)
  const [color, setColor] = useState('#881414')
  const [bgColor, setBgColor] = useState('rgba(136, 20, 20, 0.3)')

  const [loadingPolicies, setLoadingPolicies] = useState(false)
  const [loadingAdvices, setLoadingAdvices] = useState(false)

  const [policies, setPolicies] = useState(null)
  const [advices, setAdvices] = useState(null)

  const [policiesHint, setPoliciesHint] = useState(commonPolicyHint)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)
  useEffect(() => {
  }, [isMobile])

  const handleResize = () => {
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(() => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)
    }, 500)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const userName = useSelector(({ user_data }) => {
    if (user_data) {
      const { firstName } = user_data
      return `${firstName || ''}`
    }
    return ''
  })
  const userUuid = useSelector(({ user_data }) => (user_data ? user_data.user_uuid : null))
  const userEmail = useSelector(({ user_data }) => (user_data ? user_data.email : null))

  useEffect(() => {
    const getProfileData = async () => {
      const result = await new Ql('userData').get()
      if (result) {
        const {
          data: { userGet }
        } = result
        if (userGet) {
          localStorage.setItem('userId_wallet', userGet.uuid)
          dispatch({
            type: 'SET_USER_DATA',
            payload: {
              firstName: userGet.firstName,
              lastName: userGet.lastName,
              uuid: userGet.uuid,
              email: userGet.email,
              phone:userGet.phone
            }
          })
          const count = getProgressPercent(userGet)
          setProgress(count)
          const { color, background } = defineColor(count)
          setColor(color)
          setBgColor(background)
        }
      }
    }

    const getPolicies = async () => {
      setLoadingPolicies(true)
      const policies = await new Ql('userPolicies').get()
      setLoadingPolicies(false)
      if (policies) {
        const {
          data: { userPoliciesGet }
        } = policies
        if (userPoliciesGet) {
          const retreivePolicies = (data: any) => {
            delete data.__typename
            const allPolicies = {
              mudey: userPoliciesGet.policiesMudey,
              external: userPoliciesGet.policiesExternal,
              imported: userPoliciesGet.policiesImported
            }
            const allPoliciesArray = Object.keys(allPolicies).reduce(
              (accumulator: any, type: any) => {
                delete allPolicies[type].__typename
                const policies = Object.keys(allPolicies[type]).reduce((acc: any, key: any) => {
                  const data = allPolicies[type]

                  let sort_index = 1
                  if (type === 'external') {
                    sort_index = 2
                  }
                  if (type === 'imported') {
                    sort_index = 3
                  }
                  if (data[key]) {
                    const items = data[key].map((item: any) => {
                      return {
                        id: item.id,
                        status: item.status,
                        renewalDate: item.renewalDate,
                        premium: item.premium,
                        premiumType: item.premiumType,
                        insuranceType: item.insuranceType,
                        insuranceBrand: item.insuranceBrand,
                        policy_type: type,
                        packageName:item.packageName,
                        type: key.toLowerCase(),
                        sort_index
                      }
                    })
                    acc = [...acc, ...items]
                  }

                  return acc
                }, [])
                accumulator = [...accumulator, ...policies]
                return accumulator
              },
              []
            )
            // let allPoliciesArrayFiltered = allPoliciesArray.filter(item => item.status != 'ENDED')

            const sorted = allPoliciesArray
              .filter((item: any) => item.renewalDate && item.status != 'ENDED')
              // .filter((item:any)=>item.insuranceType=="TRAVEL")
              .reduce(
                (acc: any, item: any) => {
                  const today = moment()
                  const aDate = moment(item.renewalDate)
                  const difference = aDate.diff(today, 'days')

                  if (difference < 30 && difference >= 0) {
                    acc.expiredSoon = [...acc.expiredSoon, item]
                  }
                  if (difference < 0) {
                    acc.expired = [...acc.expired, item]
                  }
                  if (
                    (item.sort_index === 1 && difference >= 30) ||
                    (item.sort_index === 1 && difference < 0)
                  ) {
                    acc.mudey = [...acc.mudey, item]
                  }
                  if (
                    (item.sort_index === 2 && difference >= 30) ||
                    (item.sort_index === 2 && difference < 0)
                  ) {
                    acc.external = [...acc.external, item]
                  }
                  if (
                    (item.sort_index === 3 && difference >= 30) ||
                    (item.sort_index === 3 && difference < 0)
                  ) {
                    acc.imported = [...acc.imported, item]
                  }

                  return acc
                },
                {
                  expired: [],
                  expiredSoon: [],
                  mudey: [],
                  external: [],
                  imported: []
                }
              )
            return [
              ...sorted['expiredSoon'],
              ...sorted['mudey'],
              ...sorted['external'],
              ...sorted['imported'],
              ...sorted['expired']
            ].slice(0, MAX_POLICIES_IN_LIST)
          }

          const dataToSet = [...new Set(retreivePolicies(userPoliciesGet))]

          dataToSet.forEach((policy: any) => {
            const today = moment()
            const aDate = moment(policy.renewalDate)
            const difference = aDate.diff(today, 'days')

            if (difference < 0) {
              setPoliciesHint(expiredPolicyHint)
            }

            if (difference < 30 && difference >= 0 && policiesHint !== expiredPolicyHint) {
              setPoliciesHint(expiredSoonPolicyHint)
            }
          })

          setPolicies(dataToSet && dataToSet.length ? dataToSet : null)
        }
      }
    }

    const getAdvices = async () => {
      setLoadingAdvices(true)
      const advices = await new Ql('advisorResults').get()
      setLoadingAdvices(false)

      if (advices) {
        const {
          data: { getAdvisorSuggestions }
        } = advices
        if (getAdvisorSuggestions) {
          const getNotEmptyKeys = (entity: any, type) => {
            return Object.keys(entity).reduce((acc: any, key: string) => {
              if (entity[key] === 1) {
                let sort_index = 1
                if (type === 'recommended') {
                  sort_index = 2
                }
                if (type === 'consider') {
                  sort_index = 3
                }
                acc = [
                  ...acc,
                  {
                    type: key.toLowerCase(),
                    advice_type: type,
                    userid: userUuid,
                    email: userEmail,
                    firstname: userName,
                    sort_index
                  }
                ]
              }
              return acc
            }, [])
          }
          const suggestions = getAdvisorSuggestions
          const data = Object.keys(getAdvisorSuggestions).reduce((acc: any, key) => {
            if (key === 'recommended' || key === 'mandatory' || key === 'consider') {
              const result = getNotEmptyKeys(suggestions[key], key)
              acc = [...acc, ...result]
            }
            return acc
          }, [])

          const advices = data
            .sort((a: any, b: any) => a.sort_index - b.sort_index)
            .slice(0, MAX_ADVICES_IN_LIST)
          setAdvices(advices && advices.length ? advices : null)
        }
      }
    }

    getProfileData()
    getPolicies()
    getAdvices()
  }, [])

  return (
    <div>
      <Header userName={userName} userEmail={userEmail} />
      <div className="dashboard_grid">
        <Section
          title="Os teus Dados"
          headerText="Sobre ti"
          hintText={progress < 100 ? 'Completa os teus dados' : 'Ver o teu perfil'}
          footerText="Completa os teus dados"
          footerOnClick={() => history.push('/dados/perfil/editar')}>
          <Progress
            progress={progress}
            size={300}
            strokeWidth={20}
            circleOneStroke={bgColor}
            circleTwoStroke={color}
          />
          <div className="dashboard_progress_description">
            <span>Preenche os teus dados para que a Wallet MUDEY funcione em pleno!.</span>
          </div>
        </Section>
        <Section
          isMobile={isMobile}
          title="Os teus Seguros"
          headerText="A tua carteira de seguros"
          hintText={policiesHint}
          footerText="Ver mais"
          footerOnClick={() => history.push('/seguros')}>
          <div style={{ minHeight: 370 }}>
            <PolicyList isMobile={isMobile} loading={loadingPolicies}>
              {policies ? (
                policies.map(
                  ({
                    id,
                    renewalDate,
                    premium,
                    premiumType,
                    insuranceType,
                    insuranceBrand,
                    status,
                    packageName
                  }: any) => {
                    return (
                      <PolicyItem
                        key={id}
                        id={id}
                        isMobile={isMobile}
                        renewalDate={renewalDate}
                        premium={premium}
                        premiumType={premiumType}
                        insuranceType={insuranceType}
                        insuranceBrand={insuranceBrand}
                        status={status}
                        packageName={packageName}
                      />
                    )
                  }
                )
              ) : (
                <div className="no-results">Ainda não adicionaste os teus seguros</div>
              )}
            </PolicyList>
          </div>
        </Section>
      </div>
      <div
        ref={myRef}
        className="dashboard_grid bottom-grid"
        style={{ gridTemplateColumns: isMobile ? '1fr 376px' : '1fr 410px' }}>
        <Section
          headerText="Estes são os seguros que deves ter! Sabe mais em “O Meu Consultor”"
          title="O teu Consultor"
          footerText="Saber Mais"
          footerOnClick={() => history.push('/consultor')}>
          <AdvicesList isMobile={isMobile} loading={loadingAdvices}>
            {advices ? (
              advices.map(({ type, userid, firstname, email, advice_type }: any, i: number) => {
                return (
                  <AdvicesItem
                    key={i}
                    isMobile={isMobile}
                    refToScroll={myRef}
                    type={type}
                    userid={userid}
                    firstname={firstname}
                    email={email}
                    advice_type={advice_type}
                  />
                )
              })
            ) : (
              <div style={{ maxWidth: 350 }} className="no-results">
                Ainda não usaste o teu consultor de seguros
              </div>
            )}
          </AdvicesList>
        </Section>

        <ReferralCard goTo={() => history.push('/convites')} />
      </div>
    </div>
  )
}

export default Dashboard
