import * as Queries from './queries'

export default class ql {
  constructor(objType) {
    this.queryType = objType
  }

  delete(input) {
    return new Queries[this.queryType]().d(input)
  }
  get(type = null, input = null) {
    return new Queries[this.queryType](type).g(input)
  }
  getFilters(type, input) {
    return new Queries[this.queryType](type).gf(input)
  }

  pget(input) {
    return new Queries[this.queryType](input).g()
  }

  set(input, type = null) {
    return new Queries[this.queryType](type).s(input)
  }

  update(input, type = null) {
    return new Queries[this.queryType](type).u(input, type)
  }
  getCar(input, type) {
    return new Queries[this.queryType](type).c(input, type)
  }
}
