import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

export default class PriceAdvisor {
  constructor(url, userid, firstname, email) {
    this.type = url

    const Typemodal = value => (
      <ReactTypeformEmbed
        className="TypeformEmbed"
        style={{ position: 'relative', height: '100%', minWidth: '100%', maxWidth: '100%' }}
        url={`${value.link}?firstname=${firstname}&email=${email}&userid=${userid}`}
      />
    )

    const ExternalLink = value =>
      (window.location.href = value.link)

    const lifecreditForm = <Typemodal link="https://mudey.typeform.com/to/QnavdhON" />
    const carForm = <Typemodal link="https://mudey.typeform.com/to/SzVFMr" />
    const bikeForm = <Typemodal link="https://mudey.typeform.com/to/G1Xl6w" />
    const civilForm = <Typemodal link="https://mudey.typeform.com/to/iF9E31" />
    const petsForm = <Typemodal link="https://mudey.typeform.com/to/aCwt7ovd" />
    const houseForm = <Typemodal link="https://mudey.typeform.com/to/azIVaN" />
    const lifeForm = <Typemodal link="https://mudey.typeform.com/to/mm0uJ9" />
    const healthForm = <Typemodal link="https://mudey.typeform.com/to/SNonxY" />
    const extraPetForm = <Typemodal link="https://mudey.typeform.com/to/qTqAQ8" />
    const equipmentForm = <Typemodal link="https://mudey.typeform.com/to/K0Z8jT" />
    const housekeeperForm = <Typemodal link="https://mudey.typeform.com/to/HxrVCi" />
    const workEmployeedForm = <Typemodal link="https://mudey.typeform.com/to/vWfMj4" />
    const workEmployeeForm = <Typemodal link="https://mudey.typeform.com/to/IYCn3r" />
    const contentsForm = <Typemodal link="https://mudey.typeform.com/to/E6MlgB" />
    const dentalForm = <Typemodal link="https://mudey.typeform.com/to/fqCm095t" />
    const incomeForm = <Typemodal link="https://mudey.typeform.com/to/Y1U47G3V" />
    const boatForm = <Typemodal link="https://mudey.typeform.com/to/U7As94XF" />
    const hunterForm = <Typemodal link="https://mudey.typeform.com/to/WrTS64hj" />
    const extendedwarrantyForm = <Typemodal link="https://mudey.typeform.com/to/TX1uMLI4" />
    const whitegoodsForm = <Typemodal link="https://mudey.typeform.com/to/ssZfRsRt" />
    const gadgetForm = <Typemodal link="https://mudey.typeform.com/to/xMTVgOJv" />
    const sportsForm = <Typemodal link="https://mudey.typeform.com/to/fBF02vyP" />
    const investmentForm = <Typemodal link="https://mudey.typeform.com/to/AVfDzymr" />
    const thirdPartyProfessionalForm = <Typemodal link="https://mudey.typeform.com/to/iF9E31" />
    const thirdPartyPetForm = <Typemodal link="https://mudey.typeform.com/to/dsB4en" />
    
    //special case for subtype family for civil type
    const civilFamForm = <ExternalLink link={`${process.env.REACT_APP_HP_URL}/produtos/seguro-familia/simulador/`} />
    const pprForm = <ExternalLink link={`${process.env.REACT_APP_MD_URL}seguro-ppr/simulacao`} />

    this.assoc = {
      car: carForm,
      bike: bikeForm,
      buildings: houseForm,
      mortgage: lifecreditForm,
      lifecredit: lifecreditForm,
      workaccidentselfemployeed: workEmployeedForm,
      workaccidentemployee: workEmployeeForm,
      workaccident: workEmployeedForm,
      pet: petsForm,
      civilresponsability: civilForm,
      thirdparty: civilForm,
      contents: contentsForm,
      content: contentsForm,
      life: lifeForm,
      extrapet: extraPetForm,
      health: healthForm,
      equipment: equipmentForm,
      mobile: equipmentForm,
      housekeeper: housekeeperForm,
      hunter: hunterForm,
      income: incomeForm,
      boat: boatForm,
      dental: dentalForm,
      extendedwarranty: extendedwarrantyForm,
      whitegoods: whitegoodsForm,
      gadget: gadgetForm,
      family: civilFamForm,
      sports: sportsForm,
      investment: investmentForm,
      ppr: pprForm,
      thirdpartyprofessional: thirdPartyProfessionalForm,
      thirdpartypet: thirdPartyPetForm
    }
  }

  getForm() {
    console.log('get typeform for', this.type.toLowerCase())
    return this.assoc[this.type.toLowerCase()]
  }
}
