import React from 'react'
import PropTypes from 'prop-types'

export default function CheckableMultiAntTag({ tag, value, setValue, isDisabled }) {
  // const [isDisabled, setIsDisabled] = useState(isDisabled)

  const setValueMulti = tag => {
    if (isDisabled == false) {
      const checked = value !== null ? value.some(item => item === tag) : true
      setValue(tag, checked)
    }
  }

  return (
    <div
      style={{
        backgroundColor:
          value !== null && value.some(item => item === tag)
            ? '#AACECD'
            : isDisabled == true
            ? '#ddd'
            : 'white',
        borderColor: value !== null && value.some(item => item === tag) ? '#AACECD' : '#ddd'
      }}
      className="selectable-ant-tags"
      key={tag}
      //   checked={selectedTags.indexOf(tag) > -1 ? true : false}
      //   onChange={checked => handleChange1(tag, checked)}>
      checked={value !== null && value.some(item => item === tag)}
      //   onChange={() => setValue(tag, value)}
      onClick={() => setValueMulti(tag)}>
      {tag}
    </div>
  )
}

CheckableMultiAntTag.propTypes = {
  tag: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool.isRequired
}
