export default class InsuranceNames {
  constructor(name) {
    this.type = name

    this.assoc = {
      'comercial car': 'Carro 2 lugares',
      bicycle: 'Bicicleta',
      bike: 'Moto',
      buildings: 'Casa (Multiriscos)',
      car: 'Automóvel',
      casa: 'Casa',
      cat: 'Gato',
      child: 'Criança',
      civilresponsability: 'Responsabilidade Civil',
      comercial_car: 'Veículo Comercial',
      content: 'Recheio Casa',
      contents: 'Recheio Casa',
      criticalillness: 'Doenças Graves',
      dental: 'Seguro Dentário',
      dog: 'Cão',
      drone: 'Drone',
      equipment: 'Equipamento Informático',
      extendedwarranty: 'Extensão de Garantia',
      extrapet: 'Animais Domésticos',
      family: 'Família',
      farm: 'Quinta',
      fish_turtle: 'Tartaruga',
      health: 'Saúde',
      home: 'Casa',
      horse: 'Cavalo',
      hospitalization: 'Hospitalização',
      house: 'Casa',
      housekeeper: 'Empregada Doméstica',
      investment: 'Investimento',
      life: 'Vida Proteção',
      lifecredit: 'Vida Crédito',
      locallodging:'Alojamento Local',
      minivan: 'Carrinha 2 lugares',
      mortgage: 'Vida Crédito',
      moto: 'Moto',
      motobike: 'Moto',
      multirisks: 'Casa (Multirriscos)',
      other: 'Outro',
      personalaccident: 'Acidentes Pessoais',
      pet: 'Animais Domésticos',
      ppr: 'PPR',
      privatecondominimum: 'Condominio Privado',
      rabbit: 'Coelho',
      scooter: 'Trotinete',
      sports: 'Actividades Desportivas',
      suv: 'Monovolume',
      thirdparty: 'Responsabilidade Civil',
      thirdpartypet: 'Animal de estimação de terceiros',
      thirdpartypet: 'Responsabilidade Civil Animal',
      thirdpartyprofessional: 'Responsabilidade Civil Profissional',
      tractor: 'Trator',
      travel: 'Viagem',
      van: 'Carrinha',
      warranty: 'Garantia',
      workaccident: 'Acidentes Trabalho',
      workaccidentemployee: 'Acidentes Trabalho',
      workaccidentselfemployeed: 'Acidentes Trabalho',
    }
  }
  getName() {
    return this.assoc[this.type.toLowerCase()]
  }
}
