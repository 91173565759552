import 'element-theme-default'

import { Notification } from 'element-react'
import React from 'react'
import firebase from 'firebase/app'
import { Helmet } from 'react-helmet'
import Layout from '../layout'
import PropTypes from 'prop-types'
import { verifyErrorCode } from '../ui/LocalizeFirebaseErrorMessages'
import { Input } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { gql } from 'apollo-boost-upload'
import apollo from '../../api/client'
import validator from 'validator'
import debounce from 'lodash/debounce'

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registerLogin: null,
      password: null,
      registerPassword: null,
      fname: null,
      lname: null,
      password_confirmation: null,
      agree: false,
      isLogin: true,
      loading: false,
      agreeMarkting: false,
      isEmailValid: false,
      eyeOpen: true,
      eyeOpenSecond: true
    }

    this.register = this.register.bind(this)
    this.fbLogin = this.fbLogin.bind(this)
    this.gLogin = this.gLogin.bind(this)
    this.debouncedHandleChange = debounce(this.handleChange, 500)
  }

  goTo(path, value) {
    let state
    if (value) state = value
    this.props.history.push(path, state)
  }

  fbLogin() {
    var provider = new firebase.auth.FacebookAuthProvider()
    this.popupLogin(provider)
  }

  gLogin() {
    var provider = new firebase.auth.GoogleAuthProvider()
    this.popupLogin(provider)
  }

  popupLogin(provider) {
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(function(error) {
        const errorMessage = verifyErrorCode(error.code)
        Notification.error({
          title: 'Whoops... deu erro!',
          message: errorMessage
        })
      })
  }

  handleChange = value => {
    this.setState({ registerLogin: value })

    if (validator.isEmail(value)) {
      apollo
        .query({
          query: gql`
            query validateEmail($email: String!) {
              validateEmail(email: $email) {
                isValid
              }
            }
          `,
          variables: { email: value },
          fetchPolicy: 'no-cache'
        })
        .then(data => this.setState({ isEmailValid: data.data.validateEmail?.isValid }))
        .catch(error => console.log(error))
    }
  }

  handleInputChange = event => {
    this.setState({ isEmailValid: false })
    const newValue = event.target.value
    this.debouncedHandleChange(newValue)
  }

  register() {
    if (this.state.isEmailValid == false) {
      Notification.error({
        title: 'Whoops... deu erro!',
        message: 'O Email não é válido!'
      })
      return
    }
    let email = this.state.registerLogin,
      password = this.state.registerPassword,
      fname = this.state.fname,
      lname = this.state.lname,
      rpassword = this.state.password_confirmation,
      agree = this.state.agree,
      agree_politics = this.state.agree_politics
    let agreeMarkting = this.state.agreeMarkting

    localStorage.setItem(
      'AGREE_MRKETING',
      JSON.stringify({ email: email, agreeMarkting: agreeMarkting })
    )

    if (!email || !password || !fname || !lname || !rpassword) {
      Notification.error({
        title: 'Whoops... deu erro!',
        message: 'Todos os campos são obrigatórios.'
      })
      return false
    }
    if (password !== rpassword) {
      Notification.error({
        title: 'Whoops... deu erro!',
        message: 'As passwords têm de ser iguais!'
      })

      return false
    }

    if (!agree || !agree_politics) {
      Notification.error({
        title: 'Whoops... deu erro!',
        message: 'Tens de concordar com as Politicas de Dados para avançares...'
      })

      return false
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        let user = firebase.auth().currentUser
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'Register_User',
          New_user_created: 'wallet'
        })
        this.setState({ loading: true })
        user.updateProfile({
          displayName: fname + ' ' + lname
        })
      })
      .catch(function(error) {
        const errorMessage = verifyErrorCode(error.code)
        Notification.error({
          title: 'Whoops... deu erro!',
          message: errorMessage
        })
      })
    this.setState({ loading: false })
  }

  render() {
    var isDisabled = true
    const {
      loading,
      lname,
      fname,
      password_confirmation,
      registerPassword,
      registerLogin,
      agree_politics,
      agree
    } = this.state

    if (
      lname &&
      fname &&
      password_confirmation &&
      registerPassword &&
      registerLogin &&
      agree_politics &&
      agree !== null
    ) {
      isDisabled = false
    }
    return (
      <Layout loading={loading}>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{process.env.REACT_APP_NAME} | Registar</title>
          </Helmet>
          <h1>Criar a minha Wallet MUDEY</h1>
          <h2>
            Se subscreveste um Seguro com a MUDEY, e é a primeira vez que estás a aceder, deves
            definir uma senha de acesso.{' '}
            <u onClick={() => this.goTo('/login', 'recoverMode')} style={{ cursor: 'pointer' }}>
              Clica aqui para definires a senha{' '}
            </u>
          </h2>
          <h3>
            Se já tens a tua Wallet MUDEY criada,{' '}
            <u
              onClick={() => {
                this.goTo('/login')
              }} style={{ cursor: 'pointer' }}>
              clica aqui para acederes
            </u>
          </h3>
          {/* <span
            onClick={() => {
              this.goTo('/login')
            }}>
            Já criaste a tua wallet MUDEY?{' '}
            <strong>
              <u>Entrar</u>
            </strong>
          </span> */}
          <br />

          <div className="form">
            <label htmlFor="fname">Primeiro Nome</label>
            <input
              className="input-md"
              id="fname"
              onChange={v => {
                this.setState({ fname: v.target.value })
              }}
              type="text"
            />

            <label htmlFor="lname">Último Nome</label>
            <input
              className="input-md"
              id="lname"
              onChange={v => {
                this.setState({ lname: v.target.value })
              }}
              type="text"
            />

            <label htmlFor="email">Email</label>
            <input className="input-md" id="email" onChange={this.handleInputChange} type="text" />

            <label htmlFor="password">Password</label>
            <Input
              size="large"
              name="password"
              className="input-password"
              id="password"
              type={this.state.eyeOpen ? 'text' : 'password'}
              value={this.state.registerPassword}
              suffix={
                this.state.eyeOpen ? (
                  <EyeOutlined onClick={() => this.setState({ eyeOpen: false })} />
                ) : (
                  <EyeInvisibleOutlined onClick={() => this.setState({ eyeOpen: true })} />
                )
              }
              onChange={v => {
                this.setState({ registerPassword: v.target.value })
              }}
            />

            <label htmlFor="passwordc">Repetir Password</label>
            <Input
              size="large"
              name="password"
              className="input-password"
              type={this.state.eyeOpenSecond ? 'text' : 'password'}
              id="passwordc"
              suffix={
                this.state.eyeOpenSecond ? (
                  <EyeOutlined onClick={() => this.setState({ eyeOpenSecond: false })} />
                ) : (
                  <EyeInvisibleOutlined onClick={() => this.setState({ eyeOpenSecond: true })} />
                )
              }
              value={this.state.password_confirmation}
              onChange={v => {
                this.setState({ password_confirmation: v.target.value })
              }}
            />

            <input
              className="input-md"
              id="agree"
              onChange={v => {
                this.setState({ agree: v.target.checked })
              }}
              type="checkbox"
            />
            <label className="consents-wrapper" htmlFor="agree">
              <div className={this.state.agree ? 'ellipsis active' : 'ellipsis'} />
              <div>
                Li e concordo com as{' '}
                <a
                  href={process.env.REACT_APP_HP_URL + '/politica-de-privacidade/'}
                  rel="noopener noreferrer"
                  target="_blank">
                  Politicas de Dados
                </a>{' '}
                e de{' '}
                <a
                  href={process.env.REACT_APP_HP_URL + '/termos-de-utilizacao/'}
                  rel="noopener noreferrer"
                  target="_blank">
                  Condições de Uso
                </a>{' '}
                do Website da MUDEY
              </div>
            </label>
            <input
              className="input-md"
              id="agree_politics"
              onChange={v => {
                this.setState({ agree_politics: v.target.checked })
              }}
              type="checkbox"
            />
            <label className="consents-wrapper" htmlFor="agree_politics">
              <div className={this.state.agree_politics ? 'ellipsis active' : 'ellipsis'} />
              <div>
                Autorizo a MUDEY a tratar os meus dados para obter informações sobre seguros e
                gestão dos meus seguros
              </div>
            </label>

            {/* Newsletter */}
            <input
              className="input-md"
              id="agree_marketing"
              onChange={v => {
                this.setState({ agreeMarkting: v.target.checked })
              }}
              type="checkbox"
            />
            <label className="consents-wrapper" htmlFor="agree_marketing">
              <div className={this.state.agreeMarkting ? 'ellipsis active' : 'ellipsis'} />
              <div>Autorizo a MUDEY a enviar-me ações promocionais, passatempos e newsletters.</div>
            </label>

            <br />
            <br />

            <table className="btn-table">
              <tbody>
                <tr>
                  <td>
                    <button
                      className="button-solid"
                      disabled={isDisabled}
                      onClick={this.register}
                      style={{ background: isDisabled ? '#dedede' : null }}>
                      Registar
                    </button>
                  </td>
                  {/* <td className="login-or-td" style={{ width: '66px', textAlign: 'center' }}>
                    ou
                  </td>
                  <td>
                     <img
                      alt="facebook"
                      className="clickable"
                      onClick={this.fbLogin}
                      src={require('../../images/icons/f.svg')}
                    />
                    <img
                      alt="google"
                      //className="clickable"
                      onClick={this.gLogin}
                      src={require('../../images/icons/g.svg')}
                    />
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    )
  }
}

Register.propTypes = {
  history: PropTypes.any.isRequired
}

export default Register
