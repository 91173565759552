import React from 'react'
import './styles.less'

const defineColor = (type: number) => {
    if (type === 'danger') {
      return {
        color: '#881414',
        background: 'rgba(136, 20, 20, 0.3)'
      }
    } else if (type === 'warning') {
      return {
        color: '#f7cb8b',
        background: 'rgba(247, 203, 139, 0.8)'
      }
    } else if (type === 'success' || !type) {
        return {
            color: '#accecd',
            background: 'rgba(172, 206, 205, 0.8)'
        }      
    } else {
        return {
            color: '#895b85',
            background: 'rgba(137, 91, 133, 0.8)'
        }
    }
  }

export const CustomToast = ({ title, message = '', type = '' }: any) => {
    const cuttenMessage = message.length > 30 ? `${message.split(0, 30)}...` : message
    const color = defineColor(type)    
    return (
        <div className="custom_toast" style={{ 
            borderLeft: `7px solid ${color.color}`,
            backgroundColor: color.background
         }}>
            <div className="custom_toast-title">{title}</div>
            <div className="custom_toast-message">{cuttenMessage}</div>
        </div>
    )
}