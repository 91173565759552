import apollo from './client'
import { gql } from 'apollo-boost-upload'
import _ from 'lodash'

// eslint-disable-next-line unused-imports/no-unused-vars
let product = null

const defaultPolicyDataset = `
            claimsNumber
            claimsText
            docClause
            docDgCertificate
            docGeneralConditions
            docInsuranceCertificate
            docInsuranceCertificateEn
            docBaseCertificateEn
            docBaseCertificate
            docPreContractual
            docSignature
            externalId
            id
            packageName
            insuranceBrand
            insuranceSubtype
            insuranceType
            premium
            premiumType
            rating
            renewalDate
            renewUrl
            startDate
            type
            uuidUser
            status
        `

const specialPolicyDatasets = {
  bike:
    `
                insuranceSubtype
                plateNumber
            ` + defaultPolicyDataset,
  criticalillness:
    `
                holdersBirthdates
            ` + defaultPolicyDataset,
  hospitalization:
    `
                holdersBirthdates
            ` + defaultPolicyDataset,
  car:
    `
                insuranceSubtype
                plateNumber
                plate
                model
                brand
                registerDate
                additionalCover
                vat
                postalCode
                licenseDate
            ` + defaultPolicyDataset,
  home:
    `
                insuranceSubtype
                homeType
                homeUsage
                docSignature
                createdAt
            ` + defaultPolicyDataset,
  homecontents:
    `
                insuranceSubtype
                homeType
                homeUsage

            ` + defaultPolicyDataset,
  life:
    `
                insuranceSubtype
                disablement
                capital
                profession
                packageName
            ` + defaultPolicyDataset,
  mortgage:
    `
                insuranceSubtype
                capital
                disablement
                profession
                guarantors
                holdersSmoke
                holdersBirthdates
                ` + defaultPolicyDataset,
  pa:
    `
                capital
            ` + defaultPolicyDataset,
  health:
    `
                insuredPersons
                additionalCover
            ` + defaultPolicyDataset,
  pet:
    `
                petType
                petName
            ` + defaultPolicyDataset,
  travel:
    `
                insuranceSubtype
                endDate
                travelersNumber
                packageName
            ` + defaultPolicyDataset,
  ppr:
    `
                        insuranceSubtype
                        endDate
                    ` + defaultPolicyDataset,
  investment:
    `
                        insuranceSubtype
                        endDate
                    ` + defaultPolicyDataset,
  thirdparty:
    `
                insuranceSubtype
                profession
            ` + defaultPolicyDataset,
  dental:
    `
                insuredPersons
                personsBirthdate
                birthdate
            ` + defaultPolicyDataset,
  localLodging:
    `
              insuranceBrand
              localLodgingRegistryNumber
              localLodgingType
          ` + defaultPolicyDataset,
  extendedwarranty:
    `
    surname
    insuranceSubtype
    brand
    model
    purchaseDate
    pvp
  ` + defaultPolicyDataset,
  warranty:
    `
  surname
  insuranceSubtype
  brand
  model
  purchaseDate
  pvp
` + defaultPolicyDataset
}

export class policyGet {
  constructor({ type, id }) {
    let res = ''

    switch (type) {
      case 'bicycle':
      case 'boat':
      case 'drone':
      case 'child':
      case 'scooter':
      case 'housekeeper':
      case 'hunter':
      case 'income':
      case 'mobile':
      case 'family':
      case 'gadget':
      case 'whitegoods':

      case 'other':
        res = defaultPolicyDataset
        break
      case 'criticalillness':
        res = specialPolicyDatasets.criticalillness
        break
      case 'hospitalization':
        res = specialPolicyDatasets.hospitalization
        break
      case 'bike':
        res = specialPolicyDatasets.bike
        break
      case 'health':
        res = specialPolicyDatasets.health
        break
      case 'thirdparty':
        res = specialPolicyDatasets.thirdparty
        break
      case 'car':
        res = specialPolicyDatasets.car
        break
      case 'home':
        res = specialPolicyDatasets.home
        break
      case 'mortgage':
        res = specialPolicyDatasets.mortgage
        break
      case 'ppr': {
        product = 'ppr'
        res = specialPolicyDatasets.ppr
      }
      case 'investment': {
        product = 'investment'
        res = specialPolicyDatasets.investment
      }
      case 'life':
        res = specialPolicyDatasets.life
        break
      case 'workaccident':
      case 'personalaccident':
        res = specialPolicyDatasets.pa
        break
      case 'pet':
        res = specialPolicyDatasets.pet
        break
      case 'travel':
        res = specialPolicyDatasets.travel
        break
      case 'dental':
        res = specialPolicyDatasets.dental
        break
      case 'locallodging':
        res = specialPolicyDatasets.localLodging
        break
      case 'extendedwarranty':
        res = specialPolicyDatasets.extendedwarranty
        break
      case 'warranty':
        res = specialPolicyDatasets.warranty
        break
      default:
        break
    }

    let getType = `${type}PolicyGet`

    {
      type === 'workaccident' && (getType = 'workAccidentPolicyGet')
    }
    {
      type === 'criticalillness' && (getType = 'criticalIllnessPolicyGet')
    }
    {
      type === 'hospitalization' && (getType = 'hospitalizationPolicyGet')
    }
    {
      type === 'personalaccident' && (getType = 'personalAccidentPolicyGet')
    }
    {
      type === 'locallodging' && (getType = 'localLodgingPolicyGet')
    }

    if (type === 'criticalillness') {
      res = res + 'capital'
    }

    this.get = `
      {
        ${getType}(id: "${id}") {
          ${res.replace('insuranceSubtype', '')}
        }
      }
    `
  }

  g() {
    return apollo.query({
      query: gql`
        ${this.get}
      `,
      fetchPolicy: 'no-cache'
    })
  }
}

export class userPolicies {
  constructor(type = null) {
    this.requestsPayload = `
      bicyclePolicy {
        ${defaultPolicyDataset}
      }
      scooterPolicy {
        ${defaultPolicyDataset}
      }
      pprPolicy{
        ${defaultPolicyDataset}
      }
      investmentPolicy{
        ${defaultPolicyDataset}
      }
      whitegoodsPolicy {
        ${defaultPolicyDataset}
      }
      gadgetPolicy {
        ${defaultPolicyDataset}
      }
      familyPolicy {
        ${defaultPolicyDataset}
      }
      bikePolicy {
        ${specialPolicyDatasets.bike}
      }
      boatPolicy {
        ${defaultPolicyDataset}
      }
      carPolicy {
        ${specialPolicyDatasets.car}
      }
      childPolicy {
        ${defaultPolicyDataset}
      }
      dentalPolicy {
        ${specialPolicyDatasets.dental}
      }
      dronePolicy {
        ${defaultPolicyDataset}
      }
      healthPolicy {
        ${specialPolicyDatasets.health}
      }
      homePolicy {
        ${specialPolicyDatasets.home}
      }
      housekeeperPolicy {
        ${defaultPolicyDataset}
      }
      hunterPolicy {
        ${defaultPolicyDataset}
      }
      incomePolicy {
        ${defaultPolicyDataset}
      }
      lifePolicy {
        ${specialPolicyDatasets.life}
      }
      mortgagePolicy {
        ${specialPolicyDatasets.mortgage}
      }
      mobilePolicy {
        ${defaultPolicyDataset}
      }
      personalaccidentPolicy {
        ${specialPolicyDatasets.pa}
      }
      workaccidentPolicy {
        ${specialPolicyDatasets.pa}
      }
      petPolicy {
        ${specialPolicyDatasets.pet}
      }
      thirdpartyPolicy {
        ${defaultPolicyDataset}
      }
      criticalillnessPolicy {
        ${specialPolicyDatasets.criticalillness}
      }
      hospitalizationPolicy {
        ${specialPolicyDatasets.hospitalization}
      }
      travelPolicy {
        ${specialPolicyDatasets.travel}
      }
      otherPolicy {
        ${defaultPolicyDataset}
      }
      locallodgingPolicy {
        ${specialPolicyDatasets.localLodging}
      }
      extendedwarrantyPolicy {
        ${specialPolicyDatasets.extendedwarranty}
      }
      warrantyPolicy {
        ${specialPolicyDatasets.warranty}
      }
    `

    this.type = type

    if (type) {
      this.cType = type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()
    }

    this.get = `
      {
        userPoliciesGet {
          policiesMudey {
              ${this.requestsPayload}
          }
          policiesExternal {
              ${this.requestsPayload}
          }
          policiesImported {
              ${this.requestsPayload}
          }
        }
      }
    `

    this.getFilters = `
      {
        userPoliciesGet($filter: PolicyFilter) {
          policiesMudey {
              ${this.requestsPayload}
          }
          policiesExternal {
              ${this.requestsPayload}
          }
          policiesImported {
              ${this.requestsPayload}
          }
        }
      }
    `
  }

  d(input) {
    return apollo.mutate({
      mutation: gql`
        mutation policyDelete($id: ID!) {
          policyDelete(id: $id) {
            id
          }
        }
      `,
      variables: { id: input }
    })
  }

  g() {
    return apollo.query({
      query: gql`
        ${this.get}
      `,
      fetchPolicy: 'no-cache'
    })
  }
  gf() {
    let getFilters = `

        userPoliciesGet(filter:$filter) {
          policiesMudey {
              ${this.requestsPayload}
          }
          policiesExternal {
              ${this.requestsPayload}
          }
          policiesImported {
              ${this.requestsPayload}
          }
        }

    `

    let filterValue = localStorage.getItem('filterValue')
    if (!filterValue) {
      // alert(filterValue+'1')
      return apollo.query({
        query: gql`
  query userPoliciesGet($filter: PolicyFilter) {
    ${getFilters}
  }`,
        variables: {
          filter: { status: 'ACTIVE' }
        },
        fetchPolicy: 'no-cache'
      })
    } else if (filterValue == 'TODOS') {
      // alert(filterValue+'3')
      return apollo.query({
        query: gql`
      query userPoliciesGet($filter: PolicyFilter) {
        ${getFilters}
      }`,
        fetchPolicy: 'no-cache'
      })
    } else if (filterValue) {
      // alert(filterValue+'2')
      return apollo.query({
        query: gql`
      query userPoliciesGet($filter: PolicyFilter) {
        ${getFilters}
      }`,
        variables: {
          filter: { status: filterValue }
        },
        fetchPolicy: 'no-cache'
      })
    }
  }

  u(input, type) {
    // console.log(input, type, '12we')
    let inputType, dataType, fields, payload

    payload = {
      id: input.id,
      externalId: input.externalId,
      insuranceBrand: input.insuranceBrand,
      premium: input.premium,
      premiumType: input.premiumType,
      startDate: input.startDate,
      renewalDate: input.renewalDate,
      claimsNumber: parseInt(input.claimsNumber),
      claimsText: input.claimsText,
      rating: input.rating
    }

    fields = `
      id
      externalId
      insuranceBrand
      premium
      premiumType
      startDate
      renewalDate
      claimsNumber
      claimsText
      rating
      `

    switch (type.toLowerCase()) {
      case 'bicycle':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'bicyclePolicyUpdate'
        }
        break
      case 'boat':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'boatPolicyUpdate'
        }
        break
      case 'drone':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'dronePolicyUpdate'
        }
        break
      case 'family':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'familyPolicyUpdate'
        }
        break
      case 'gadget':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'gadgetPolicyUpdate'
        }
        break
      case 'whitegoods':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'whitegoodsPolicyUpdate'
        }
        break
      case 'child':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'childPolicyUpdate'
        }
        break
      case 'criticalillness':
        inputType = 'UpdateCriticalIllnessPolicyInput'

        if (!dataType) {
          dataType = 'criticalillnessPolicyUpdate'
        }
        break
      case 'housekeeper':
        inputType = 'UpdateHousekeeperPolicyInput'

        if (!dataType) {
          dataType = 'housekeeperPolicyUpdate'
        }
        break
      case 'hunter':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'hunterPolicyUpdate'
        }
        break
      case 'income':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'incomePolicyUpdate'
        }
        break
      case 'investment':
        inputType = 'UpdateInvestmentPolicyInput'

        if (!dataType) {
          dataType = 'investmentPolicyUpdate'
        }
        break
      case 'ppr':
        inputType = 'UpdatePprPolicyInput'

        if (!dataType) {
          dataType = 'pprPolicyUpdate'
        }
        break
      case 'mobile':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'mobilePolicyUpdate'
        }
        break
      case 'hospitalization':
        inputType = 'UpdateHospitalizationPolicyInput'

        if (!dataType) {
          dataType = 'hospitalizationPolicyUpdate'
        }
        break
      case 'scooter':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'scooterPolicyUpdate'
        }
        break
      case 'thirdparty':
        inputType = 'UpdateThirdPartyPolicyInput'

        if (!dataType) {
          dataType = 'thirdpartyPolicyUpdate'
        }
        break
      case 'other':
        inputType = 'UpdatePolicyInput'

        if (!dataType) {
          dataType = 'otherPolicyUpdate'
        }

        fields = `
          id
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'bike':
        inputType = 'UpdateBikePolicyInput'
        dataType = 'bikePolicyUpdate'
        fields = `
          id
          insuranceSubtype
          plateNumber
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          insuranceSubtype: input.insuranceSubtype,
          plateNumber: input.plateNumber,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'car':
        inputType = 'UpdateCarPolicyInput'
        dataType = 'carPolicyUpdate'
        fields = `
          id
          insuranceSubtype
          plateNumber
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          model
          brand
          registerDate
          renewalDate
          claimsNumber
          claimsText
          rating
          additionalCover
          vat
          licenseDate
          postalCode
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          insuranceSubtype: input.insuranceSubtype,
          plateNumber: input.plateNumber,
          premium: input.premium,
          premiumType: input.premiumType,
          model: input.model,
          brand: input.brand,
          registerDate: input.registerDate,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating,
          additionalCover: input.additionalCover,
          vat: input.vat,
          licenseDate: input.licenseDate,
          postalCode: input.postalCode
        }
        break
      case 'health':
        inputType = 'UpdateHealthPolicyInput'

        if (!dataType) {
          dataType = 'healthPolicyUpdate'
        }

        fields = `
          id
          externalId
          additionalCover
          insuredPersons
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          additionalCover: input.additionalCover,
          insuredPersons: input.insuredPersons,
          insuranceBrand: input.insuranceBrand,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'homecontents':
      case 'home':
        inputType = 'UpdateHomePolicyInput'
        dataType = 'homePolicyUpdate'
        fields = `
          id
          insuranceSubtype
          homeType
          homeUsage
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          insuranceSubtype: input.insuranceSubtype,
          homeType: input.homeType,
          homeUsage: input.homeUsage,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'mortgage':
        inputType = 'UpdateMortgagePolicyInput'
        dataType = 'mortgagePolicyUpdate'
        fields = `
          id
          insuranceSubtype
          capital
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
          profession
          disablement
          guarantors
          holdersSmoke
          holdersBirthdates
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          insuranceSubtype: input.insuranceSubtype,
          disablement: _.replace(input.disablement.toString(), ',', '.'),
          capital: _.replace(input.capital.toString(), ',', '.'),
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating,
          profession: input.profession,
          guarantors: input.guarantors,
          holdersSmoke: input.holdersSmoke,
          holdersBirthdates: input.holdersBirthdates
        }
        break
      case 'life':
        inputType = 'UpdateLifePolicyInput'
        dataType = 'lifePolicyUpdate'
        fields = `
          id
          insuranceSubtype
          disablement
          capital
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
          profession
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          insuranceSubtype: input.insuranceSubtype,
          disablement: _.replace(input.disablement.toString(), ',', '.'),
          capital: _.replace(input.capital.toString(), ',', '.'),
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating,
          profession: input.profession
        }
        break

      case 'pet':
        inputType = 'updatePetPolicyInput'
        dataType = 'petPolicyUpdate'
        fields = `
          id
          petType
          petName
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          petType: input.petType,
          petName: input.petName,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'personalaccident':
      case 'personal accident':
      case 'personal':
      case 'pa':
        inputType = 'updatePersonalAccidentPolicyInput'
        dataType = 'personalAccidentPolicyUpdate'
        fields = `
          id
          capital
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          capital: _.replace(input.capital.toString(), ',', '.'),
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'workaccident':
        inputType = 'updateWorkAccidentPolicyInput'
        dataType = 'workAccidentPolicyUpdate'
        fields = `
          id
          capital
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          capital: _.replace(input.capital.toString(), ',', '.'),
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'travel':
        inputType = 'updateTravelPolicyInput'
        dataType = 'travelPolicyUpdate'
        fields = `
          id
          endDate
          travelersNumber
          externalId
          insuranceBrand
          insuranceSubtype
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          insuranceSubtype: input.insuranceSubtype,
          endDate: input.endDate,
          travelersNumber: input.travelersNumber,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'dental':
        inputType = 'UpdateDentalPolicyInput'
        dataType = 'dentalPolicyUpdate'
        fields = `
          id
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
          insuredPersons
          personsBirthdate
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating,
          insuredPersons: input.insuredPersons,
          personsBirthdate: input.personsBirthdate
        }
        break
      case 'localLodging':
      case 'locallodging':
        inputType = 'updateLocalLodgingPolicyInput'
        dataType = 'localLodgingPolicyUpdate'
        fields = `
          id
          insuranceBrand
          premium
          premiumType
          renewalDate
          startDate
          claimsNumber
          claimsText
          rating
          insuranceBrand
          localLodgingRegistryNumber
          localLodgingType
        `
        payload = {
          id: input.id,
          insuranceBrand: input.insuranceBrand,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating,
          localLodgingRegistryNumber: input.localLodgingRegistryNumber,
          localLodgingType: input.localLodgingType
        }
        break
      case 'extendedwarranty':
        inputType = 'UpdateExtendedWarrantyPolicyInput'

        dataType = 'extendedwarrantyPolicyUpdate'

        fields = `
          id
          surname
          insuranceSubtype
          brand
          model
          purchaseDate
          pvp
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          surname: input.surname,
          insuranceSubtype: input.insuranceSubtype,
          brand: input.brand,
          model: input.model,
          purchaseDate: input.purchaseDate,
          pvp: input.pvp,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      case 'warranty':
        inputType = 'UpdateWarrantyPolicyInput'

        dataType = 'warrantyPolicyUpdate'

        fields = `
          id
          surname
          insuranceSubtype
          brand
          model
          purchaseDate
          pvp
          externalId
          insuranceBrand
          premium
          premiumType
          startDate
          renewalDate
          claimsNumber
          claimsText
          rating
        `
        payload = {
          id: input.id,
          externalId: input.externalId,
          insuranceBrand: input.insuranceBrand,
          surname: input.surname,
          insuranceSubtype: input.insuranceSubtype,
          brand: input.brand,
          model: input.model,
          purchaseDate: input.purchaseDate,
          pvp: input.pvp,
          premium: input.premium,
          premiumType: input.premiumType,
          startDate: input.startDate,
          renewalDate: input.renewalDate,
          claimsNumber: parseInt(input.claimsNumber),
          claimsText: input.claimsText,
          rating: input.rating
        }
        break
      default:
        break
    }

    let mutation = gql`
            mutation ${dataType}($input: ${inputType}) {
                ${dataType}(input: $input) {
                    ${fields}
                }
            }
        `
    let variables = { input: payload }

    return apollo.mutate({ mutation, variables }).then(() => {})
  }
}

export class userApplications {
  constructor() {
    this.get = `
      {
        getUserOpenApplicationsList{
          data {
          id
          description
          type
          url
          expiresAt
          createdAt 
          }
        }
      }
    `
  }

  g() {
    return apollo.query({
      query: gql`
        ${this.get}
      `,
      fetchPolicy: 'no-cache'
    })
  }
}

export class notifications {
  g() {
    return apollo.query({
      query: gql`
        {
          userGet {
            consents {
              privacyConsent
              insuranceConsent
              marketingConsent
              createdAt
              updatedAt
            }
          }
        }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  s(input) {
    let type = input.field

    return apollo.mutate({
      mutation: gql`
            mutation userUpdate($input: UserInput) {
                userUpdate(input: $input) {
                    ${type}
                }
            }
        `,
      variables: { input: { [type]: input.value } }
    })
  }
}

export class usersList {
  g() {
    return apollo.query({
      query: gql`
        {
          usersGet {
            uuid
            firstName
            lastName
            email
          }
        }
      `,
      fetchPolicy: 'no-cache'
    })
  }
}

export class userData {
  g() {
    return apollo.query({
      query: gql`
        {
          userGet {
            uuid
            email
            firstName
            lastName
            vat
            idNumber
            licenseNumber
            licenseDate
            address
            postcode
            birthday
            photoUrl
            profession
            maritalStatus
            jobTitle
            childrenNr
            annualSalary
            annualFixesExpenses
            houses
            vehicles
            hobbies
            pets
            referralsSent
            referralsCompleted
            consents {
              privacyConsent
              insuranceConsent
              marketingConsent
              createdAt
              updatedAt
            }
          }
        }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  s(input) {
    delete input['__typename']
    delete input['email']
    delete input['createdAt']
    delete input['uuid']

    input['childrenNr'] = parseInt(input['childrenNr'])
    input['annualSalary'] = parseInt(input['annualSalary'])
    input['annualFixesExpenses'] = parseInt(input['annualFixesExpenses'])

    return apollo.mutate({
      mutation: gql`
        mutation userUpdate($input: UserInput) {
          userUpdate(input: $input) {
            firstName
            lastName
            vat
            idNumber
            licenseNumber
            licenseDate
            address
            postcode
            birthday
            photoUrl
            profession
            maritalStatus
            jobTitle
            childrenNr
            annualSalary
            annualFixesExpenses
            houses
            vehicles
            hobbies
            pets
            consents {
              privacyConsent
              insuranceConsent
              marketingConsent
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: { input }
    })
  }
}

export class updateAdvisorAnswers {
  s(input) {
    return apollo.mutate({
      mutation: gql`
        mutation updateAdvisorAnswers(
          $uuid: String
          $email: String
          $responses: [AnswerResponseInput]
          $companyId: String
        ) {
          updateAdvisorAnswers(
            input: { uuid: $uuid, email: $email, responses: $responses, companyId: $companyId }
          ) {
            uuid
          }
        }
      `,
      variables: {
        uuid: input.uuid,
        email: input.email,
        responses: input.responses,
        companyId: input.companyId
      }
    })
  }
}

export class advisorAnswers {
  s(input) {
    return apollo.mutate({
      mutation: gql`
        mutation AddAdvisorAnswers($input: AdvisorAnswersInput) {
          addAdvisorAnswers(input: $input) {
            uuid
          }
        }
      `,
      variables: {
        input: { email: input.email, responses: input.responses, companyId: input.companyId }
      }
    })
  }
}

export class policyImport {
  constructor(type) {
    this.fieldSet = ``
    this.template = ``
    this.type = type
  }

  s(input) {
    this.fieldSet = input.fields

    let data = input.data,
      importPrefix = 'PolicyImport'

    if (input.admin) {
      importPrefix = 'PolicyAdminAdd'
      this.useAdminTemplate(input.specialQueryType)
    } else {
      this.useTemplate(input.specialQueryType)
    }

    //REMOVE IT ONCE FIXED ON BE
    if (data.capital) data.capital = _.replace(data.capital.toString(), ',', '.')
    if (data.disablement) data.disablement = _.replace(data.disablement.toString(), ',', '.')

    return apollo
      .mutate({
        mutation: gql`
          ${this.template}
        `,
        variables: { input: data }
      })
      .then(response => {
        let item = response.data[this.type + importPrefix]
        return item
      })
  }
  useAdminTemplate(withType = false) {
    let queryType = 'PolicyAdminInput'

    if (withType) {
      let type = this.type

      if (this.type === 'personalaccident') {
        type = 'PersonalAccident'
        this.type = 'personalAccident'
      }

      if (this.type === 'homecontents') {
        type = 'Other'
        this.type = 'other'
      }

      if (this.type === 'travel') {
        this.fieldSet += 'insuranceSubtype'
      }

      queryType = type.charAt(0).toUpperCase() + type.slice(1) + 'PolicyAdminInput'
    }

    if (this.type === 'third_party') {
      this.type = 'thirdparty'
    }

    this.template = `
      mutation ${this.type}PolicyAdminAdd($input: ${queryType}) {
        ${this.type}PolicyAdminAdd(input: $input) {
          id
          uuidUser
          insuranceType
          ${this.fieldSet}
        }
      }
    `
  }
  useTemplate() {
    let queryType = 'PolicyInput'
    let type = this.type
    let prefix = true

    if (type === 'thirdparty' || type === 'third_party') {
      type = 'ThirdParty'
      this.type = 'thirdparty'
    } else if (type === 'travel') {
      this.fieldSet += 'insuranceSubtype'
      type = 'Travel'
    } else if (type === 'bike') {
      type = 'Bike'
    } else if (type === 'health') {
      type = 'Health'
    } else if (type === 'dental') {
      type = 'Dental'
    } else if (type === 'car') {
      type = 'Car'
    } else if (type === 'extendedwarranty') {
      type = 'ExtendedWarranty'
    } else if (type === 'criticalillness') {
      type = 'CriticalIllness'
    } else if (type === 'home') {
      type = 'Home'
    } else if (type === 'hospitalization') {
      type = 'Hospitalization'
    } else if (type === 'housekeeper') {
      type = 'Housekeeper'
    } else if (type === 'investment') {
      type = 'investment'
    } else if (type === 'life') {
      type = 'Life'
    } else if (type === 'mortgage') {
      type = 'Mortgage'
    } else if (type === 'personalaccident' || type === 'personal_accident') {
      type = 'PersonalAccident'
      this.type = 'personalAccident'
      this.fieldSet += 'insuranceSubtype'
    } else if (type === 'pet') {
      type = 'Pet'
    } else if (type === 'ppr') {
      type = 'ppr'
    } else if (type === 'warranty') {
      type = 'Warranty'
    } else if (type === 'workAccident' || type === 'work_accident') {
      type = 'WorkAccident'
      this.type = 'workAccident'
      this.fieldSet += 'insuranceSubtype'
    } else if (type === 'localLodging') {
      type = 'localLodging'
    } else if (type === 'homecontents') {
      type = 'Other'
      this.type = 'other'
    } else {
      prefix = false
    }

    if (prefix) {
      queryType = ''.concat(type, 'PolicyInput')
    }

    this.template = `
    mutation ${this.type}PolicyImport($input: ${queryType}) {
      ${this.type}PolicyImport(input: $input) {
        id
        insuranceType
        ${this.fieldSet}
      }
    }
    `
  }
}
export class getAdvisorQuestions {
  g() {
    return apollo.query({
      query: gql`
        {
          getAdvisorQuestions {
            title
            key
            questions {
              uuid
              key
              question
              choice
              questionType
              child {
                uuid
                key
                question
                choice
                questionType
                select
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    })
  }
}

export class advisorResults {
  g() {
    return apollo.query({
      query: gql`
        {
          getAdvisorSuggestions {
            uuid
            userId
            mandatory {
              car
              bike
              bicycle
              scooter
              home
              content
              mortgage
              life
              housekeeper
              health
              dental
              criticalIllness
              hospitalization
              personalAccident
              sports
              child
              family
              drone
              workAccidentSelfEmployeed
              workAccidentEmployee
              investment
              ppr
              thirdPartyProfessional
              pet
              thirdPartyPet
            }
            recommended {
              car
              bike
              bicycle
              scooter
              home
              content
              mortgage
              life
              housekeeper
              health
              dental
              criticalIllness
              hospitalization
              personalAccident
              sports
              child
              family
              drone
              workAccidentSelfEmployeed
              workAccidentEmployee
              investment
              ppr
              thirdPartyProfessional
              pet
              thirdPartyPet
            }
            consider {
              car
              bike
              bicycle
              scooter
              home
              content
              mortgage
              life
              housekeeper
              health
              dental
              criticalIllness
              hospitalization
              personalAccident
              sports
              child
              family
              drone
              workAccidentSelfEmployeed
              workAccidentEmployee
              investment
              ppr
              thirdPartyProfessional
              pet
              thirdPartyPet
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    })
  }
}
export class updateAdvisorResults {
  g() {
    return apollo.query({
      query: gql`
        {
          updateAdvisorSuggestions {
            userId
            submittedAt
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    })
  }
}

export class getAdvisorAnswers {
  g(id) {
    return apollo.query({
      query: gql`
        {
          getAdvisorAnswers(id: "${id}") {
            key
            questions {
              uuid
              answer
              question
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    })
  }
}

export class policyFiles {
  d(input) {
    return apollo.mutate({
      mutation: gql`
        mutation deleteFile($uuid: ID) {
          deleteFile(uuid: $uuid) {
            uuid
          }
        }
      `,
      variables: { uuid: input }
    })
  }
  g(id) {
    return apollo.query({
      query: gql`
        {
          getPolicyFiles(id: "${id}") {
            fileId
            name
            url
            encodeUrl
            fileUuid
            uuid
          }
        }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  s({ file, id }) {
    return apollo.mutate({
      context: {
        hasUpload: true
      },
      mutation: gql`
        mutation uploadPolicyFile($file: Upload!, $id: ID!) {
          uploadPolicyFile(file: $file, id: $id) {
            fileId
            name
            url
            uuid
          }
        }
      `,
      variables: { id, file }
    })
  }
}

export class getPolicyFiles {
  g(id) {
    return apollo.query({
      query: gql`
        {
          getFile(uuid: "${id}") {
            fileId
            fileUuid
            name
            url
            encodeUrl
          }
        }
      `,
      fetchPolicy: 'no-cache'
    })
  }
}

export class getCarDetails {
  g(plate) {
    return apollo.query({
      query: gql`
        {
          getCarDetails(plate: "${plate}"){
            details {
              plate
              brand
              model
              chassis
              cc
              registerDate
              fuel
            }
            versions
          }
        }
      `,
      fetchPolicy: 'no-cache'
    })
  }
}

export class getCarVersions {
  c(plate, identificationType) {
    return apollo.query({
      query: gql`
        query RootQuery($plate: String, $identificationType: IdentificationType!) {
          getCarVersions(plate: $plate, identificationType: $identificationType) {
            versionMatch
            typeCode
            plateRegDate
          }
        }
      `,
      variables: { plate, identificationType },
      fetchPolicy: 'no-cache'
    })
  }
}

export class getCarModel {
  c(plate, typeCode) {
    return apollo.query({
      query: gql`
        query RootQuery($plate: String, $typeCode: String) {
          getCarModel(plate: $plate, typeCode: $typeCode) {
            plate
            typeCode
            brand
            model
          }
        }
      `,
      variables: { plate, typeCode },
      fetchPolicy: 'no-cache'
    })
  }
}

export class getProfessions {
  g() {
    return apollo.query({
      query: gql`
        {
          getProfessions {
            professions
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    })
  }
}
