import React, { useState, useEffect } from 'react'
// import Select from 'react-select'
import PropTypes from 'prop-types'
import ChildComponent from './childComponent'
import Form from '../../../../../components/ui/Form'
import { SPORTS } from '../utils/sports'
// import { selectHttpOptionsAndBody } from '@apollo/client'

// import '../../../../../common.less'
// **************** UTILS ****************
// import getProvider from '../../../../../utils/getProviders'

// This is the topmost <select component which is used only once in this section
export default function SingleSelectProvider({
  item,
  onSelect,
  onChildInput,
  defaultValue,
  isError
}) {
  const [selectedOption, setSelectedOption] = useState(null)
  const [options, setOptions] = useState([])
  const [showChild, setShowChild] = useState(null)

  useEffect(() => {
    let options = []
    if (item.uuid == 'c957a6dd-456d-42a3-b9c9-2c57560f9d64') {
      for (let i = 0; i < SPORTS.length; i++) {
        let obj = {}
        obj.name = SPORTS[i].sports
        obj.value = SPORTS[i].sports
        options.push(obj)
      }
    } else {
      for (let i = 0; i < item.choice.length; i++) {
        let obj = {}
        obj.name = item.choice[i]
        obj.value = item.choice[i]
        options.push(obj)
      }
    }
    setOptions(options)
  }, [])

  useEffect(() => {
    setSelectedOption(defaultValue && defaultValue[item.uuid] && defaultValue[item.uuid][0])
  }, [defaultValue])

  useEffect(() => {
    if (selectedOption && item.child) {
      let dependency = []
      for (let i = 0; i < item.child[0].select.length; i++) {
        dependency.push(item.child[0].select[i])
      }
      let check = dependency.some(e => e == selectedOption.value)
      if (check) {
        setShowChild(true)
      }
    }
  }, [selectedOption])

  const handleChange = val => {
    setSelectedOption(val)
    onSelect(val)
  }

  return (
    <React.Fragment>
      <Form
        data={options}
        onValueChange={v => {
          handleChange(v)
        }}
        type="select"
        value={selectedOption}
        className="select-item-list half-width-input"
      />
      <br />
      {showChild && item.child && (
        <ChildComponent
          child={item.child[0]}
          onChildSelect={(v, quuid) => {
            onChildInput(v, quuid)
          }}
          defaultValues={defaultValue}
          isError={isError}
        />
      )}
    </React.Fragment>
  )
}

SingleSelectProvider.propTypes = {
  item: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChildInput: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired,
  isError: PropTypes.any.isRequired
}
