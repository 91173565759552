import 'element-theme-default'

import { Notification } from 'element-react'
import PropTypes from 'prop-types'
import React from 'react'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import firebase from 'firebase/app'
import query from 'query-string'
import validator from 'validator'
import { verifyErrorCode } from '../ui/LocalizeFirebaseErrorMessages'
import { Input } from 'antd'
import Swal from 'sweetalert2'

class Auth extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pwd1: '',
      pwd2: '',
      buttonDisabled: false,
      eyeOpen: true,
      eyeOpen2:true
    }
  }

  render() {
    return (
      <div>
        <div className="recovery">
          <h1>Definir password</h1>
          <p>Indica qual a nova password para entrares na tua wallet MUDEY</p>

          <div className="form">
            <label htmlFor="login">Nova password</label>
            <Input
              size="large"
              name="pwd1"
              suffix={
                this.state.eyeOpen ? (
                  <EyeOutlined onClick={() => this.setState({ eyeOpen: false })} />
                ) : (
                  <EyeInvisibleOutlined onClick={() => this.setState({ eyeOpen: true })} />
                )
              }
              type={this.state.eyeOpen?"text":"password"}
              className="input-password"
              id="pwd1"
              onChange={v => {
                this.setState({ pwd1: v.target.value })
              }}
            />

            <label htmlFor="login">Repete a password</label>
            <Input
              size="large"
              name="pwd2"
              type={this.state.eyeOpen2?"text":"password"}
              className="input-password"
              id="pwd2"
              suffix={
                this.state.eyeOpen2 ? (
                  <EyeOutlined onClick={() => this.setState({ eyeOpen2: false })} />
                ) : (
                  <EyeInvisibleOutlined onClick={() => this.setState({ eyeOpen2: true })} />
                )
              }
              onChange={v => {
                this.setState({ pwd2: v.target.value })
              }}
            />
          </div>

          <button
            className="button-solid"
            style={{ cursor: this.state.buttonDisabled ? 'not-allowed' : '' }}
            disabled={this.state.buttonDisabled}
            onClick={() => {
              this.setState({ buttonDisabled: true })
              this.restore()
            }}>
            Recuperar Password
          </button>
        </div>
      </div>
    )
  }
  restore() {
    const { location } = this.props
    const parsed = query.parse(location.search)

    const { continueUrl, oobCode } = parsed
    // console.log(this.state.pwd1,this.state.pwd1)

    if (
      !validator.isStrongPassword(this.state.pwd1, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      this.setState({ buttonDisabled: false })
      return Notification.error({
        title: 'Whoops... deu erro!',
        // message: 'Password tem de ter pelo menos 6 digitos'
        message:
          'Password deve conter pelo menos uma letra maiúscula, um caracter especial e um número'
      })
    }

    if (this.state.pwd1 !== this.state.pwd2) {
      this.setState({ buttonDisabled: false })
      return Notification.error({
        title: 'Whoops... deu erro!',
        message: 'As passwords têm de ser iguais'
      })
    }

    if (
      validator.isStrongPassword(this.state.pwd1, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then(() => {
          firebase
            .auth()
            .confirmPasswordReset(oobCode, this.state.pwd1)
            .then(() => {
              this.setState({ buttonDisabled: false })
              return Swal.fire({
                title: 'Password alterada',
                html: 'A tua password foi alterada com sucesso!',
                confirmButtonText: 'Ok',
                // scrollbarPadding: false,
                // optional
                heightAuto: false,
                confirmButtonColor: '#accecd',
                allowOutsideClick: false
              }).then(() => {
                window.location.href = continueUrl
              })
              // Notification.success({
              //   title: 'Password alterada',
              //   message: 'A tua password foi alterada com sucesso!'
              // })
            })
          // .then(() => {
          //   setTimeout(() => {
          //     window.window.location.href = continueUrl
          //   }, 2000)
          // })
        })
        .catch(e => {
          const errorMessage = verifyErrorCode(e.code)
          this.setState({ buttonDisabled: false })
          return Swal.fire({
            title: 'Whoops... deu erro!',
            html: errorMessage,
            confirmButtonText: 'Ok',
            // scrollbarPadding: false,
            // optional
            heightAuto: false,
            confirmButtonColor: '#ff4949',
            allowOutsideClick: false
          })
          // Notification.error({
          //   title: 'Whoops... deu erro!',
          //   message: errorMessage
          // })
        })
    }
  }
}

Auth.propTypes = {
  location: PropTypes.any.isRequired
}

export default Auth
