// import Form from '../../../../../components/ui/Form'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import moment from 'moment'

export const RenderInputField = ({ onInput, defaultValue }) => {
  const [dob, setDOB] = useState(null)

  useEffect(() => {
    if (defaultValue != null) {
      setDOB(typeof defaultValue == 'object' ? defaultValue[0] : defaultValue)
    }
  }, [defaultValue])

  return (
    <>
      <MaskedInput
        className="input-md"
        // defaultValue={defaultValue && defaultValue[0]}
        value={dob}
        guide={false}
        mask={[/[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        name="bdate"
        
        onChange={e => {
          if (e.target.value.length == 10) {
            const value = moment(e.target.value, 'DD-MM-YYYY')

            if (moment().diff(moment(value), 'years') >= 18) {
              onInput(e.target.value)
              setDOB(e.target.value)
            } else {
              onInput(null)
              setDOB(null)
            }
          }
        }}
        placeholder={['dd-mm-yyyy']}
      />
      {/* <Form
        onValueChange={v => {
          onInput(v)
        }}
        type="birth-date"
        defaultValue={defaultValue && defaultValue[0]}
        value={defaultValue && defaultValue[0]}
      /> */}
      {/* Error Message */}
      {/* <p className="error-text">{isError == true ? 'Tens de preencher este campo' : ''}</p> */}
    </>
  )
}

RenderInputField.propTypes = {
  onInput: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired
}
