import React, { useEffect, useState } from 'react'
import Ql from '../../api/ql'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './index.less'
import {
  SET_ADVISOR_SURVEY_ID,
  SET_CONSIDER,
  SET_MANDATORY,
  SET_RECOMMENDED
} from '../../store/actions'
import Routes from '../../routes/index'
// import { Spin } from 'antd'

export const Advisor = () => {
  const isPhoneValidAndVerified = useSelector(state => state.isPhoneValidAndVerified)
  const history = useHistory()
  const dispatch = useDispatch()
  const [suggestionList, setSuggestionList] = useState(null)
  // const [loading, setLoading] = useState(false)
  // const [timer, setTimer] = useState(3)
  // const [showTime, setShowTime] = useState('3 min')

  let resizeTimer = null
  let MOBILE_BREAKPOINT = 1280
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  useEffect(() => {
    if (!isPhoneValidAndVerified) return history.push('/verify-number')
  }, [isPhoneValidAndVerified])

  const handleResize = () => {
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(() => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)
    }, 500)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // const startTimer = () => {
  //   console.log('start timer')
  //   let down = timer * 60 // seconds
  //   const t = setInterval(() => {
  //     down--
  //     if (down > 60) {
  //       if (down % 60 === 0) {
  //         setTimer(down / 60)
  //         setShowTime(`${down / 60} min`)
  //       }
  //     } else {
  //       setTimer(down)
  //       setShowTime(`${down} sec`)
  //     }
  //     console.log('timer', down)

  //     if (down === 0) {
  //       setTimer(0)
  //       clearInterval(t)
  //     }
  //   }, 1000)
  // }

  useEffect(() => {
    // startTimer()
    getAdvisorResults()
  }, [])

  useEffect(() => {
    if (suggestionList !== null) {
      // Redirect to suggestion page if there are suggestions
      if (
        Object.values(suggestionList.mandatory).includes(1) ||
        Object.values(suggestionList.recommended).includes(1) ||
        Object.values(suggestionList.consider).includes(1)
      ) {
        window.location.href = Routes.advisorSuggestion.path
      }
    }
  }, [suggestionList])

  const getAdvisorResults = () => {
    // setLoading(true)
    new Ql('advisorResults')
      .get()
      .then(response => {
        if (response.data.getAdvisorSuggestions) {
          dispatch({ type: SET_MANDATORY, payload: response.data.getAdvisorSuggestions.mandatory })
          dispatch({
            type: SET_RECOMMENDED,
            payload: response.data.getAdvisorSuggestions.recommended
          })
          dispatch({ type: SET_CONSIDER, payload: response.data.getAdvisorSuggestions.consider })

          dispatch({
            type: SET_ADVISOR_SURVEY_ID,
            payload: response.data.getAdvisorSuggestions.uuid
          })

          setSuggestionList(response.data.getAdvisorSuggestions)
          // setLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        // Swal.fire({
        //   icon: 'error',
        //   title: 'opa',
        //   text: error.message
        // })
      })
  }

  return (
    <>
      {!isMobile && (
        <div className="advisors">
          <div className="advisor-banner">
            <div className="advisor-landing">
              <img alt="Get Started" src={require('../../images/advisor-landing.png')} />
              <div className="landing-right">
                <h3 className="landing-title">O teu consultor</h3>
                <p className="landing-content">
                  O teu consultor ajuda-te a perceber quais os seguros que deves ter para estares
                  bem protegido(a)
                </p>
                {/* {timer !== 0 && (
            <p className="landing-content timer">
              <img alt="Get Started" src={require('../../images/clock.png')} />
              <p>{showTime}</p>
            </p>
          )} */}
                {/* {timer === 0 && (
            <div className="start-btn" onClick={() => history.push(Routes.advisorForms.path)}>
              Iniciar o consultor
            </div>
          )} */}
                <div className="start-btn" onClick={() => history.push('/form')}>
                  Iniciar o consultor
                </div>
              </div>
            </div>
            <div style={{ background: '#895D85', width: '415px', borderRadius: '6px' }}>
              <img
                className="bottom-flakes"
                alt=""
                src={require('../../images/rice-flakes-mobile.png')}
              />
            </div>
          </div>

          {/* {loading && (
            <div className="advisor-loader">
              <Spin />
            </div>
          )} */}
        </div>
      )}

      {isMobile && (
        <div className="advisors-m">
          <div className="advisor-landing">
            <div className="landing-right">
              <h3 className="landing-title">O teu consultor</h3>
              <p className="landing-content">
                O teu consultor ajuda-te a perceber quais os seguros que deves ter para estares bem
                protegido(a)
              </p>
              <div className="start-btn" onClick={() => history.push('/form')}>
                Iniciar o consultor
              </div>
            </div>
            <img
              alt="Get Started"
              className="landing-img-m"
              src={require('../../images/advisory-landing-mobile.png')}
            />
            <img
              className="bottom-flakes-m"
              alt=""
              src={require('../../images/rice-flakes-mobile.png')}
            />
          </div>
        </div>
      )}
    </>
  )
}
