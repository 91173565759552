import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ChildComponent from './childComponent'
import CheckableMultiAntTag from '../../../../../components/Tags/MultiSelectTag'

// This is the topmost <select component which is used only once in this section
export default function MultiTabSelect({
  item,
  onSelect,
  onChildInput,
  defaultValue,
  isError,
  formValues
}) {
  const [selectedOption, setSelectedOption] = useState([])
  const [showChild, setShowChild] = useState([])
  const [disableTagList, setDisableTagList] = useState([])

  useEffect(() => {
    setSelectedOption(defaultValue && defaultValue[item.uuid] ? defaultValue[item.uuid] : [])
  }, [defaultValue])

  useEffect(() => {
    const showChildQues = []
    if (selectedOption.length > 0 && item.child != null && item.child.length > 0) {
      for (var ch of item.child) {
        onChildInput('', ch.uuid)
        var filteredArray = ch.select.filter(function(n) {
          return selectedOption.indexOf(n) !== -1
        })

        if (filteredArray.length > 0) {
          showChildQues.push(ch.uuid)
        }
      }
    }

    setShowChild(showChildQues)
  }, [selectedOption])

  const handleChange = (tag, checked) => {
    // Handle vehicle selection case
    if (item.uuid === 'cbd54e84-c127-48f3-8ae7-484f49472397') {
      if (tag == 'Não conduzo / Não tenho' && checked === false) {
        setDisableTagList(item.choice.filter(c => c !== 'Não conduzo / Não tenho'))
        onSelect([tag])
        setSelectedOption([tag])
      } else {
        setDisableTagList([])
        const preViousData = selectedOption
        const newData = !checked ? [...preViousData, tag] : preViousData.filter(t => t !== tag)
        onSelect(newData.length == 0 ? null : newData)
        setSelectedOption(newData)
      }
      // if (selectedOption.length > 0) {
      //   if (
      //     selectedOption.includes('Não conduzo / Não tenho') &&
      //     tag == 'Não conduzo / Não tenho'
      //   ) {
      //     const preViousData = selectedOption
      //     const newData = !checked ? [...preViousData, tag] : preViousData.filter(t => t !== tag)
      //     onSelect(newData)
      //     setSelectedOption(newData)
      //   } else if (
      //     !selectedOption.includes('Não conduzo / Não tenho') &&
      //     tag != 'Não conduzo / Não tenho'
      //   ) {
      //     const preViousData = selectedOption
      //     const newData = !checked ? [...preViousData, tag] : preViousData.filter(t => t !== tag)
      //     onSelect(newData)
      //     setSelectedOption(newData)
      //   }
      // } else {
      //   const preViousData = selectedOption
      //   const newData = !checked ? [...preViousData, tag] : preViousData.filter(t => t !== tag)
      //   onSelect(newData)
      //   setSelectedOption(newData)
      // }
      // Handle disease selection
    } else if (item.uuid === '574d279b-355b-4f2c-b9bb-028fa3eb40e3') {
      if (tag == 'Não tenho' && checked === false) {
        setDisableTagList(item.choice.filter(c => c !== 'Não tenho'))
        onSelect([tag])
        setSelectedOption([tag])
      } else {
        setDisableTagList([])
        const preViousData = selectedOption
        const newData = !checked ? [...preViousData, tag] : preViousData.filter(t => t !== tag)
        onSelect(newData.length == 0 ? null : newData)
        setSelectedOption(newData)
      }
    }
  }

  return (
    <>
      <div className="selectable-tags-div">
        {item.choice.map((tag, index) => (
          <CheckableMultiAntTag //Reusale component to select tags on click
            tag={tag}
            key={index}
            setValue={(tag, checked) => handleChange(tag, checked)}
            value={selectedOption}
            isDisabled={disableTagList.includes(tag)}
          />
        ))}
      </div>
      {showChild.length > 0 &&
        item.child.map(c => {
          return (
            showChild.includes(c.uuid) && (
              <div className="child-div" key={c.uuid}>
                <ChildComponent
                  child={c}
                  onChildSelect={(v, quuid) => {
                    onChildInput(v, quuid)
                  }}
                  defaultValues={defaultValue}
                  formValues={formValues}
                  isError={isError}
                />
              </div>
            )
          )
        })}
    </>
  )
}

MultiTabSelect.propTypes = {
  item: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChildInput: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired,
  formValues: PropTypes.any.isRequired,
  isError: PropTypes.any.isRequired
}
