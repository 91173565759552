import React, { useState, useEffect } from 'react'
// eslint-disable-next-line unused-imports/no-unused-imports
import { Row, Col, Spin } from 'antd'
import Form from '../components/AdvisorForm/index'
import { Progress } from '../../../containers/dashboard/components/progress'
import { useDispatch, useSelector } from 'react-redux'
import Ql from '../../../api/ql'
import { SET_MANDATORY, SET_RECOMMENDED, SET_CONSIDER } from '../../../store/actions'
import Routes from '../../../routes'
import { Progress as MProgress } from 'antd'
import Loading from './loading'

const UserForms = () => {
  const [progress, setProgress] = useState(0)
  const color = '#895d85'
  const bgColor = 'rgba(137, 91, 133, 0.3)'
  // const [color, setColor] = useState('#895d85')
  // const [bgColor, setBgColor] = useState('rgba(137, 91, 133, 0.3)')
  const [showViewDiv, setShowViewDiv] = useState(false)
  const [viewResultsClicked, setViewResultsClicked] = useState(false)
  const [loadingResults, setLoadingResults] = useState(false)
  const username = useSelector(state => state.user_data.firstName)
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  let resizeTimer = null
  let MOBILE_BREAKPOINT = 1280
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  const handleResize = () => {
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(() => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)
    }, 500)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    setTimeout(() => {
      setShow(true)
    }, 1000)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (viewResultsClicked === true) {
      setLoadingResults(true)
      new Ql('advisorResults')
        .get()
        .then(response => {
          if (response.data.getAdvisorSuggestions) {
            dispatch({
              type: SET_MANDATORY,
              payload: response.data.getAdvisorSuggestions.mandatory
            })
            dispatch({
              type: SET_RECOMMENDED,
              payload: response.data.getAdvisorSuggestions.recommended
            })
            dispatch({ type: SET_CONSIDER, payload: response.data.getAdvisorSuggestions.consider })

            setTimeout(() => {
              window.location.href = Routes.advisorSuggestion.path
              // setLoadingResults(false)
              // setViewResultsClicked(false)
            }, 3000)
          }
        })
        .catch(error => {
          console.log(error)
          // Swal.fire({
          //   icon: 'error',
          //   title: 'opa',
          //   text: error.message
          // })
        })
    }
  }, [viewResultsClicked])

  // style={{ position: 'sticky', top: 0, zIndex: 120, bottom: '10px', marginBottom: '10px' }}
  return (
    <>
      {isMobile && (
        <Row>
          <Col lg={18} md={18}>
            <div className="progress-bar-m">
              <div className="progress-bar-inner-m">
                <MProgress percent={progress} />
              </div>
            </div>
          </Col>
        </Row>
      )}
      <div style={{ display: 'flex' }}>
        <div>
          <Row>
            <div className="mainHeader">
              <h1 className="mainText">O teu consultor</h1>
              <div className="partner-home-wrapper">
                <div className="wrapper-partner-position">{/* <PartnersHeader /> */}</div>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <Form
                onFormStateUpdate={data => {
                  let total = 0
                  let answered = 0
                  for (var value of Object.values(data)) {
                    total++
                    if (value !== null) {
                      answered++
                    }
                  }

                  const updatedProgress = ((answered / total) * 100).toFixed()
                  setProgress(isNaN(updatedProgress) ? 0 : updatedProgress)
                }}
                showViewResultButton={isShow => setShowViewDiv(isShow)}
              />
            </Col>
          </Row>
        </div>
        {!isMobile && show ? (
          <Col lg={6} md={6}>
            <div className="form-progress-bar">
              <div className="form-progress-bar-title">
                <img alt="" className="icon" src={require('../../../images/sofia.png')} />
                Olá {username}, eu sou a Sofia!
              </div>
              <div className="form-progress-bar-content">
                <p>Preenche todos os dados para te apresentar os seguros que deves ter...</p>
                <Progress
                  progress={progress}
                  size={75}
                  strokeWidth={6}
                  circleOneStroke={bgColor}
                  circleTwoStroke={color}
                />
              </div>
            </div>
          </Col>
        ) : null}
      </div>
      <Row>
        {showViewDiv === true && (
          <div className="advisor-form-footer">
            <div>
              <div className="ft1">
                <img src={require('../../../images/advisor-result-emo.png')} />
                <p className="ftr-text">
                  {loadingResults === true
                    ? 'A trabalhar nos teus resultados'
                    : `Tudo preenchido ${username}!`}
                </p>
              </div>
              {loadingResults === true && (
                // <div className="view-result-btn">
                //   {/* <Spin /> */}
                // </div>
                <Loading />
              )}
              {loadingResults === false && (
                <div className="view-result-btn" onClick={() => setViewResultsClicked(true)}>
                  Ver os resultados
                </div>
              )}
            </div>
          </div>
        )}
      </Row>
    </>
  )
}

export default UserForms
