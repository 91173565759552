// @flow
import React from 'react'
import './styles.less'

export const Section = ({
  title,
  headerText,
  footerText,
  children,
  hintText,
  footerOnClick,
  noBorder = false,
  isMobile
}: any) => {
  return (
    <div className="section_wrapper">
      <div className="section_title">{title}</div>
      <section
        className="section_container"
        style={{ border: !noBorder ? '3px solid #b181aa' : 'none' }}>
        {headerText && (
          <div className="section_header">
            <span className="section_headers_text">{headerText}</span>
            {hintText && (
              <div className="section_headers_hint">
                <span className="section_headers_hint_sign">!</span>
                <span
                  style={{ width: isMobile ? '25vw' : null }}
                  className="section_headers_hint_text">
                  {hintText}
                </span>
              </div>
            )}
          </div>
        )}
        <div className="section_content">{children}</div>
        {footerText && (
          <div
            className="section_footer"
            onClick={() => {
              footerOnClick && footerOnClick()
            }}>
            <span>{footerText}</span>
          </div>
        )}
      </section>
    </div>
  )
}
