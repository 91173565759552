export const redirectToApp = item => {
  const userId=localStorage.getItem("userId_wallet")
  // if(userId){
  switch (item) {
    //app_MD = app.mudey.pt
    //app_HD = mudey.pt
    case 'LIFE':
      return `${process.env.REACT_APP_MD_URL}seguro-vida/simulacao?overview=${userId}`
    case 'CAR':
      return `${process.env.REACT_APP_MD_URL}seguro-carro/simulacao?overview=${userId}`
    case 'PERSONALACCIDENT':
      return `${process.env.REACT_APP_MD_URL}seguro-acidentes-pessoais/simulacao`
    case 'TRAVEL':
      return `${process.env.REACT_APP_MD_URL}seguro-viagem/simulacao?overview=${userId}`
    case 'HOME':
      return `${process.env.REACT_APP_MD_URL}seguro-casa/simulacao`
    case 'DENTAL':
      return `${process.env.REACT_APP_MD_URL}seguro-dental/simulacao`
    case 'MORTGAGE':
      return `${process.env.REACT_APP_MD_URL}seguro-vida-credito/simulacao?overview=${userId}`
    case 'HEALTH':
      return `${process.env.REACT_APP_MD_URL}seguro-saude/simulacao`
    case 'CRITICALILLNESS':
      return `${process.env.REACT_APP_MD_URL}doencas-graves/simulacao?overview=${userId}`
    case 'HOUSEKEEPER':
      return `${process.env.REACT_APP_MD_URL}seguro-empregada/simulacao?overview=${userId}`
    case 'PPR':
      return `${process.env.REACT_APP_MD_URL}seguro-ppr/simulacao?overview=${userId}`
    case 'INVESTMENT':
      return `${process.env.REACT_APP_MD_URL}seguro-investimento/simulacao`
    case 'WORKACCIDENT':
      return `${process.env.REACT_APP_MD_URL}seguro-acidentes-trabalho-independente/simulacao?overview=${userId}`
    case 'PET':
      return `${process.env.REACT_APP_MD_URL}seguro-animais-domesticos/simulacao?overview=${userId}`
    // Bellow are REACT_APP_HP_URL
    case 'BICYCLE':
      return `${process.env.REACT_APP_HP_URL}/seguro-bicicleta-simulacao`
    case 'CHILD':
      return `${process.env.REACT_APP_HP_URL}/seguro-crianca/simulador`
    case 'DRONE':
      return `${process.env.REACT_APP_HP_URL}/seguro-drone-simulacao`
    case 'FAMILY':
      return `${process.env.REACT_APP_HP_URL}/produtos/seguro-familia/simulador/`
    case 'SCOOTER':
      return `${process.env.REACT_APP_HP_URL}/produtos/seguro-trotinete/simulador/`
    case 'LOCALLODGING':
      return `${process.env.REACT_APP_MD_URL}seguro-alojamento-local/simulacao?overview=${userId}`  
    /* NOTE: If you fill some of the next ones please be aware that
    you must move then above this comment, all this cases are returning
    typeform, so if you add the return for, for example, gadget, whitegoods and mobile
    also will get that return link statement */
    case 'BIKE':
    case 'INCOME':
    case 'PET':
    case 'THIRDPARTY':
    case 'OTHER':
    case 'MOBILE':
    case 'BOAT':
    case 'HUNTER':
    case 'WHITEGOODS':
    case 'EXTENDESWARRANTY':
    case 'GADGET':
      return 'typeform'
  }
}
// }
