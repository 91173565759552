import { Link } from 'react-router-dom'
import React from 'react'

class HelpCenterList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      categories: [],
      filters: [],
      displayFilters: false
    }
  }

  articleBlock(item) {
    return (
      <div className="block" key={item.id}>
        <img alt="" src={item.image} />
        <div className="description">
          <Link to={'/hc/' + item.id}>{item.title}</Link>
          <p>{item.caption}</p>
        </div>
      </div>
    )
  }
  componentDidMount() {
    // axios.get('/service/articles').then(response => {
    //   let categories = []
    //   this.setState({ data: response.data })
    //   response.data.map(article => {
    //     let categoriesNodes = JSON.parse(article.category)
    //     categoriesNodes.forEach(category => {
    //       if (categories.indexOf(category) === -1) {
    //         categories.push(category)
    //       }
    //     })
    //     return article
    //   })
    //   this.setState({ categories })
    // })
  }

  render() {
    let categories, content

    if (this.state.categories) {
      categories = this.state.categories.map(item => {
        return (
          <li key={item}>
            <img
              alt="unchecked"
              className={this.state.filters.indexOf(item) !== -1 ? 'hidden' : ''}
              src={require('../../images/icons/unchecked.svg')}
            />
            <img
              alt="checked"
              className={this.state.filters.indexOf(item) !== -1 ? '' : 'hidden'}
              src={require('../../images/icons/checked.svg')}
            />
            <input
              id={item.replace(' ', '')}
              onChange={e => {
                this.setFilter(e.target)
              }}
              type="checkbox"
              value={item}
            />
            <label htmlFor={item.replace(' ', '')}>{item}</label>
          </li>
        )
      })
    }

    if (this.state.data) {
      content = this.state.data.map(item => {
        if (this.state.filters.length > 0) {
          let categories = JSON.parse(item.category),
            allowed = false

          categories.map(node => {
            if (this.state.filters.indexOf(node) !== -1) {
              allowed = true
            }
            return node
          })

          if (allowed) {
            return this.articleBlock(item)
          }
        } else {
          return this.articleBlock(item)
        }

        return ''
      })
    }

    return (
      <div>
        <h1>Curiosidades</h1>

        <div className="hc-filters">
          <div
            className="hc-filter-button"
            onClick={() => {
              this.setState({ displayFilters: !this.state.displayFilters })
            }}>
            <img alt="sliders" src={require('../../images/icons/sliders.svg')} />
            <span>Filtros</span>
          </div>

          {categories.length > 0 && (
            <div className={this.state.displayFilters ? 'hc-filters-modal' : ' hidden'}>
              <img
                alt="close"
                className="close"
                onClick={() => {
                  this.setState({ displayFilters: !this.state.displayFilters })
                }}
                src={require('../../images/icons/x.svg')}
              />
              <span>Filtros</span>
              <ul>{categories}</ul>
            </div>
          )}
        </div>

        <div className="hc-content">{content}</div>
      </div>
    )
  }
  setFilter(target) {
    if (!target) {
      return false
    }

    let filters = this.state.filters

    if (target.checked) {
      filters.push(target.value)
    } else {
      filters.splice(filters.indexOf(target.value), 1)
    }

    this.setState({ filters })
  }
}

export default HelpCenterList
