import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import InsuranceNames from '../../../components/ui/InsuranceNames'

const userId = localStorage.getItem('userId_wallet')
export const AdvisorHeading = {
  bike: 'Bike',
  car: 'Car',
  bicycle: 'Bicycle',
  scooter: 'Scooter',
  home: 'Home',
  content: 'Content',
  mortgage: 'Mortgage',
  life: 'Life',
  housekeeper: 'Housekeeper',
  health: 'Health',
  dental: 'Dental',
  criticalIllness: 'Critical Illness',
  hospitalization: 'Hospitalization',
  personalAccident: 'Accident',
  sports: 'Sports',
  child: 'Child',
  family: 'Family',
  drone: 'Drone',
  workAccidentSelfEmployeed: 'Work Accident Independent',
  workAccidentEmployee: 'Work Accident Company',
  investment: 'Investment',
  ppr: 'PPR',
  thirdPartyProfessional: 'Third Party Professional',
  pet: 'Pet',
  thirdPartyPet: 'Third Party Pet'

  // buildings: 'Buildings',
  // lifeCredit: 'Life Credit',
  // civilResponsability: 'Civil Responsability',
  // extraPet: 'Extra Pet',
  // contents: 'Contents',
  // equipment: 'Equipment',
}

export const AdvisorJourneyLinks = {
  // bike: `${process.env.REACT_APP_HP_URL}/seguro-bicicleta-simulacao/?email=`,
  car: `${process.env.REACT_APP_MD_URL}seguro-carro/simulacao?overview=${userId}`,
  bicycle: 'https://mudey.pt/produtos/seguro-bicicleta/seguro-bicicleta-simulacao/',
  buildings: `${process.env.REACT_APP_MD_URL}seguro-casa/simulacao`,
  home: `${process.env.REACT_APP_MD_URL}seguro-casa/simulacao`,
  housekeeper: `${process.env.REACT_APP_MD_URL}seguro-empregada/simulacao?overview=${userId}`,
  scooter: `${process.env.REACT_APP_HP_URL}/seguro-trotinete/simulador?email=`,
  health: `${process.env.REACT_APP_MD_URL}seguro-saude/simulacao`,
  personalAccident: `${process.env.REACT_APP_MD_URL}seguro-acidentes-pessoais/simulacao`,
  workAccidentSelfEmployeed: `${process.env.REACT_APP_MD_URL}seguro-acidentes-trabalho-independente/simulacao?overview=${userId}`,
  life: `${process.env.REACT_APP_MD_URL}seguro-vida/simulacao?overview=${userId}`,
  child: `${process.env.REACT_APP_HP_URL}/seguro-crianca/simulador`,
  drone: `${process.env.REACT_APP_HP_URL}/seguro-drone-simulacao/?email=`,
  mortgage: `${process.env.REACT_APP_MD_URL}seguro-vida-credito/simulacao?overview=${userId}`,
  dental: `${process.env.REACT_APP_HP_URL}/seguro-dental/simulacao`,
  criticalIllness: `${process.env.REACT_APP_MD_URL}doencas-graves/simulacao?overview=${userId}`,
  hospitalization: `${process.env.REACT_APP_MD_URL}hospitalização/simulacao`,
  family: 'https://mudey.pt/produtos/seguro-familia/simulador/',
  pet: `${process.env.REACT_APP_MD_URL}seguro-animais-domesticos/simulacao?overview=${userId}`,
  localLoding: `${process.env.REACT_APP_HP_URL}seguro-alojamento-local/simulacao?overview=${userId}`
}

export const ProductDetails = {
  car:
    'Como tens um automóvel, és obrigado(a) por lei a ter um seguro de responsabilidade civil automóvel',
  bike:
    'Como tens uma moto, és obrigado(a) por lei a ter um seguro de responsabilidade civil automóvel',
  mortgage: 'Se tens um crédito habitação, então o teu Banco exige um seguro de vida',
  buildings: 'Tens de ter pelo menos um seguro que cubra o risco de incêndio na tua casa',
  home: 'Tens de ter pelo menos um seguro que cubra o risco de incêndio na tua casa',
  housekeeper:
    'A lei obriga-te a ter um seguro de acidentes de trabalho para a tua empregada doméstica',
  drone:
    'Como o teu drone pesa mais de 900gr, a lei impõe que tenhas um seguro de responsabilidade civil',
  workAccidentSelfEmployeed: 'A lei obriga que estejas protegido em caso de acidente profissional',
  workAccidentEmployee:
    'A tua empresa tem de garantir um seguro de acidentes de trabalho para os teus colaboradores',
  thirdPartyPet: 'Por lei, tens de ter um seguro de responsabilidade civil para o teu cão/cadela',
  thirdPartyProfessional:
    'Para a tua profissão a lei impõe que tenhas um seguro de responsabilidade civil profissional'
}

export const Card = ({ items, showMoreInfo, showPriceAdvisor, showDesc }) => {
  const user = useSelector(state => state.user_data)

  const openInNewTab = key => {
    // console.log("KEY: ",key);
    if (
      ![
        'car',
        'buildings',
        'housekeeper',
        'scooter',
        'health',
        'personalAccident',
        'workAccidentSelfEmployeed',
        'child',
        'drone',
        'mortgage',
        'dental',
        'criticalIllness',
        'hospitalization',
        'family',
        'home',
        'life',
        'pet'
      ].includes(key)
    ) {
      showPriceAdvisor(key)
    } else {
      if (key === 'child') {
        //location.href = `${AdvisorJourneyLinks[key]}?${user.firstName}&email=${user.email}`
        const newWindow = window.open(
          `${AdvisorJourneyLinks[key]}?${user.firstName}&email=${user.email}`,
          '_blank',
          'noopener,noreferrer'
        )
        if (newWindow) newWindow.opener = null
      } else if (['bike', 'scooter', 'drone'].includes(key)) {
        // window.location.href = `${AdvisorJourneyLinks[key]}${user.email}`
        const newWindow = window.open(
          `${AdvisorJourneyLinks[key]}${user.email}`,
          '_blank',
          'noopener,noreferrer'
        )
        if (newWindow) newWindow.opener = null
      } else {
        //window.location.href = AdvisorJourneyLinks[key]
        const newWindow = window.open(AdvisorJourneyLinks[key], '_blank', 'noopener, noreferrer')
        if (newWindow) newWindow.opener = null
      }
    }
  }

  return (
    <>
      {Object.keys(items).length > 0 &&
        Object.keys(items).map(function(key) {
          let icon = key.charAt(0).toLocaleUpperCase() + key.slice(1).toLocaleLowerCase()
          if (icon === 'Personalaccident') {
            icon = 'Accident'
          } else if (icon === 'Bike') {
            icon = 'Motorbike'
          } else if (['Civilresponsability', 'Thirdpartyprofessional'].includes(icon)) {
            icon = '3rdParty'
          } else if (icon == 'Hospitalization') {
            icon = 'Hospitalização'
          } else if (icon === 'Home') {
            icon = 'Buildings'
          } else if (icon === 'Criticalillness') {
            icon = 'CriticalIllness'
          } else if (icon === 'PPR' || icon == 'Ppr' || icon == 'ppr') {
            icon = 'PPR'
          } else if (icon === 'Content') {
            icon = 'Contents'
          } else if (['Workaccidentselfemployeed', 'Workaccidentemployee'].includes(icon)) {
            icon = 'WorkAccident'
          }

          // onClick={() =>  {
          //   if (
          //     ![
          //       'bike',
          //       'car',
          //       'buildings',
          //       'housekeeper',
          //       'scooter',
          //       'health',
          //       'personalAccident',
          //       'child',
          //       'drone',
          //       'mortgage',
          //       'dental',
          //       'criticalIllness',
          //       'hospitalization',
          //       'family'
          //     ].includes(key)
          //   ) {
          //     showPriceAdvisor(key)
          //   } else {
          //     if (key === 'child') {
          //       window.location.href = `${AdvisorJourneyLinks[key]}?${user.firstName}&email=${user.email}`
          //     } else if (['bike', 'scooter', 'drone'].includes(key)) {
          //       window.location.href = `${AdvisorJourneyLinks[key]}${user.email}`
          //     } else {
          //       window.location.href = AdvisorJourneyLinks[key]
          //     }
          //   }
          // }}

          return (
            items[key] == 1 && (
              <div className="card" key={icon}>
                <div className="cardImageWrapper">
                  {/* {[''].includes(icon) && (
                    <img
                      src={require(`../../../images/types/purple/${icon}.png`)}
                      className="cardImage"
                    />
                  )} */}
                  <img
                    src={require(`../../../images/types/purple/${icon}.svg`)}
                    className="cardImage"
                  />
                  <p className="card-title">
                    <strong>{new InsuranceNames(key.toUpperCase()).getName()}</strong>
                  </p>
                  {showDesc
                    ? Object.keys(ProductDetails).includes(key) && (
                        <p className="description">{ProductDetails[key]}</p>
                      )
                    : null}
                </div>
                <div className="button-wrapper">
                  <button className="quote-button" onClick={() => openInNewTab(key)}>
                    Obter cotação
                  </button>
                  <button className="more-info-button" onClick={() => showMoreInfo(key)}>
                    Mais informações
                  </button>
                </div>
              </div>
            )
          )
        })}
    </>
  )
}

Card.propTypes = {
  items: PropTypes.any.isRequired,
  showMoreInfo: PropTypes.func.isRequired,
  showPriceAdvisor: PropTypes.func.isRequired,
  showDesc: PropTypes.any.isRequired
}
