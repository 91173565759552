// @flow
//import Cookies from 'js-cookie'
import * as actionTypes from './actions'
//const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'

const initialState = {
  isLoading: false,
  user_data: null,
  recommended: null,
  mandatory: null,
  consider: null,
  advisorSurveyID: null,
  isPhoneValidAndVerified: false,
  phone: null
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        user_data: payload
      }
    // case actionTypes.SET_USER_UUID:
    //   return {
    //     ...state,
    //     user_uuid: payload
    //   }

    case actionTypes.RESET:
      return initialState

    case actionTypes.SET_RECOMMENDED:
      return {
        ...state,
        recommended: payload
      }
    case actionTypes.SET_MANDATORY:
      return {
        ...state,
        mandatory: payload
      }
    case actionTypes.SET_CONSIDER:
      return {
        ...state,
        consider: payload
      }
    case actionTypes.SET_ADVISOR_SURVEY_ID:
      return {
        ...state,
        advisorSurveyID: payload
      }

    case actionTypes.IS_PHONE_VALID_AND_VERIFIED:
      return {
        ...state,
        isPhoneValidAndVerified: payload
      }
    case actionTypes.SET_PHONE:
      return {
        ...state,
        phone: payload
      }
    default:
      return state
  }
}

export default reducer
