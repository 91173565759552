import { requiredKeysList } from './constants'

export const getProgressPercent = (data: any) => {
  const percents = Object.keys(data)
    .map((key: any) => {
      if (requiredKeysList.some((k: any) => key === k)) {
        return key
      }
      return null
    })
    .filter((k: any) => k)
    .map((key: any) => {
      return data[key] ? data[key] : null
    })
    .filter((k: any) => k).length

  return percents ? (percents * 100) / 20 : 0
}

export const defineColor = (progress: number) => {
  if (progress === 0) {
    return {
      color: '#881414',
      background: 'rgba(136, 20, 20, 0.3)'
    }
  } else if (progress > 0 && progress <= 50) {
    return {
      color: '#f7cb8b',
      background: 'rgba(247, 203, 139, 0.3)'
    }
  } else if (progress > 50 && progress < 100) {
    return {
      color: '#895b85',
      background: 'rgba(137, 91, 133, 0.3)'
    }
  } else {
    return {
      color: '#accecd',
      background: 'rgba(172, 206, 205, 0.3)'
    }
  }
}
