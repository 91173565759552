import React from 'react'
import './inputs.less'

export const InputWrapper = (props: any) => {
  const {
    formik,
    pClassName,
    question,
    name,
    hideQuestion,
    labelText,
    tabTextStyle,
    style
  } = props
  const error = _.get(formik.errors, name)
  const touched = _.get(formik.touched, name)

  return (
    <div
      className={`${error && touched ? 'error-div-active' : ''} div-wrapping-component`}
      name={name}
      style={{
        ...style,
        marginTop: !question && !labelText ? '0' : '',
        marginBottom: !question && !labelText ? '0' : ''
      }}>
      {!hideQuestion && question && (
        <p className={`${pClassName ? pClassName : 'tab-text'}`} style={tabTextStyle}>
          {question}
        </p>
      )}
      {props.children}
      {error && touched && (
        <div className="formik-errors" style={{ color: 'red' }}>
          {error}
        </div>
      )}
    </div>
  )
}
