import PropTypes from 'prop-types'
import React from 'react'

class Home extends React.Component {
  componentDidMount() {
    this.props.history.push('/seguros')
  }

  render() {
    return <h1>Home</h1>
  }
}

Home.propTypes = {
  history: PropTypes.any.isRequired
}

export default Home
