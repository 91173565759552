import Layout from '../layout'
import Ql from '../../api/ql'
import apollo from '../../../src/api/client'
import { gql } from 'apollo-boost-upload'
import { Notification } from 'element-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import PartnersHeader from '../../../src/components/utils/partners-header'
import moment from 'moment'

class NotificationsSettings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      consents: {},
      toggle: true,
      marketingToggle: false,
    }

    this.getStates = this.getStates.bind(this)
    this.setStates = this.setStates.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    this.getStates()
  }

  getStates() {
    this.setState({ loading: true })
    new Ql('notifications')
      .get()
      .then(data => {
        this.setState({
          consents: data.data.userGet.consents,
          marketingToggle: data.data.userGet.consents.marketingConsent
        })
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    const { toggle, marketingToggle, consents } = this.state
    return (
      <Layout loading={this.state.loading}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{process.env.REACT_APP_NAME} | Definições</title>
        </Helmet>
        <div className="settings">
          <div className="header-partner-wrapper">
            <h1>Definições</h1>
            <PartnersHeader />
          </div>
          <h2>Políticas e Consentimentos</h2>

          <div className="switches">
            <div
              className="switch-object"
              onClick={() => {
                this.setState({ toggle: !toggle })
              }}>
              <span>
                Autorizo a MUDEY a tratar os meus dados para obter informações sobre seguros e
                gestão dos meus seguros
              </span>
              <div className={toggle ? 'switch on' : 'switch off'} />
            </div>
            <div
              className="switch-object"
              onClick={() => {
                this.setState({ marketingToggle: !marketingToggle })
              }}>
              <span>
                Autorizo a MUDEY a enviar-me ações promocionais, passatempos e newsletters.
              </span>
              <div className={marketingToggle ? 'switch on' : 'switch off'} />
            </div>
            <div className={toggle ? 'hide' : 'toggled-text'}>
              <span>
                Para poderes usar a Wallet MUDEY, temos de ter este consentimento. Se quiseres
                retirar este consentimento, envia-nos um email para bemvindos@mudey.pt.
              </span>
              <div
                className="btn button-solid"
                onClick={() => {
                  this.setState({ toggle: !toggle })
                }}>
                Compreendi
              </div>
            </div>
            <div
              className={toggle && marketingToggle !== consents.marketingConsent ? 'button-solid savebtn' : 'hide'} style={{ width: '180px' }}
              onClick={() => { this.onSubmit() }}>
              Guardar Alterações
            </div>
          </div>
          <div>
            <p>
              Política de Privacidade -{' '}
              <a
                href={process.env.REACT_APP_HP_URL + '/politica-de-privacidade/'}
                rel="noopener noreferrer"
                target="_blank">
                Clica aqui
              </a>
            </p>
            <p>
              Ficha Informativa-{' '}
              <a
                href="https://files.mudey.pt/files/ficha_informativa.pdf"
                rel="noopener noreferrer"
                target="_blank">
                Clica aqui
              </a>
            </p>
            <p>
              Termos de Utilização do Website -{' '}
              <a
                href={process.env.REACT_APP_HP_URL + '/termos-de-utilizacao/'}
                rel="noopener noreferrer"
                target="_blank">
                Clica aqui
              </a>{' '}
            </p>
          </div>
        </div>
      </Layout >
    )
  }
  setStates(field, value) {
    this.setState({
      [field]: value
    })

    this.setState({ loading: true })
    new Ql('notifications').set({ field, value }).finally(() => this.setState({ loading: false }))
  }


  async onSubmit() {
    const { consents, marketingToggle } = this.state
    const newData = {
      marketingConsent: marketingToggle,
      updatedAt: moment().format()
    };
    const newConsents = { ...consents, ...newData }
    delete newConsents.__typename
    try {
      await apollo.mutate({
        variables: { consents: newConsents },
        mutation: gql`
            mutation userUpdate(
              $consents: UserConsentsInput
            ) {
              userUpdate(
                input: {
                  consents: $consents
                }
              ) {
                uuid
                consents {
                  marketingConsent
                  updatedAt
                }
              }
            }
          `
      })
      this.setState({ loading: false })
      Notification.success({
        title: 'Sucesso!',
        message: 'Guardado'
      })
      this.getStates()
    } catch (err) {
      this.setState({ loading: false })
      if (err) {
        Notification.failed({
          title: 'Erro!',
          message: 'Tente novamente'
        })
      }
    }
  }
}

export default NotificationsSettings
