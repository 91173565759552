import React, {useState, useEffect} from 'react'
import { Progress } from 'antd';

const Loading = () => {
  const [load, setLoad] = useState(0)
  useEffect(() => {
    if(load!== 100) {
      setTimeout(() => {
        setLoad(load + 25)
      }, 1000)
    }
  }, [load])
  return (
    <>
      <Progress 
      strokeLinecap="square" 
      strokeColor="orange" 
      trailColor="darkgray"
      percent={load} 
      style={{background: "#895d85",}}
      />
    </>
  )
}

export default Loading
