// @flow
import React from 'react'
import { Spin } from 'antd'
import Icons from '../../../../components/ui/Icons'
import InsuranceNames from '../../../../components/ui/InsuranceNames'
import { useHistory } from 'react-router-dom'
import './styles.less'

export const AdvicesList = ({ children, loading }: any) => {
  if (!loading) {
    return (
      <div className="advisor-wrapper_" style={{ marginTop: 0 }}>
        <div className="mandatory">
          <div className="wrapper_">{children}</div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="policy-list">
        <Spin size="large" />
      </div>
    )
  }
}

export const AdvicesItem = ({ type, advice_type }: any) => {
  const history = useHistory()
  const colors = {
    mandatory: 'rgba(137, 91, 133, 0.8)',
    recommended: 'rgba(172, 206, 205, 0.8)',
    consider: 'rgba(247, 203, 139, 0.8)'
  }

  return (
    <div
      onClick={() => history.push('/consultor')}
      className="results-options_"
      style={{ backgroundColor: colors[advice_type] }}>
      <div className="icon-wrapper">
        <img alt="" src={new Icons(type).getWhite()} />
        <p className="insurance-name">{new InsuranceNames(type.toUpperCase()).getName()}</p>
      </div>
    </div>
  )
}
