import React, { useState, useEffect } from 'react'
import Select,{StylesConfig} from 'react-select'
import PropTypes from 'prop-types'
// import { Input } from 'antd'
// import Form from '../../../../../components/ui/Form'
import moment from 'moment'
import Ql from '../../../../../api/ql'
import { SPORTS } from '../utils/sports'
import SingleTabSelect from './SingleTabSelect'

// import '../../../../../common.less'
// **************** UTILS ****************
// import getProvider from '../../../../../utils/getProviders'

//for select options
// import { Select } from 'antd'
import MaskedInput from 'react-text-mask'
// const { Option } = Select

// This is the topmost <select component which is used only once in this section
export default function ChildComponent({
  child,
  onChildSelect,
  defaultValues,
  isError,
  formValues
}) {
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  // const [professionList, setProfessionList] = useState(null)

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles: any, {  isFocused, isSelected }: any) => ({
      ...styles, 
      backgroundColor: 'white',
      border:isFocused || isSelected?'1px solid #d9d9d9':'1px solid #d9d9d9',
      borderColor:"#967e94",
      boxShadow:isFocused || isSelected?"0 0 0 2px rgba(137,93,133,.2)":"",
      width:300,
   // boxShadow: 'none',
  
   '&:hover':{
     border:'1px solid #d9d9d9',
     cursor:'pointer',
   }
  }),
    option: (styles: any, { isFocused, isSelected }: any) => {
      
      return {
        ...styles,
        backgroundColor: isFocused ? "#ccbccb" : isSelected?"#ac72a7":null,
        color: "#333333"
      };
    },
    indicatorSeparator:(defaultStyles: any) => {
      return {
          ...defaultStyles,
          display:'none',
      } 
  },
  container: base => ({
    ...base,
    width:300
  }),
}
  

  useEffect(() => {
    if (defaultValues && defaultValues[child.uuid]) {
      if (child.questionType == 'DATE') {
        setSelectedOption(
          typeof defaultValues[child.uuid] == 'object'
            ? defaultValues[child.uuid][0]
            : defaultValues[child.uuid]
        )
      } else if (defaultValues[child.uuid][0]) {
        // setSelectedOption({
        //   label: defaultValues[child.uuid][0],
        //   value: defaultValues[child.uuid][0]
        // })
        setSelectedOption(defaultValues[child.uuid][0])
      }
    }
  }, [defaultValues])

  useEffect(() => {
    //let options = []

    if (child) {
      if (child.uuid == 'c957a6dd-456d-42a3-b9c9-2c57560f9d64') {
        // for (let i = 0; i < SPORTS.length; i++) {
        //   let obj = {}
        //   obj.label = SPORTS[i].sports
        //   obj.value = SPORTS[i].sports
        //   options.push(obj)
        // }

        setOptions(
          SPORTS.map(item => {
            const obj = {}
            obj.label = item.sports
            obj.value = item.sports
            return {
              label: obj.label,
              value: obj.value
            }
          })
        )
      } else if (child.uuid == 'ce13d919-0f05-4568-a34b-b6f6cc4fbcff') {
        new Ql('getProfessions')
          .get()
          .then(response => {
            // setProfessionList(response.data.getProfessions.professions)
            // for (var p of response.data.getProfessions.professions) {
            //   let obj = {}
            //   obj.label = p
            //   obj.value = p
            //   options.push(obj)
            // }

            setOptions(
              response.data.getProfessions.professions.map(item => {
                const obj = {}
                obj.label = item
                obj.value = item
                return {
                  label: obj.label,
                  value: obj.value
                }
              })
            )
          })
          .finally(() => {})
      } else {
        let choices = child.choice
        // for (let i = 0; i < choices.length; i++) {
        //   let obj = {}
        //   obj.label = choices[i]
        //   obj.value = choices[i]
        //   options.push(obj)
        // }

        setOptions(
          choices.map(item => {
            const obj = {}
            obj.label = item
            obj.value = item
            return {
              label: obj.label,
              value: obj.value
            }
          })
        )
      }

      //setOptions(options)
    }
  }, [child])

  const handleChange = (val, quuid) => {
    setSelectedOption(val)
    onChildSelect(val, quuid)
  }

  // useEffect(() => {
  //   new Ql('getProfessions')
  //     .get()
  //     .then(response => {
  //       setProfessionList(response.data.getProfessions.professions)
  //     })
  //     .finally(() => {})
  // }, [])

  if (child && child.questionType == 'SINGLE_SELECT') {
    return (
      <div
        className={
          isError == true && (formValues[child.uuid] == null || formValues[child.uuid] == '')
            ? ' input-error'
            : ''
        }>
        <p>{child.question}</p>
        <SingleTabSelect
          onSelect={value => {
            handleChange(value, child.uuid)
          }}
          onChildInput={() => {
            console.log('child -> single tab -> child input change')
          }}
          // onChildInput={(value, quuid) => {
          //   updateFormValues(value, quuid)
          // }}
          defaultValue={defaultValues}
          // isError={isError}
          item={child}
        />

        {/* Error Message */}
        <p className="error-text">
          {isError == true && (formValues[child.uuid] == null || formValues[child.uuid] == '')
            ? 'Tens de preencher este campo'
            : ''}
        </p>
        {/* <Form
          data={options.map(o => {
            return { name: o.label }
          })}
          onValueChange={v => {
            handleChange(v, child.uuid)
          }}
          type="select"
          value={selectedOption}
          // defaultValue={selectedOption}
          className="select-item-list half-width-input"
        /> */}
        {/* <Select className="select-item-list half-width-input" options={options}/> */}
      </div>
    )
  } else if (child && child.questionType == 'LIST') {
    return (
      <div
        className={
          isError == true && (formValues[child.uuid] == null || formValues[child.uuid] == '')
            ? ' input-error'
            : ''
        }>
        <p>{child.question}</p>
        {/* <Form
          data={options.map(o => {
            return { name: o.label }
          })}
          onValueChange={v => {
            handleChange(v, child.uuid)
          }}
          type="select"
          value={selectedOption}
          // defaultValue={selectedOption}
          className="select-item-list half-width-input"
        /> */}

        {/* <Select
          // style={{ width: "fit-content" }}
          placeholder="search to select"
          showSearch
          onChange={v => {
            handleChange(v, child.uuid)
          }}
          optionFilterProp="children"
          // filterOption={filterOptions}
          //defaultValue={selectedOption}
          value={selectedOption}>
          {options.map((option, index) => {
            return (
              <Option value={option.value} key={index}>
                {option.label}
              </Option>
            )
          })}
        </Select> */}
        <Select
          optionFilterProp="children"
          placeholder="search to select"
          options={options}
          onChange={v => {
            handleChange(v.value, child.uuid)
          }}
          styles={colourStyles}
        />

        {/* Error Message */}
        <p className="error-text">
          {isError == true && (formValues[child.uuid] == null || formValues[child.uuid] == '')
            ? 'Tens de preencher este campo'
            : ''}
        </p>
      </div>
    )
  } else if (child && child.questionType == 'DATE') {
    return (
      <div
        className={
          isError == true && (formValues[child.uuid] == null || formValues[child.uuid] == '')
            ? ' input-error'
            : ''
        }>
        <p>{child.question}</p>
        <MaskedInput
          className="input-md"
          defaultValue={selectedOption}
          value={selectedOption}
          guide={false}
          mask={[/[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
          name="bdate"
          // onBlur={e => {
          //   console.log('on blur', e.target.value)
          //   const value = moment(e.target.value, 'DD-MM-YYYY')
          //   if (moment().diff(moment(value), 'years') < 18 && e.target.value.length == 10) {
          //     handleChange(null, child.uuid)
          //   }
          // }}
          onChange={e => {
            if (e.target.value.length == 10) {
              const value = moment(e.target.value, 'DD-MM-YYYY')
              if (moment().diff(moment(value), 'years') < 18) {
                handleChange(null, child.uuid)
              } else {
                handleChange(e.target.value, child.uuid)
              }
            }
          }}
          placeholder={['dd-mm-yyyy']}
        />
        {/* <Form
          onValueChange={v => {
            handleChange(v, child.uuid)
          }}
          type="birth-date"
          defaultValue={selectedOption}
          value={selectedOption}
        /> */}
        {/* Error Message */}
        <p className="error-text">
          {isError == true && (formValues[child.uuid] == null || formValues[child.uuid] == '')
            ? 'Tens de preencher este campo'
            : ''}
        </p>
      </div>
    )
  } else return null
}

ChildComponent.propTypes = {
  child: PropTypes.any,
  onChildSelect: PropTypes.func,
  defaultValues: PropTypes.any,
  formValues: PropTypes.any,
  isError: PropTypes.any
}
