import React from 'react'
import { Card } from './card'
import PropTypes from 'prop-types'

export const AllCards = ({
  showMoreInfo,
  showPriceAdvisor,
  Heading,
  subHeading,
  items,
  showDescription
}) => {
  return (
    <div className={showDescription ? 'product' : 'product-rest'}>
      <h2>{Heading}</h2>
      <p>{subHeading}</p>
      <div className="card-wrapper">
        <Card
          items={items}
          showPriceAdvisor={type => showPriceAdvisor(type)}
          showMoreInfo={type => showMoreInfo(type)}
          showDesc={showDescription}
        />
      </div>
      <div className="arrow-mb">
        {Object.values(items).filter(i => i == 1).length > 1 && (
          <span>
            Desliza para a <span>direita</span> &gt;
          </span>
        )}
      </div>
    </div>
  )
}

AllCards.propTypes = {
  showMoreInfo: PropTypes.func.isRequired,
  showPriceAdvisor: PropTypes.func.isRequired,
  Heading: PropTypes.any.isRequired,
  subHeading: PropTypes.any.isRequired,
  items: PropTypes.any.isRequired,
  card: PropTypes.any.isRequired,
  showDescription: PropTypes.any.isRequired
}
