import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Spin } from 'antd'
import Icons from '../../../../components/ui/Icons'
import InsuranceNames from '../../../../components/ui/InsuranceNames'
import { redirectToApp } from '../../../../components/utils/redirectToApp'
import { translateApiValue } from '../../../../components/utils'

import './styles.less'

export const PolicyList = ({ children, loading, isMobile }: any) => {
  if (!loading) {
    return !isMobile ? (
      <div className="policy-list-container">{children}</div>
    ) : (
      <div className="policy-list-container_mobile">{children}</div>
    )
  }

  if (loading) {
    return (
      <div className="policy-list">
        <Spin size="large" />
      </div>
    )
  }
}

export const PolicyItem = ({
  id,
  renewalDate,
  premiumType,
  premium,
  insuranceType,
  insuranceBrand,
  status,
  isMobile,
  packageName
}: any) => {
  const history = useHistory()
  const imgUrl = new Icons(insuranceType.toUpperCase()).getPurple()
  const [borderColor, setBorderColor] = useState('#895b85')

  const [policyExpirationStatus, setPolicyExpirationStatus] = useState(status)

  useEffect(() => {
    const today = moment()
    const date = moment(renewalDate)
    const difference = date.diff(today, 'days')

    if (difference < 30 && difference >= 0 && insuranceType != 'TRAVEL') {
      setBorderColor('#f7cb8b')
      setPolicyExpirationStatus('expiring')
    }

    if (difference < 0) {
      setBorderColor('#881414')
      setPolicyExpirationStatus('expired')
    }
  }, [])

  const type = insuranceType.toUpperCase()
  const linkTo = `/seguros/${type.toLowerCase()}/${id}`
  const name = new InsuranceNames(type).getName()
  const brand = insuranceBrand ? insuranceBrand.toLowerCase() : ''
  const redirectType = redirectToApp(insuranceType)

  const redirectOnExpire = () => {
    if (redirectType === 'typeform') {
      history.push('/seguros')
    }

    window.location.href = redirectType
  }

  if (!isMobile) {
    return (
      <div style={{ border: `2px solid ${borderColor}` }} className="policy-list-item">
        <div style={{ justifyContent: 'flex-start' }} className="policy-list-item_cell">
          <div
            className="insurance-type_icon"
            style={{
              backgroundImage: `url(${imgUrl})`,
              backgroundSize: insuranceType == 'LOCALLODGING' ? '40px' : ''
            }}
          />
          <div className="insurance-type_text">{name} </div>
        </div>
        <div className="policy-list-item_cell">
          <div className="brand_container">
            <span>Seguradora</span>
            <span className="price_container_text">{brand}</span>
          </div>
        </div>
        <div className="policy-list-item_cell">
          <div className="brand_container">
            <span>{insuranceType == 'TRAVEL' ? 'Produto' : 'Tipo Pagamento'}</span>
            <span className="price_container_text">
              {insuranceType == 'TRAVEL'
                ? packageName
                : translateApiValue(_.startCase(_.toLower(premiumType)), 'ALL', 'PAYMENT')}
            </span>
          </div>
        </div>

        <div className="policy-list-item_cell">
          <div className="price_container">
            <span>Prémio Seguro</span>
            <span className="price_container_text">€ {premium}</span>
          </div>
        </div>
        <div style={{ flexDirection: 'column' }} className="policy-list-item_cell">
          <div className="data-title">
            {insuranceType.toUpperCase() == 'TRAVEL' ? 'Data Fim' : 'Data Renovação'}
          </div>
          <div className="data-value">{renewalDate}</div>
        </div>

        <div
          className="policy-list-item_cell"
          style={{ flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
          {policyExpirationStatus === 'PENDING' && (
            <div className="_pending">
              <span>Ainda não está ativo!</span>
            </div>
          )}

          {policyExpirationStatus === 'expiring' && insuranceType != 'TRAVEL' && (
            <div className="__expiring">
              <img alt="refresh" src={require('../../../../images/icons/refresh_black.svg')} />
              <span>Expira em breve</span>
            </div>
          )}

          {status == 'ENDED' && (
            <div className="__expired">
              <img alt="buy" src={require('../../../../images/icons/buy.svg')} />
              <span>Este seguro já terminou</span>
            </div>
          )}
          <div className="_buttons-container">
            {policyExpirationStatus === 'expiring' &&
              insuranceType != 'TRAVEL' &&
              insuranceType != 'CAR' && (
                <div className="_refresh" onClick={redirectOnExpire}>
                  <span>Renovar seguro</span>
                </div>
              )}

            {policyExpirationStatus === 'expired' && insuranceType != 'CAR' && (
              <div className="_expired" onClick={redirectOnExpire}>
                <span>Comprar seguro</span>
              </div>
            )}

            <div onClick={() => history.push(linkTo)} className="_clickable">
              <span>Mais info</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="policy-list-item_mobile">
      <div className="policy-list-item_cell_mobile" style={{ flexDirection: `row` }}>
        <span
          className="insurance-type_icon_mobile"
          style={{
            backgroundImage: `url(${imgUrl})`,
            backgroundSize: insuranceType == 'LOCALLODGING' ? '45px' : ''
          }}
        />
        <span className="insurance-type_text_mobile">{name} </span>
      </div>

      <div className="policy-list-item_cell_mobile">
        <span className="data-title_mobile">Seguradora</span>
        <span className="data-value_mobile">{brand}</span>
      </div>

      <div className="policy-list-item_cell_mobile">
        <span className="data-title_mobile">Prémio Seguro</span>
        <span className="data-value_mobile">€ {premium}</span>
      </div>

      <div className="policy-list-item_cell_mobile">
        <div className="data-title_mobile">
          {insuranceType.toUpperCase() == 'TRAVEL' ? 'Data Fim' : 'Data Renovação'}
        </div>
        <div className="data-value_mobile">{renewalDate}</div>
      </div>

      <div onClick={() => history.push(linkTo)} className="policy-list-item_cell_mobile">
        <div className="clickable_mobile">
          <span>Mas Info</span>
        </div>
      </div>
    </div>
  )
}
