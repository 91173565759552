// @flow
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USER_UUID = 'SET_USER_UUID'

export const RESET = 'RESET'

export const SET_RECOMMENDED = 'SET_RECOMMENDED'
export const SET_MANDATORY = 'SET_MANDATORY'
export const SET_CONSIDER = 'SET_CONSIDER'
export const SET_ADVISOR_SURVEY_ID = 'SET_ADVISOR_SURVEY_ID'
export const IS_PHONE_VALID_AND_VERIFIED='IS_PHONE_VALID_AND_VERIFIED'
export const SET_PHONE="SET_PHONE"
