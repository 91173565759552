/* eslint-disable react/prop-types */
import React from 'react'
import MaskedInput from 'react-text-mask'

import moment from 'moment'
import _ from 'lodash'

import CustomReactSelect from '../custom-react-select'
import Ql from '../../api/ql'
import Form from './Form'
import './animation.scss'

class FormBuilder extends React.Component {
  constructor(props) {
    super(props)

    this.obj = ''

    this.setObject = this.setObject.bind(this)
    this.setFormData = this.setFormData.bind(this)
    this.setFile = this.setFile.bind(this)
    this.unsetFile = this.unsetFile.bind(this)
    this.pickEquivalent = this.pickEquivalent.bind(this)
    this.CarInsurance = this.CarInsurance.bind(this)
    this.BikeInsurance = this.BikeInsurance.bind(this)
    this.HomeInsurance = this.HomeInsurance.bind(this)
    this.LifeInsurance = this.LifeInsurance.bind(this)
    this.MortgageInsurance = this.MortgageInsurance.bind(this)
    this.PersonalAccidentInsurance = this.PersonalAccidentInsurance.bind(this)
    this.PetInsurance = this.PetInsurance.bind(this)
    this.TravelInsurance = this.TravelInsurance.bind(this)
    this.OtherInsurance = this.OtherInsurance.bind(this)
    this.UserSettings = this.UserSettings.bind(this)
    this.UserView = this.UserView.bind(this)

    this.state = {
      extraFiles: [],
      extraFilesRender: '',
      customVariable1: null,
      customVariable2: null,
      customVariable3: null,
      customVariableType: null,
      personAmountDental: null,
      predefinedInput: null,
      isBlocking: true,
      filesList: null,
      carBrand: '',
      carRegisterDate: '',
      carVersions: [],
      loading: false,
      userLicenseDate: '',
      userNif: '',
      userPostcode: '',
      userBirthDate: '',
      showError: false,
      personsBirthdateArray: [],
      plateNumberForapi: '',
      carModelName: '',
      warrantyPvp: '',
      warrantyModel: '',
      warrantyBrand: '',
      warrantyPurchaseDate: '',
      // carVersionValue: null,
    }

    this.formData = new Map()
    this.files = new Map()

    if (props.data) {
      this.formData = new Map(Object.entries(props.data))
    }
console.log("--->type: ",props.type)
    switch (props.type) {
      case 'CarInsurance':
        this.setObject(this.CarInsurance)
        break
      case 'BikeInsurance':
        this.setObject(this.BikeInsurance)
        break
      case 'HomeInsurance':
        this.setObject(this.HomeInsurance)
        break
      case 'LifeInsurance':
        this.setObject(this.LifeInsurance)
        break
      case 'MortgageInsurance':
        this.setObject(this.MortgageInsurance)
        break
      case 'PersonalAccidentInsurance':
        this.setObject(this.PersonalAccidentInsurance)
        break
      case 'PetInsurance':
        this.setObject(this.PetInsurance)
        break
      case 'TravelInsurance':
        this.setObject(this.TravelInsurance)
        break
      case 'OtherInsurance':
        this.setObject(this.OtherInsurance)
        break
      case 'UserSettings':
        this.setObject(this.UserSettings)
        break
      case 'UserView':
        this.setObject(this.UserView)
        break
      default:
        this.setObject(this.OtherInsurance)
    }
  }

  BikeInsurance() {
    let files = []

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual o tipo de seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'ALLRISKS' }, { name: 'THIRDPARTIES' }]}
                    id={'tipo' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <div className="checkbox-wrapper">
                  <Form
                    id={'seguradora' + this.props.index}
                    onBlockStateChange={v => {
                      this.props.onBlockStateChange(v)
                      this.setState({ isBlocking: v })
                    }}
                    onValueChange={v => {
                      this.setFormData('insuranceBrand', v)
                    }}
                    type="select-nao"
                  />
                </div>
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'apolice' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'tipo' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="text"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable2.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valor' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'renovacao' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node width-correction">
                <span>Qual a matricula da moto?</span>
                <Form
                  id={'matricula' + this.props.index}
                  onValueChange={v => {
                    this.setFormData('plateNumber', v)
                  }}
                  type="plate"
                />
              </div>
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  CarInsurance() {
    let files = []
    const { carBrand, showError, carVersions, carModelName } = this.state

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual o tipo de seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'ALLRISKS' }, { name: 'THIRDPARTIES' }]}
                    id={'tipo' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-nao"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>

              <div className="form-node">
                <span>O que inclui o teu seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'TRAVEL_ASSISTANCE' },
                      { name: 'BROKEN_GLASS' },
                      { name: 'THEFT' },
                      { name: 'CAR_REPLACEMENT' },
                      { name: 'NEW_VALUE' },
                      { name: 'LEGAL' },
                      { name: 'PASSENGERS' },
                      { name: 'DRIVERS' }
                    ]}
                    id={'tipo' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('additionalCover', v)
                    }}
                    type="multiple-checkbox"
                  />
                </div>
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'numero' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'apolice' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="text"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable2.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valor' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'renovacao' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              {/* change this for new Masked input */}
              <div className="form-node width-correction">
                <span>Qual a matricula do carro?</span>
                <MaskedInput
                  className="input-md"
                  guide={false}
                  placeholder="xx-xx-xx"
                  mask={[
                    /[0-9a-zA-Z]/,
                    /[0-9a-zA-Z]/,
                    '-',
                    /[0-9a-zA-Z]/,
                    /[0-9a-zA-Z]/,
                    '-',
                    /[0-9a-zA-Z]/,
                    /[0-9a-zA-Z]/
                  ]}
                  id={'matricula' + this.props.index}
                  onChange={v => {
                    if (v.target.value.length == 8) {
                      this.setFormData('plateNumber', v.target.value)
                      this.getInfoFromPlate(v.target.value)
                    }
                  }}
                  type="plate"
                />
              </div>
           <div className="form-node ">
                <span>Qual o modelo ?</span>
                {carVersions.length > 0 ? (
                  <CustomReactSelect
                  placeholder="Seleciona"
                    options={carVersions}
                    onChange={(e: any) => {
                      this.setState(
                        {
                          carRegisterDate: e.date
                        },
                        // () => console.log(this.state.carRegisterDate)
                      )
                      this.setFormData('model', e.label)
                      if (this.state.plateNumberForapi != '') {
                        this.getCarModel(this.state.plateNumberForapi, e.value)
                      }
                    }}
                  />
                ) : this.state.loading === false ? (
                  <span>Insere a tua matricula</span>
                ) : (
                  this.loadingAnimation()
                )}
              </div>
              <div className="form-node ">
                <span>A marca do carro:</span>
                {carBrand !== '' && this.setFormData('brand', carBrand)}
                {carBrand !== '' ? (
                  <span>{carBrand}</span>
                ) : this.state.loading === false ? (
                  <span>Insere a tua matricula</span>
                ) : (
                  this.loadingAnimation()
                )}
              </div>

              <div className="form-node ">
                <span>Modelo de carro:</span>
                {carModelName !== '' && this.setFormData('model', carModelName)}
                {carModelName !== '' ? (
                  <span>{carModelName}</span>
                ) : this.state.loading === false ? (
                  <span>Insere a tua matricula</span>
                ) : (
                  this.loadingAnimation()
                )}
              </div>

              <div className="form-node ">
                <span>Data de registo:</span>
                {this.state.carRegisterDate !== '' && this.setFormData('registerDate', this.state.carRegisterDate)}
                {this.state.carRegisterDate !== '' ? (
                  <span>{this.state.carRegisterDate}</span>
                ) : this.state.loading === false ? (
                  <span>Insere a tua matricula</span>
                ) : (
                  this.loadingAnimation()
                )}
              </div>
              <div className="form-node width-correction">
                <span>Número contribuinte do condutor</span>
                <Form
                  onValueChange={v => {
                    this.setFormData('vat', v)
                  }}
                  type="input-nif"
                  value={this.formData.get('vat')}
                />
              </div>
              <div className="form-node">
                <span>Data de nascimento do condutor</span>
                <Form
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setState({ userBirthDate: formatedValue })
                  }}
                  type="birth-date"
                  //value={userBirthDate ? userBirthDate : 'insert date'}
                />
              </div>
              <div className="form-node">
                <span>Data da carta de condução do condutor</span>
                <Form
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('licenseDate', formatedValue)
                  }}
                  type="license-date"
                  value={
                    this.formData.get('licenseDate') === 'Invalid date'
                      ? ''
                      : this.formData.get('licenseDate')
                  }
                />
                {showError && (
                  <span className="error-message">
                    Tens que ter mais de 18 anos para criar este seguro
                  </span>
                )}
              </div>
              <div className="form-node width-correction">
                <span>Qual o teu código postal?</span>
                <Form
                  id={'codigopostal' + this.props.index}
                  onValueChange={v => {
                    this.setFormData('postalCode', v)
                  }}
                  type="postCode"
                />
              </div>

              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  //compare birth date with license date
  compareDates() {
    const { userBirthDate, userLicenseDate, showError } = this.state

    var a = moment(`${userBirthDate}`, 'YYYY-M-D')
    var b = moment(`${userLicenseDate}`, 'YYYY-M-D')
    var diffDays = b.diff(a, 'years')
    if (diffDays < 18) {
      this.setState({ showError: !showError })
    } else if (diffDays >= 18) {
      this.setState({ showError: !showError })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userBirthDate, userLicenseDate } = this.state

    if (
      prevState.userBirthDate !== userBirthDate ||
      prevState.userLicenseDate !== userLicenseDate
    ) {
      this.setState({ showError: false })
      this.compareDates()
    }
  }

  //array needed for dental persons birthdate
  createBirthdateArray(v, i) {
    const { personsBirthdateArray } = this.state
    personsBirthdateArray.splice(i, 1, v)
    this.setFormData('personsBirthdate', personsBirthdateArray)
  }

  //extra field for mortgage insurance
  customField() {
    return (
      <div className="form-node">
        <span>Qual a percentagem de Invalidez?</span>
        <div className="checkbox-wrapper">
          <Form
          onValueChange={v => {
            this.setFormData('disablement', _.replace(v.toString(), ',', '.'))
          }}
          id={'numero' + this.props.index}
          type="text"
          />
        </div>
      </div>
    )
  }

  //get car/bike related information
  getInfoFromPlate(v) {
    if (v.length == 8) {
      this.setState({ loading: true })
      // if(this.state.carVersions.length >0){

      // }
      this.setState({
        ...this.state,
        carVersions: [],
        carRegisterDate: '',
        carBrand: '',
        carModelName: '',

        plateNumberForapi: v
      })

      new Ql('getCarVersions')
        .getCar(v, 'AUTOMATIC')
        .then(response => {
          // console.log(response.data.getCarVersions, 'getCarVersions')
          const data = response.data.getCarVersions
          if (data != undefined || data != null) {
            let results = response.data.getCarVersions
            let carVersion = []
            for (let i = 0; i < results.length; i++) {
              let obj = {}
              obj.value = results[i].typeCode
              obj.label = results[i].versionMatch
              ;(obj.date = `${results[i].plateRegDate.slice(6)}-${results[i].plateRegDate.slice(
                4,
                6
              )}-${results[i].plateRegDate.slice(0, 4)}`),
                carVersion.push(obj)
            }
            this.setState({
              ...this.state,
              carVersions: carVersion
            })
          }

          // this.setState({
          //   ...this.state,
          //   carRegisterDate: response.data.getCarDetails.details.registerDate,
          //   carVersions: response.data.getCarDetails.versions,
          //   carBrand: response.data.getCarDetails.details.brand
          // })
        })
        .catch(e => {
          // console.log(e.message)
          this.setState({ errorMessage: e.message })
          this.setState({
            ...this.state,
            carVersions: [],
            carRegisterDate: '',
            carBrand: '',
            carModelName: '',
          })
        })
        .finally(() => {
          this.setState({ loading: false })
        })
    }
  }
  getCarModel(v, g) {
    // if (v.length == 8) {
    this.setState({ loading: true })
    new Ql('getCarModel')
      .getCar(v, g)
      .then(response => {
        this.setState({
          ...this.state,
          carBrand: response.data.getCarModel.brand,
          carModelName: response.data.getCarModel.model
        })
      })
      .catch(e => {
        this.setState({ errorMessage: e.message })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
    // }
  }
  //get dental insured persons birthdate
  getPersonAmountFields(v) {
    return [...Array(parseInt(v - 1))].map((x, i) => (
      <div className="form-node" key={i}>
        <span>Qual a data de nascimento da Pessoa Segura {i + 1}?</span>
        <Form
          onValueChange={v => {
            this.createBirthdateArray(v, i)
          }}
          type="birth-date"
          value={this.state.personsBirthdateArray[i]}
        />
      </div>
    ))
  }

  //get info to fill car insurance - temp commented
  // getUserInfo() {
  //   new Ql('userData')
  //     .get()
  //     .then(response => {
  //       this.setState({
  //         ...this.state,
  //         userLicenseDate: response.data.userGet.licenseDate,
  //         userNif: response.data.userGet.vat,
  //         userPostcode: response.data.userGet.postcode,
  //       })
  //     })
  //     .catch(e => {
  //       this.setState({ errorMessage: e.message })
  //     })
  // }
  HomeInsurance() {
    let files = []

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual o tipo de seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'MULTIRISK' }, { name: 'FILLING' }, { name: 'ALL' }]}
                    id={'tipo' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-nao"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'numero' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'qualapolice' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="input"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'renovacao' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable2.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valor' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual o tipo de habitação?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'HOUSE' }, { name: 'APARTMENT' }, { name: 'OTHER' }]}
                    id={'qualtipo' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('homeType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="form-node">
                <span>Qual o tipo de utilização?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'PERMANENT' },
                      { name: 'SECONDARY' },
                      { name: 'RENTING' },
                      { name: 'LOCAL' },
                      { name: 'UNOCCUPIED' },
                      { name: 'OTHER' }
                    ]}
                    id={'qualtipoutilizacao' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('homeUsage', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  LifeInsurance() {
    let files = []

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual o tipo de seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'DEAD' }, { name: 'DISABLEMENT' }, { name: 'ALL' }]}
                    id={'tipo' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 === 'ALL' && this.customField()}
              {this.state.customVariable1 === 'DISABLEMENT' && this.customField()}
              <div className="form-node">
                <span>Profissão</span>
                <Form
                  id={'profissão' + this.props.index}
                  // onBlockStateChange={v => {
                  //   this.props.onBlockStateChange(v)
                  //   this.setState({ isBlocking: v })
                  // }}
                  onValueChange={v => {
                    this.setFormData('profession', v)
                  }}
                  type="select-jobs"
                  value={this.formData.get('profession')}
                />
              </div>
              <div className="form-node">
                <span>Data Nascimento</span>
                <Form
                  onValueChange={v => {
                    this.setFormData('birthdate', v)
                  }}
                  type="birth-date"
                  value={this.formData.get('birthdate')}
                />
              </div>
              <div className="form-node width-correction">
                <span>Qual o montante do capital seguro?</span>
                <Form
                  id={'qualapolice' + this.props.index}
                  onValueChange={v => {
                    this.setFormData('capital', _.replace(v.toString(), ',', '.'))
                  }}
                  type="text"
                />
              </div>
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-vida"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'qualtipo' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'numeroapolice' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="input"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable3: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable3 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable3.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valor' + this.props.index}
                    onBlockStateChange={v => {
                      this.props.onBlockStateChange(v)
                      this.setState({ isBlocking: v })
                    }}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'renovacao' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  MortgageInsurance() {
    let files = []

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual o tipo de seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'DEAD' }, { name: 'DISABLEMENT' }, { name: 'ALL' }]}
                    id={'tipo' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 === 'ALL' && this.customField()}
              {this.state.customVariable1 === 'DISABLEMENT' && this.customField()}
              <div className="form-node">
                <span>Profissão</span>
                <Form
                  id={'profissão' + this.props.index}
                  // onBlockStateChange={v => {
                  //   this.props.onBlockStateChange(v)
                  //   this.setState({ isBlocking: v })
                  // }}
                  onValueChange={v => {
                    this.setFormData('profession', v)
                  }}
                  type="select-jobs"
                  value={this.formData.get('profession')}
                />
              </div>
              <div className="form-node">
                <span>Data Nascimento</span>
                <Form
                  onValueChange={v => {
                    this.setFormData('holdersBirthdates', v)
                  }}
                  type="birth-date"
                  value={this.formData.get('holdersBirthdates')}
                />
              </div>
              <div className="form-node width-correction">
                <span>Qual o montante do capital seguro?</span>
                <Form
                  id={'qualapolice' + this.props.index}
                  onValueChange={v => {
                    this.setFormData('capital', _.replace(v.toString(), ',', '.'))
                  }}
                  type="text"
                />
              </div>
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-vida"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'qualtipo' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'numeroapolice' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="input"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable3: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable3 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable3.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valor' + this.props.index}
                    onBlockStateChange={v => {
                      this.props.onBlockStateChange(v)
                      this.setState({ isBlocking: v })
                    }}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'renovacao' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  loadingAnimation() {
    return (
      <div className="loading">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    )
  }
  OtherInsurance() {
    const { personAmountDental } = this.state

    let files = []
    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-nao"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>

              {this.props.index === '7' && (
                // related to extended warranty
                <>
                  <div className="form-node">
                    <span>Preço do Produto?</span>
                    <div className="checkbox-wrapper width-correction">
                      <Form
                        id={'pvp' + this.props.index}
                        onValueChange={v => {
                          this.setState({ warrantyPvp: v })
                          this.setFormData('pvp', v)
                        }}
                        type="number"
                        value={this.formData.get('pvp')}
                      />
                    </div>
                    {personAmountDental < 0 && (
                      <div>
                        <span className="error-message">Montante igual ou inferior a zero.</span>
                      </div>
                    )}
                  </div>
                  <div className="form-node">
                    <span>Marca do Produto?</span>
                    <div className="checkbox-wrapper width-correction">
                      <Form
                        id={'brand' + this.props.index}
                        onValueChange={v => {
                          this.setState({ warrantyBrand: v })
                          this.setFormData('brand', v)
                        }}
                        type="text"
                        value={this.formData.get('brand')}
                      />
                    </div>
                  </div>
                  <div className="form-node">
                    <span>Modelo do Produto?</span>
                    <div className="checkbox-wrapper width-correction">
                      <Form
                        id={'brand' + this.props.index}
                        onValueChange={v => {
                          this.setState({ warrantyModel: v })
                          this.setFormData('model', v)
                        }}
                        type="text"
                        value={this.formData.get('model')}
                      />
                    </div>
                  </div>
                  <div className="form-node">
                    <span>Data de compra do Produto?</span>
                    <div className="checkbox-wrapper width-correction">
                      <Form
                        id={'purchaseDate' + this.props.index}
                        onValueChange={v => {
                          this.setState({ warrantyPurchaseDate: v })
                          this.setFormData('purchaseDate', v)
                        }}
                        type="license-date"
                        value={this.formData.get('purchaseDate')}
                      />
                    </div>
                  </div>
                </>
              )}

              {this.props.index === '11' && (
                //related to dental insurance
                <>
                  <div className="form-node">
                    <span>Quantas pessoas estão incluídas?</span>
                    <div className="checkbox-wrapper width-correction">
                      <Form
                        id={'nPessoas' + this.props.index}
                        onValueChange={v => {
                          this.setState({ personAmountDental: v })
                          this.setFormData('insuredPersons', v)
                        }}
                        type="number"
                        value={this.formData.get('insuredPersons')}
                      />
                    </div>
                    {personAmountDental > 5 && (
                      <div>
                        <span className="error-message">5 é o número máximo permitido</span>
                      </div>
                    )}
                  </div>
                  {personAmountDental >= 1 && personAmountDental < 6 && (
                    <div className="form-node">
                      <span>Qual a data de nascimento do tomador do Seguro?</span>
                      <Form
                        onValueChange={v => {
                          const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                          this.setFormData('birthdate', formatedValue)
                        }}
                        type="birth-date"
                        value={this.formData.get('birthdate')}
                      />
                    </div>
                  )}
                  {personAmountDental > 1 &&
                    personAmountDental < 6 &&
                    this.getPersonAmountFields(personAmountDental)}
                </>
              )}

              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>

              {this.state.customVariable1 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable1.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valorque' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}

              {this.props.index === '8' && (
                //index 8 is related to health insurance
                <>
                  <div className="form-node">
                    <span>Quantas pessoas estão incluídas?</span>
                    <div className="checkbox-wrapper width-correction">
                      <Form
                        id={'nPessoas' + this.props.index}
                        onValueChange={v => {
                          this.setFormData('insuredPersons', parseInt(v))
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="form-node">
                    <span>O que inclui o teu seguro?</span>
                    <div className="checkbox-wrapper">
                      <Form
                        data={[
                          { name: 'MEDICAL_EXAMS' },
                          { name: 'HOSPITALIZATION' },
                          { name: 'STOMATOLOGY' },
                          { name: 'SERIOUS_DISEASES' },
                          { name: 'OPHTHALMOLOGY' },
                          { name: 'CHILDBIRTH' },
                          { name: 'PHYSIOTHERAPY' }
                        ]}
                        id={'tipo' + this.props.index}
                        onValueChange={v => {
                          this.setFormData('additionalCover', v)
                          //this.setState({ customVariableType: v })
                        }}
                        type="multiple-checkbox"
                      />
                    </div>
                  </div>
                </>
              )}
              {this.props.index === '15' && (
                //index 15 is related to Third party insurance
                <div className="form-node">
                  <span>Qual o tipo de seguro?</span>
                  <div className="checkbox-wrapper">
                    <Form
                      data={[
                        { name: 'FAMILY' },
                        { name: 'PROFESSIONAL' },
                        { name: 'DOG' },
                        { name: 'OTHER' }
                      ]}
                      id={'tipo' + this.props.index}
                      onValueChange={v => {
                        this.setFormData('insuranceSubtype', v)
                        this.setState({ customVariableType: v })
                      }}
                      type="checkbox"
                    />
                  </div>
                </div>
              )}
              {/* {this.state.customVariableType === 'OTHER' && (
                <div className="form-node width-correction">
                  <span>Descreve o tipo de seguro</span>
                  <Form
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="input"
                  />
                </div>
              )} */}
              {this.state.customVariableType === 'PROFESSIONAL' && (
                <div className="form-node">
                  <span>Qual a profissão?</span>
                  <Form
                    id={'profissão' + this.props.index}
                    onBlockStateChange={v => {
                      this.props.onBlockStateChange(v)
                      this.setState({ isBlocking: v })
                    }}
                    onValueChange={v => {
                      this.setFormData('profession', v)
                    }}
                    type="select-jobs"
                    value={this.formData.get('profession')}
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'dataseguro' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'sabesapolice' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'qualapolice' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="input"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  PersonalAccidentInsurance() {
    let files = []
    {
      //subtype for work accident insurance
      this.props.subtype && this.setFormData('insuranceSubtype', this.props.subtype)
    }

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-nao"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable1.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valor' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'renovacao' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node width-correction">
                <span>Qual o montante do capital seguro?</span>
                <Form
                  id={'qualapolice' + this.props.index}
                  onValueChange={v => {
                    this.setFormData('capital', _.replace(v.toString(), ',', '.'))
                  }}
                  type="text"
                />
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'qualtipo' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'numeroapolice' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="input"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  PetInsurance() {
    let files = []

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual o animal que está seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'DOG' }, { name: 'CAT' }, { name: 'OTHER' }]}
                    id={'animalperiodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                      this.setFormData('petType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o nome do {this.pickEquivalent(this.state.customVariable1.toLowerCase())}?
                  </span>
                  <Form
                    id={'petName' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('petName', v)
                    }}
                    type="input"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-nao"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidadepagamento' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable2.toLowerCase())} que
                    pagas?
                  </span>
                  <Form
                    id={'valorque' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data de renovação do seguro?</span>
                <Form
                  id={'qualrenovacao' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable3: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable3 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <Form
                    id={'numeroapolice' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('externalId', v)
                    }}
                    type="input"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  pickEquivalent(str) {
    let name

    switch (str.toUpperCase()) {
      case 'YES':
        name = 'Sim'
        break
      case 'NO':
        name = 'Não'
        break
      case 'ANNUAL':
        name = 'anual'
        break
      case 'SEMESTER':
        name = 'semestral'
        break
      case 'QUARTERLY':
        name = 'trimestral'
        break
      case 'MONTHLY':
        name = 'mensal'
        break
      case 'OTHER':
        name = 'outro'
        break
      case 'DOG':
        name = 'cão'
        break
      case 'CAT':
        name = 'gato'
        break
      default:
        name = str
    }

    return name
  }

  render() {
    let PageForm = this.obj

    return <PageForm />
  }
  setFile(value) {
    if (!this.files.size) {
      this.files.set(0, value.files[0])
    } else {
      this.files.set(this.files.size, value.files[0])
    }

    this.setState({ filesList: this.files })
  }

  setFormData(key, value) {
    this.formData.set(key, value)
  }

  setObject(obj) {
    this.obj = obj
  }

  TravelInsurance() {
    let files = []

    if (this.state.filesList) {
      //eslint-disable-next-line unused-imports/no-unused-vars
      for (let item of this.state.filesList.entries()) {
        files.push(
          <div className="inline filefield" key={item[0]}>
            <div
              className="remove-sign"
              onClick={() => {
                this.unsetFile(item[0])
              }}>
              &times;
            </div>
            <div className="inline file-attach">
              <span>{item[1].name}</span>
            </div>
          </div>
        )
      }
    }

    return (
      <div>
        {' '}
        <div className="add-insurance row">
          <div className="col-sm-12 col-lg-5 space-bottom">
            <div className="user-details ">
              <div className="form-node">
                <span>Qual o tipo de seguro?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'SINGLE' }, { name: 'ANNUAL' }]}
                    id={'seguradoratype' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="form-node">
                <span>Qual é a tua Seguradora?</span>
                <Form
                  id={'seguradora' + this.props.index}
                  onBlockStateChange={v => {
                    this.props.onBlockStateChange(v)
                    this.setState({ isBlocking: v })
                  }}
                  onValueChange={v => {
                    this.setFormData('insuranceBrand', v)
                  }}
                  type="select-nao"
                />
                {this.state.isBlocking === 'true' && (
                  <span className="danger" style={{ marginTop: '8px', display: 'block' }}>
                    Tens de selecionar uma opção
                  </span>
                )}
              </div>
              <div className="form-node">
                <span>Qual a periodicidade de pagamento?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[
                      { name: 'ANNUAL' },
                      { name: 'SEMESTER' },
                      { name: 'QUARTERLY' },
                      { name: 'MONTHLY' }
                    ]}
                    id={'periodicidade' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable1: v })
                      this.setFormData('premiumType', v)
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable1 && (
                <div className="form-node width-correction">
                  <span>
                    Qual o valor {this.pickEquivalent(this.state.customVariable1)} que pagas?
                  </span>
                  <Form
                    id={'valorque' + this.props.index}
                    onValueChange={v => {
                      this.setFormData('premium', v)
                    }}
                    type="number"
                  />
                </div>
              )}
              <div className="form-node">
                <span>Qual a data fim do seguro?</span>
                <Form
                  id={'dataseguro' + this.props.index}
                  onValueChange={v => {
                    const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                    this.setFormData('renewalDate', formatedValue)
                  }}
                  type="date"
                />
              </div>
              <div className="form-node width-correction">
                <span>Quantos viajantes estão associados no seguro?</span>
                <Form
                  id={'quantosassociados' + this.props.index}
                  onValueChange={v => {
                    this.setFormData('travelersNumber', parseInt(v))
                  }}
                  type="number"
                />
              </div>
              <div className="form-node">
                <span>Sabes o número da apólice?</span>
                <div className="checkbox-wrapper">
                  <Form
                    data={[{ name: 'Yes' }, { name: 'No' }]}
                    id={'sabesapolice' + this.props.index}
                    onValueChange={v => {
                      this.setState({ customVariable2: v })
                    }}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.customVariable2 === 'Yes' && (
                <div className="form-node width-correction">
                  <span>Qual o número da apólice?</span>
                  <div className="checkbox-wrapper">
                    <Form
                      id={'qualapolice' + this.props.index}
                      onValueChange={v => {
                        this.setFormData('externalId', v)
                      }}
                      type="input"
                    />
                  </div>
                </div>
              )}
              <div className="form-node">
                <span>Anexar os documentos do seguro</span>
                {files}
                <Form
                  id={'attach' + this.props.index}
                  onValueChange={v => {
                    this.setFile(v)
                  }}
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  unsetFile(key) {
    this.files.delete(key)
    this.setState({ filesList: this.files })
  }

  UserSettings() {
    return (
      <div className="user-settings row">
        <div className="col-sm-12 col-lg-5 space-bottom">
          <div className="user-details ">
            <h1 className="main-title">
              <span>1</span> Os Meus Dados
            </h1>
            <div className="form-node">
              <span>Primeiro Nome</span>
              <Form
                onValueChange={v => {
                  this.setFormData('firstName', v)
                }}
                type="input"
                value={this.formData.get('firstName')}
              />
            </div>
            <div className="form-node">
              <span>Último Nome</span>
              <Form
                onValueChange={v => {
                  this.setFormData('lastName', v)
                }}
                type="input"
                value={this.formData.get('lastName')}
              />
            </div>
            <div className="form-node">
              <span>Nr. Contribuinte</span>
              <Form
                onValueChange={v => {
                  this.setFormData('vat', v)
                }}
                type="input-nif"
                value={this.formData.get('vat')}
              />
            </div>
            <div className="form-node">
              <span>Nr. Cartão Cidadão</span>
              <Form
                onValueChange={v => {
                  this.setFormData('idNumber', v)
                }}
                type="input-nif"
                value={this.formData.get('idNumber')}
              />
            </div>
            <div className="form-node">
              <span>Carta de Condução</span>
              <Form
                onValueChange={v => {
                  this.setFormData('licenseNumber', v)
                }}
                type="input"
                value={this.formData.get('licenseNumber')}
              />
            </div>
            <div className="form-node">
              <span>Data Carta de Condução</span>
              <Form
                onValueChange={v => {
                  const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                  this.setFormData('licenseDate', formatedValue)
                }}
                type="license-date"
                value={this.formData.get('licenseDate')}
              />
            </div>
            <div className="form-node">
              <span>Morada Completa</span>
              <Form
                onValueChange={v => {
                  this.setFormData('address', v)
                }}
                type="input"
                value={this.formData.get('address')}
              />
            </div>
            <div className="form-node">
              <span>Código Postal</span>
              <Form
                onValueChange={v => {
                  this.setFormData('postcode', v)
                }}
                type="postCode"
                value={this.formData.get('postcode')}
              />
            </div>
            <div className="form-node">
              <span>Data Nascimento</span>
              <Form
                onValueChange={v => {
                  const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                  this.setFormData('birthday', formatedValue)
                }}
                type="birth-date"
                value={this.formData.get('birthday')}
              />
            </div>
            <div className="form-node">
              <span>Passatempos</span>
              <div className="multiple-checkbox-wrapper">
                <span className="dropdown-span">Escolhe as opções</span>
                <div className="multiple-checkbox-content">
                  <Form
                    data={[
                      { name: 'VOLUNTEERING' },
                      { name: 'PHOTOGRAPHY' },
                      { name: 'SURF' },
                      { name: 'CLIMBING' },
                      { name: 'BIKE' },
                      { name: 'READING' },
                      { name: 'COOKING' },
                      { name: 'PAINTING' },
                      { name: 'FISHING' },
                      { name: 'HUNTING' },
                      { name: 'GARDENING' },
                      { name: 'SEWING' }
                    ]}
                    id="hobbies"
                    onValueChange={v => {
                      this.setFormData('hobbies', v)
                    }}
                    type="multiple-checkbox"
                    value={this.formData.get('hobbies')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-5 space-bottom">
          <div className="user-about">
            <h1 className="main-title">
              <span>2</span> Sobre Mim
            </h1>
            <div className="form-node">
              <span>Profissão</span>
              <Form
                id={'profissão' + this.props.index}
                onBlockStateChange={v => {
                  this.setState({ saveBlocked: v === 'true' })
                }}
                onValueChange={v => {
                  this.setFormData('profession', v)
                  this.setState({
                    ...this.state,
                    profession: v
                  })
                }}
                type="select-jobs"
                value={this.formData.get('profession')}
              />
            </div>
            <div className="form-node">
              <span>Titulo</span>
              <Form
                onValueChange={v => {
                  this.setFormData('jobTitle', v)
                }}
                type="input"
                value={this.formData.get('jobTitle')}
              />
            </div>
            <div className="form-node">
              <span>Estado Civil</span>
              <Form
                data={[
                  { name: 'SINGLE' },
                  { name: 'MARRIED' },
                  { name: 'WIDOWED' },
                  { name: 'DIVORCED' },
                  { name: 'SEPARATED' }
                ]}
                id="maritalStatus"
                onValueChange={v => {
                  this.setFormData('maritalStatus', v)
                }}
                type="select"
                value={this.formData.get('maritalStatus')}
              />
            </div>
            <div className="form-node">
              <span>Nr. Filhos(as)</span>
              <Form
                onValueChange={v => {
                  this.setFormData('childrenNr', v)
                }}
                type="number"
                value={this.formData.get('childrenNr')}
              />
            </div>
            <div className="form-node">
              <span>Salário Anual</span>
              <Form
                onValueChange={v => {
                  this.setFormData('annualSalary', v)
                }}
                type="input-money"
                value={this.formData.get('annualSalary')}
              />
            </div>
            <div className="form-node">
              <span>Despesas Fixas Anuais</span>
              <Form
                onValueChange={v => {
                  this.setFormData('annualFixesExpenses', v)
                }}
                type="input-money"
                value={this.formData.get('annualFixesExpenses')}
              />
            </div>
            <div className="form-node">
              <span>Tipo de Casa</span>
              <div className="multiple-checkbox-wrapper">
                <span className="dropdown-span">Escolhe as opções</span>
                <div className="multiple-checkbox-content">
                  <Form
                    data={[
                      { name: 'APARTMENT' },
                      { name: 'HOUSE' },
                      { name: 'PRIVATECONDOMINIUM' },
                      { name: 'FARM' },
                      { name: 'DUPLEX' },
                      { name: 'OTHER' }
                    ]}
                    id="houseType"
                    onValueChange={v => {
                      this.setFormData('houses', v)
                    }}
                    type="multiple-checkbox"
                    value={this.formData.get('houses') ? this.formData.get('houses') : null}
                  />
                </div>
              </div>
            </div>

            <div className="form-node">
              <span>Veículos</span>
              <div className="multiple-checkbox-wrapper">
                <span className="dropdown-span">Escolhe as opções</span>
                <div className="multiple-checkbox-content">
                  <Form
                    data={[
                      { name: 'CAR' },
                      { name: 'VAN' },
                      { name: 'MINIVAN' },
                      { name: 'SUV' },
                      { name: 'SCOOTER' },
                      { name: 'MOTO' },
                      { name: 'COMERCIAL_CAR' },
                      { name: 'TRACTOR' },
                      { name: 'OTHER' }
                    ]}
                    id="petType"
                    onValueChange={v => {
                      this.setFormData('vehicles', v)
                    }}
                    type="multiple-checkbox"
                    value={this.formData.get('vehicles') ? this.formData.get('vehicles') : null}
                  />
                </div>
              </div>
            </div>

            <div className="form-node">
              <span>Tipo de Animais</span>
              <div className="multiple-checkbox-wrapper">
                <span className="dropdown-span">Escolhe as opções</span>
                <div className="multiple-checkbox-content">
                  <Form
                    data={[
                      { name: 'DOG' },
                      { name: 'CAT' },
                      { name: 'HORSE' },
                      { name: 'RABBIT' },
                      { name: 'FISH_TURTLE' },
                      { name: 'OTHER' }
                    ]}
                    id="petType"
                    onValueChange={v => {
                      this.setFormData('pets', v)
                    }}
                    type="multiple-checkbox"
                    value={this.formData.get('pets') ? this.formData.get('pets') : null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  UserView() {
    return (
      <div className="user-settings row">
        <div className="col-sm-12 col-lg-5 space-bottom">
          <div className="user-details">
            <h1 className="main-title">
              <span>1</span> Os Meus Dados
            </h1>
            <div className="container-show">
              <span className="container-name">Primeiro Nome</span>
              <Form
                onValueChange={v => {
                  this.setFormData('firstName', v)
                }}
                type="show-box"
                value={this.formData.get('firstName')}
              />
            </div>
            <div className="container-show">
              <span className="container-name">Último Nome</span>
              <Form
                onValueChange={v => {
                  this.setFormData('lastName', v)
                }}
                type="show-box"
                value={this.formData.get('lastName')}
              />
            </div>
            <div className="container-show">
              <span className="container-name">Email</span>
              <Form
                onValueChange={v => {
                  this.setFormData('email', v)
                }}
                type="show-email"
                value={this.formData.get('email')}
              />
            </div>
            <div className="container-show">
              <span>Nr. Contribuinte</span>
              <Form
                onValueChange={v => {
                  this.setFormData('vat', v)
                }}
                type="nif"
                value={this.formData.get('vat')}
              />
            </div>
            <div className="container-show">
              <span>Nr. Cartão Cidadão</span>
              <Form
                onValueChange={v => {
                  this.setFormData('idNumber', v)
                }}
                type="nif"
                value={this.formData.get('idNumber')}
              />
            </div>
            <div className="container-show">
              <span>Carta de Condução</span>
              <Form
                onValueChange={v => {
                  this.setFormData('licenseNumber', v)
                }}
                type="show-box"
                value={this.formData.get('licenseNumber')}
              />
            </div>
            <div className="container-show">
              <span>Data Carta de Condução</span>
              <Form
                onValueChange={v => {
                  this.setFormData('licenseDate', v)
                }}
                type="show-box"
                value={
                  this.formData.get('licenseDate') === 'Invalid date'
                    ? ''
                    : moment(moment(this.formData.get('licenseDate'), 'YYYY-MM-DD')).format(
                        'DD-MM-YYYY'
                      )
                }
              />
            </div>
            <div className="container-show">
              <span>Morada Completa</span>
              <Form
                onValueChange={v => {
                  this.setFormData('address', v)
                }}
                type="show-box"
                value={this.formData.get('address')}
              />
            </div>
            <div className="container-show">
              <span>Código Postal</span>
              <Form
                onValueChange={v => {
                  this.setFormData('postcode', v)
                }}
                type="show-box"
                value={this.formData.get('postcode')}
              />
            </div>
            <div className="container-show">
              <span>Data Nascimento</span>
              <Form
                onValueChange={v => {
                  this.setFormData('birthday', v)
                }}
                type="show-box"
                value={
                  this.formData.get('birthday') === 'Invalid date' ||
                  this.formData.get('birthday') === null
                    ? ''
                    : moment(moment(this.formData.get('birthday'), 'YYYY-MM-DD')).format(
                        'DD-MM-YYYY'
                      )
                }
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-5 space-bottom">
          <div className="user-about">
            <h1 className="main-title">
              <span>2</span> Sobre Mim
            </h1>
            <div className="container-show">
              <span>Profissão</span>
              <Form
                onValueChange={v => {
                  this.setFormData('profession', v)
                }}
                type="show-box"
                value={this.formData.get('profession')}
              />
            </div>
            <div className="container-show">
              <span>Titulo</span>
              <Form
                onValueChange={v => {
                  this.setFormData('jobTitle', v)
                }}
                type="show-box"
                value={this.formData.get('jobTitle')}
              />
            </div>

            <div className="container-show">
              <span>Estado Civil</span>
              <Form
                id="maritalStatus"
                onValueChange={v => {
                  this.setFormData('maritalStatus', v)
                }}
                type="show-box"
                value={this.formData.get('maritalStatus')}
              />
            </div>
            <div className="container-show">
              <span>Nr. Filhos(as)</span>
              <span className="show-box">{this.formData.get('childrenNr')}</span>
            </div>

            <div className="container-show">
              <span>Salário Anual</span>
              <span className="show-box">{this.formData.get('annualSalary')} €</span>
            </div>
            <div className="container-show">
              <span>Despesas Fixas Anuais</span>
              <span className="show-box">{this.formData.get('annualFixesExpenses')} €</span>
            </div>

            <div className="container-show">
              <span>Tipo de Casa</span>
              <div className="multiple-checkbox-wrapper-view">
                <Form
                  data={[
                    { name: 'APARTMENT' },
                    { name: 'HOUSE' },
                    { name: 'PRIVATECONDOMINIUM' },
                    { name: 'FARM' },
                    { name: 'DUPLEX' },
                    { name: 'OTHER' }
                  ]}
                  id="houseType"
                  onValueChange={v => {
                    this.setFormData('houses', v)
                  }}
                  type="multiple-show"
                  value={this.formData.get('houses') ? this.formData.get('houses') : null}
                />
              </div>
            </div>

            <div className="container-show">
              <span>Veículos</span>
              <div className="multiple-checkbox-wrapper-view">
                <Form
                  data={[
                    { name: 'CAR' },
                    { name: 'VAN' },
                    { name: 'MINIVAN' },
                    { name: 'SUV' },
                    { name: 'SCOOTER' },
                    { name: 'MOTO' },
                    { name: 'COMERCIAL_CAR' },
                    { name: 'TRACTOR' },
                    { name: 'OTHER' }
                  ]}
                  id="petType"
                  onValueChange={v => {
                    this.setFormData('vehicles', v)
                  }}
                  type="multiple-show"
                  value={this.formData.get('vehicles') ? this.formData.get('vehicles') : null}
                />
              </div>
            </div>
            <div className="container-show">
              <span>Passatempos</span>
              <div className="multiple-checkbox-wrapper-view">
                <Form
                  data={this.formData.get('hobbies')}
                  id="hobbies"
                  onValueChange={v => {
                    this.setFormData('hobbies', v)
                  }}
                  type="multiple-show"
                  value={this.formData.get('hobbies')}
                />
              </div>
            </div>
            <div className="container-show">
              <span>Tipo de Animais</span>
              <div className="multiple-checkbox-wrapper-view">
                <Form
                  data={[
                    { name: 'DOG' },
                    { name: 'CAT' },
                    { name: 'HORSE' },
                    { name: 'RABBIT' },
                    { name: 'TURTLE' },
                    { name: 'OTHER' }
                  ]}
                  id="petType"
                  onValueChange={v => {
                    this.setFormData('pets', v)
                  }}
                  type="multiple-show"
                  value={this.formData.get('pets') ? this.formData.get('pets') : null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FormBuilder
