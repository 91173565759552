import React, { useState, useEffect } from 'react'
import { MaskedInputComponent } from '../../components/Inputs/maskedInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spin } from 'antd'
import { useLocation } from 'react-router-dom'
import { gql } from 'apollo-boost-upload'
import apollo from '../../api/client'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'
import './index.less'
import { IS_PHONE_VALID_AND_VERIFIED } from '../../store/actions'
// import { Notification } from 'element-react'

const VerifyOTP = () => {
  const isPhoneValidAndVerified = useSelector(state => state?.isPhoneValidAndVerified)
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState(false)
  const [countdown, setCountdown] = useState(60)
  const location = useLocation()
  const phone = location?.state?.phone
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  let MOBILE_BREAKPOINT = 700
  // eslint-disable-next-line
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  useEffect(() => {
    if (isPhoneValidAndVerified) return history.push('/seguros')
  }, [isPhoneValidAndVerified])
  useEffect(() => {
    if (isDisabled && countdown > 0) {
      const timerId = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
      return () => clearTimeout(timerId) // Clean up on unmount or if condition changes
    } else if (countdown === 0) {
      setIsDisabled(false)
      setCountdown(60)
    }
  }, [isDisabled, countdown])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: null
    },
    onSubmit: (values: any) => {
      setLoading(true)
      setTimeout(() => {
        return apollo
          .query({
            query: gql`
              query ValidateOtp($otp: String!, $phone: String!) {
                validateOtp(otp: $otp, phone: $phone) {
                  isPhoneNumValid
                  code
                  message
                }
              }
            `,
            variables: {
              otp: values?.otp?.replace(/\s+/g, ''),
              phone: phone
            },
            fetchPolicy: 'no-cache'
          })
          .then(res => {
            // let status = res.data.validateOtp.isPhoneNumValid
            let code = res?.data?.validateOtp?.code
            // let message = res?.data?.validateOtp?.message
            if (code == '200') {
              dispatch({
                type: IS_PHONE_VALID_AND_VERIFIED,
                payload: true
              })
              history.push('/seguros')
              setLoading(false)
            } else if (code == '202') {
              setLoading(false)
              formik.setFieldError('otp', 'O código está incorrecto')
            } else {
              setLoading(false)
              // if (!isMobile) {
              //   Notification.error({
              //     title: '',
              //     message: message,
              //     duration: 0
              //   })
              // }
            }
            //  window.location.reload()
          })
          .catch(err => {
            console.log(err)
          })
      }, 2000)
    },
    validationSchema: Yup.object().shape({
      otp: Yup.mixed()
        .test(
          'otp',
          'O comprimento mínimo do OTP deve ser de 6 dígitos',
          (value: any, testContext: any) => {
            if (!value) {
              return testContext.createError({ message: 'Preenche este campo' })
            } else if (value) {
              let newStr = value?.replace(/[_\s]/g, '')
              return newStr.length == 6
            } else return false
          }
        )
        .required('Preenche este campo')
        .typeError('Preenche este campo')
    })
  })

  const handleResend = () => {
    // Resend OTP logic here
    // setLoading(true)
    setIsDisabled(true)
    return apollo
      .query({
        query: gql`
          query SendOtp($phone: String!, $type: OtpType) {
            sendOtp(phone: $phone, type: $type) {
              code
              message
            }
          }
        `,
        variables: {
          phone: phone,
          type: "RESEND"
        },
        fetchPolicy: 'no-cache'
      })
      .then(() => {})
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <p
        className="back-text"
        onClick={() =>
          history.push('/verify-number', {
            from: history.location.pathname,
            state: phone
          })
        }>
        {'<- Alterar o nº telemóvel'}
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="verify-mobile">
          <div className="wrapper-div">
            <h1>Introduz o código que recebeste por SMS</h1>

            <p style={{ fontSize: '18px' }}>
              {`Recebeste um sms no telemóvel que termina em xx${phone?.slice(-2)} com um código de 6 números. Esta
              validação ajuda-nos a proteger a tua conta.`}
            </p>
            <div className="verify-otp-div">
              <p className="phone-text">Introduz o código de 6 digitos</p>
              <MaskedInputComponent
                formik={formik}
                mask="111 111"
                name="otp"
                type="tel"
                placeholder="xxx xxx"
                style={{ width: '100%' }}
              />
              <button className="otp-button" type="submit">
                {loading ? <Spin size="small" /> : 'Entrar na minha Wallet'}
              </button>
              <p
                onClick={handleResend}
                className={isDisabled ? 'disabled-text resend-text' : 'resend-text'}>
                Enviar novo código
              </p>
              <p style={{ textAlign: 'center' }}>
                {' '}
                {isDisabled && `Resend in ${countdown} seconds`}
              </p>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default VerifyOTP
