import PropTypes from 'prop-types'
/* eslint-disable react/prefer-stateless-function */
import React from 'react'

class AddViaEmail extends React.Component {
  render() {
    return (
      <div className="email-page">
        <div
          className="back-arrow"
          onClick={() => {
            this.props.history.push('/seguros')
          }}>
          <img alt="left-arrow" src={require('../../images/icons/arrow-left.svg')} />
        </div>
        <h1>
          Preferes que seja a MUDEY a adicionar os seguros à tua wallet?{' '}
          <strong>
            Muito bem{' '}
            <span aria-labelledby="" role="img">
              &#x1F609;
            </span>
            !
          </strong>
        </h1>
        <h2>Passo 1</h2>
        <p>
          Envias um email para <a href="mailto:bemvindos@mudey.pt">bemvindos@mudey.pt</a> com:
        </p>
        <ol type="a">
          <li>Os documentos dos seguros para adicionar à tua Wallet MUDEY (condições gerais, entre outros)</li>
          <span>ou</span>
          <li>
            As seguintes informaçãoes
            <ol type="i">
              <li>Qual o Seguro (por exemplo, carro, casa, etc.)</li>
              <li>Tipo de Seguro - por exemplo, todos os riscos no caso do seguro de carro</li>
              <li>Seguradora</li>
              <li>Valor que estás a pagar em euros</li>
              <li>Regularidade de pagamento (Anual, Semestral, Trimestral ou Mensal)</li>
              <li>Data Renovação</li>
            </ol>
          </li>
        </ol>
        <hr />
        <h2>Passo 2</h2>
        <p>A MUDEY vai adicionar os teus seguros, e envia-te uma mensagem quando tudo estiver pronto</p>
        <hr />
        <h2>Passo 3</h2>
        <p>Entras na tua Wallet para veres os seguros que foram adicionados</p>
        <div
          className="button-transparent"
          onClick={() => {
            this.props.history.push('/seguros')
          }}>
          Cancelar
        </div>
      </div>
    )
  }
}

AddViaEmail.propTypes = {
  history: PropTypes.any.isRequired
}

export default AddViaEmail
