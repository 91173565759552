import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// import { Mandatory } from '../components/mandatory'
// import { Recommended } from '../components/recommended'
// import { Consider } from '../components/consider'
import './suggestions.less'
// import Routes from '../../../routes/index'
import InfoTexts from '../../../components/ui/InfoTexts'
import PriceAdvisor from '../../../components/ui/PriceAdvisor'
import { AllCards } from '../components/AllCards'
import { AdvisorJourneyLinks } from '../../advisor/components/card'

export const Suggestions = () => {
  const user = useSelector(state => state.user_data)
  const surveyID = useSelector(state => state.advisorSurveyID)
  const [showMoreInfo, setShowMoreInfo] = useState(null)
  const [showPriceAdvisor, setShowPriceAdvisor] = useState(null)
  const recommended = useSelector(state => state.recommended)
  const consider = useSelector(state => state.consider)
  const mandatory = useSelector(state => state.mandatory)
  const cardHeads = [
    {
      name: 'Obrigatórios',
      subHead: 'Estes seguros são obrigatórios, não podes fugir!',
      items: mandatory,
      showDesc: true
    },
    {
      name: 'Sugeridos',
      subHead: 'Para estares bem protegido(a) em áreas importantes da tua vida!',
      items: recommended,
      showDesc: false
    },
    {
      name: 'A Considerar',
      subHead: 'Para reflectires e ponderares!',
      items: consider,
      showDesc: false
    }
  ]

  const openInNewTab = key => {
    if (
      ![
        'bike',
        'car',
        'buildings',
        'housekeeper',
        'scooter',
        'health',
        'personalAccident',
        'child',
        'drone',
        'mortgage',
        'dental',
        'criticalIllness',
        'hospitalization',
        'family',
        'content',
        'workAccidentSelfEmployeed'
      ].includes(key)
    ) {
      showPriceAdvisor(key)
    } else {
      if (key === 'child') {
        //location.href = `${AdvisorJourneyLinks[key]}?${user.firstName}&email=${user.email}`
        const newWindow = window.open(
          `${AdvisorJourneyLinks[key]}?${user.firstName}&email=${user.email}`,
          '_blank',
          'noopener,noreferrer'
        )
        if (newWindow) newWindow.opener = null
      } else if (['bike', 'scooter', 'drone'].includes(key)) {
        // window.location.href = `${AdvisorJourneyLinks[key]}${user.email}`
        const newWindow = window.open(
          `${AdvisorJourneyLinks[key]}${user.email}`,
          '_blank',
          'noopener,noreferrer'
        )
        if (newWindow) newWindow.opener = null
      } else {
        //window.location.href = AdvisorJourneyLinks[key]
        const newWindow = window.open(AdvisorJourneyLinks[key], '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
    }
  }

  return (
    <div className="suggestions">
      <div className="banner">
        <div className="banner-top">
          <div className="banner-text">
            <h1>O teu consultor, {user.firstName} !</h1>
          </div>

          <div className="banner-buttons-wrapper">
            <p className="wrapper-text">
              De acordo com o teu perfil, estes são os seguros que deves ter
            </p>
            <div>
              <button
                className="banner-button"
                onClick={() => {
                  window.location.href = `/form/${surveyID}`
                }}>
                Rever respostas
              </button>
              <button
                className="banner-button"
                onClick={() => {
                  window.location.href = '/form'
                }}>
                Iniciar consultor{' '}
              </button>
            </div>
          </div>
        </div>
      </div>

      {showMoreInfo && (
        <div className="modal-info darkBg">
          <div className="wrapper-info">
            <img alt="" className="icon" src={require('../../../images/sofia.png')} />
            <p className="name"> Sofia da MUDEY</p>
            <a className="button-solid" href="#mandatory" onClick={() => setShowMoreInfo(null)}>
              X
            </a>
            {new InfoTexts(showMoreInfo).getText()}
            <div className="quote-button-container">
              <button className="quote-button" onClick={() => openInNewTab(showMoreInfo)}>
                Obter cotação
              </button>
            </div>
          </div>
        </div>
      )}

      {showPriceAdvisor && (
        <div className="modal-info darkBg">
          <div className="typeform-wrapper">
            {new PriceAdvisor(showPriceAdvisor, user.userid, user.firstName, user.email).getForm()}
            <a className="button-solid" href="#mandatory" onClick={() => setShowPriceAdvisor(null)}>
              X
            </a>
          </div>
        </div>
      )}

      <div className="packages">
        {/* <Mandatory
          showMoreInfo={type => setShowMoreInfo(type)}
          showPriceAdvisor={type => setShowPriceAdvisor(type)}
        />
        <Recommended
          showMoreInfo={type => setShowMoreInfo(type)}
          showPriceAdvisor={type => setShowPriceAdvisor(type)}
        />
        <Consider
          showMoreInfo={type => setShowMoreInfo(type)}
          showPriceAdvisor={type => setShowPriceAdvisor(type)}
        /> */}
        {cardHeads &&
          cardHeads.map((item, index) => {
            return (
              Object.values(item.items).includes(1) && (
                <AllCards
                  Heading={item.name}
                  subHeading={item.subHead}
                  items={item.items}
                  showPriceAdvisor={type => setShowPriceAdvisor(type)}
                  showMoreInfo={type => setShowMoreInfo(type)}
                  key={index}
                  showDescription={item.showDesc}
                />
              )
            )
          })}
      </div>
    </div>
  )
}
