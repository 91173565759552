import 'element-theme-default'

import { Input } from 'antd'
import { gql } from 'apollo-boost-upload'
import { Notification } from 'element-react'
import firebase from 'firebase/app'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import apollo from '../../api/client'
import Layout from '../layout'
import { verifyErrorCode } from '../ui/LocalizeFirebaseErrorMessages'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

class Auth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      login: null,
      password: null,
      recovery: false,
      recoveryEmail: null,
      fname: null,
      lname: null,
      agree: null,
      isLogin: true,
      loading: false,
      disabled: false,
      eyeOpen: true
    }

    this.login = this.login.bind(this)
    this.gLogin = this.gLogin.bind(this)
    this.fbLogin = this.fbLogin.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
  }

  componentWillUnmount() {
    this.props.history.replace({ ...history.location, state: undefined })
  }

  componentDidMount() {
    console.log(this.props.history.location.state, 'wrge')
    if (this.props.history.location.state == 'recoverMode') {
      this.setState({ recovery: true })
    }
  }
  fbLogin() {
    var provider = new firebase.auth.FacebookAuthProvider()
    this.popupLogin(provider)
  }
  forgotPassword() {
    this.setState({ loading: true })

    // var auth = firebase.auth()
    var emailAddress = this.state.recoveryEmail
    // var protocol = window.location.protocol
    // var slashes = protocol.concat('//')
    // var host = slashes.concat(window.location.hostname)
    // var twopoints = host.concat(':')
    // var completeurl = twopoints.concat(window.location.port)
    // var actionCodeSettings = {
    //   url: completeurl,
    //   handleCodeInApp: false
    // }

    if (emailAddress === null) {
      Notification.error({
        title: 'Whoops... deu erro!',
        message: 'Deve inserir um email para continuar.'
      })
      this.setState({ loading: false })

      return false
    }
    let wallet_url = process.env.REACT_APP_WALLET_URL
    let continueUrl = wallet_url.concat(':')
    return apollo
      .mutate({
        mutation: gql`
          mutation ResetPassword(
            $email: String!
            $continueUrl: String!
            $canHandleCodeInApp: Boolean
            $appName: String!
          ) {
            resetPassword(
              input: {
                email: $email
                continueUrl: $continueUrl
                canHandleCodeInApp: $canHandleCodeInApp
                appName: $appName
              }
            ) {
              kind
              email
              status
            }
          }
        `,
        variables: {
          email: emailAddress,
          continueUrl,
          canHandleCodeInApp: false,
          appName: 'Mudey'
        }
      })
      .then(() => {
        Notification.success({
          title: 'Enviamos-te um email com as instruções',
          message: 'Verifica o teu email',
          duration: 0
        })
        this.setState({ loading: false })
      })
      .catch(err => {
        this.setState({ disable: true })
        const errorMessage = verifyErrorCode(err.code)
        Notification.error({
          title: 'Whoops... deu erro!',
          message: errorMessage
        })
        this.setState({ loading: false, recoveryEmail: '' })
      })

    // auth
    //   .sendPasswordResetEmail(emailAddress, actionCodeSettings)
    //   .then(() => {
    //     // eslint-disable-next-line new-cap
    //     Notification.success({
    //       title: 'Enviamos-te um email com as instruções',
    //       message: 'Verifica o teu email',
    //       duration: 0
    //     })
    //     this.setState({ loading: false })
    //   })
    //   .catch(e => {
    //     this.setState({ disable: true })
    //     const errorMessage = verifyErrorCode(e.code)
    //     Notification.error({
    //       title: 'Whoops... deu erro!',
    //       message: errorMessage
    //     })
    //     this.setState({ loading: false, recoveryEmail: '' })
    //   })
  }

  gLogin() {
    var provider = new firebase.auth.GoogleAuthProvider()
    this.popupLogin(provider)
  }

  goTo(path) {
    this.props.history.push(path)
  }
  login() {
    this.setState({ loading: true })

    let email = this.state.login,
      password = this.state.password

    if (!email || !password) {
      Notification.error({
        title: 'Whoops... deu erro!',
        message: 'Todos os campos são obrigatórios.'
      })
      this.setState({ loading: false })

      return false
    }

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(() => {
        Notification.error({
          title: 'Whoops... deu erro!',
          message: 'A password é inválida ou o utilizador não existe'
        })
        this.setState({ loading: false })
      })
  }
  popupLogin(provider) {
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(function(error) {
        const errorMessage = verifyErrorCode(error.code)
        Notification.error({
          title: 'Whoops... deu erro!',
          message: errorMessage
        })
      })
  }

  render() {
    const { loading, recoveryEmail, login, password } = this.state
    const isSubmitButtonDisabled = loading || !recoveryEmail || recoveryEmail.indexOf('@') === -1
    var disabledLoginBtn = true
    if (login && password !== null) {
      disabledLoginBtn = false
    }
    return (
      <Layout loading={loading}>
        {this.state.recovery && (
          <div className="recovery">
            <h1>Definir Senha ou Recuperar Senha</h1>
            <p>
              Indica o e-mail associado à tua wallet MUDEY. De seguida enviamos instruções, para o
              teu email, para definires uma nova senha.
            </p>

            <div className="form">
              <label htmlFor="login">Email</label>
              <input
                className="input-md"
                id="login"
                onChange={v => {
                  this.setState({ recoveryEmail: v.target.value })
                }}
                type="text"
                value={recoveryEmail}
              />
              <div>
                <h2><u style={{ cursor: 'pointer',marginBottom:10 }} onClick={() => this.setState({ recovery: false })}>
                Ir para a página de Login
                </u>
                </h2>
              </div>
            </div>
            <button
              className="button-solid"
              disabled={isSubmitButtonDisabled}
              onClick={this.forgotPassword}
              style={{ background: isSubmitButtonDisabled ? '#dedede' : null }}>
              Recuperar Password
            </button>
          </div>
        )}
        {this.state.isLogin && !this.state.recovery && (
          <div>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{process.env.REACT_APP_NAME} | Login</title>
            </Helmet>
            <h1>Entrar na Wallet MUDEY</h1>
            <h2 style={{ cursor: 'pointer' }}>
              Se subscreveste um Seguro com a MUDEY, e é a primeira vez que estás a aceder, deves
              definir uma senha de acesso.&nbsp;
              <u onClick={() => this.setState({ recovery: true })} style={{ cursor: 'pointer' }}>
                Clica aqui para definires a senha{' '}
              </u>
            </h2>
            <h2 onClick={() => this.goTo('/registar')} style={{ cursor: 'pointer',marginBottom:'10px' }}>
                <u>Ainda não tens uma Wallet? Clica aqui para criares</u>
              </h2>
           

            <div className="form">
              <label htmlFor="login">Email</label>
              <input
                className="input-md"
                id="login"
                onChange={v => {
                  this.setState({ login: v.target.value })
                }}
                type="text"
              />

              <label htmlFor="password">Password</label>
              <Input
                size="large"
                name="password"
                className="input-password"
                id="password"
                type={this.state.eyeOpen ? 'text' : 'password'}
                value={this.state.password}
                onChange={v => {
                  this.setState({ password: v.target.value })
                }}
                suffix={
                  this.state.eyeOpen ? (
                    <EyeOutlined onClick={() => this.setState({ eyeOpen: false })} />
                  ) : (
                    <EyeInvisibleOutlined onClick={() => this.setState({ eyeOpen: true })} />
                  )
                }
              />
              <span
                className="forgot"
                onClick={() => {
                  this.setState({ recovery: true })
                }}
                style={{ cursor: 'pointer',fontSize:'16px' }}>
                Esqueceste a password? Clica aqui para definires uma nova senha.
              </span>
             
              <table className="btn-table">
                <tbody>
                  <tr>
                    <td>
                      <button
                        className="button-solid"
                        disabled={disabledLoginBtn}
                        onClick={this.login}
                        style={{ background: disabledLoginBtn ? '#dedede' : null }}>
                        Entrar
                      </button>
                    </td>
                    {/* <td className="login-or-td" style={{ width: '66px', textAlign: 'center' }}>
                      ou
                    </td>
                    <td>
                       <img
                        alt="facebook"
                        onClick={this.fbLogin}
                        src={require('../../images/icons/f.svg')}
                      /> 
                      <img
                        alt="google"
                        onClick={this.gLogin}
                        src={require('../../images/icons/g.svg')}
                      />
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (payload: any) => dispatch({ type: 'SET_USER_DATA', payload })
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Auth)

Auth.propTypes = {
  history: PropTypes.any.isRequired,
  setUserData: PropTypes.any.isRequired,
  // eslint-disable-next-line
  location: PropTypes.any
}
