import PropTypes from 'prop-types'
/* eslint-disable no-useless-escape */
import React from 'react'

class ArticleDetails extends React.Component {
  constructor(props) {
    super(props)

    this.article_id = props.match.params.id

    this.state = {
      data: null
    }
  }

  componentDidMount() {
    // axios.get('/service/articles/' + this.article_id).then((response) => {
    //   this.setState({ data: response.data })
    //   let content = response.data.parsed_content
    //   content = content.replace(/\<figure class\=\"media\"\>/g, '')
    //   content = content.replace(/\<figure class\=\"image\"\>/g, '')
    //   content = content.replace(/\<\/figure\>/g, '')
    //   content = content.replace('frameborder="0"/>', 'frameborder="0"></iframe>')
    //   document.querySelector('.article-content').innerHTML = content
    // })
  }

  getContent() {
    if (this.state.data) {
      let content = this.state.data.parsed_content
      content = content.replace(/\<figure class\=\"media\"\>/g, '')
      content = content.replace(/\<figure class\=\"image\"\>/g, '')
      content = content.replace(/\<\/figure\>/g, '')
      document.querySelector('.article-content').innerHTML = content
      return { __html: content }
    }
  }

  render() {
    return (
      <div>
        <div
          className="back-arrow"
          onClick={() => {
            this.props.history.push('/hc')
          }}>
          <img alt="left" src={require('../../images/icons/arrow-left.svg')} />
        </div>

        {this.state.data && (
          <div>
            <h1>{this.state.data.title}</h1>
            <div className="article-content" />
          </div>
        )}
      </div>
    )
  }
}

ArticleDetails.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired
}
export default ArticleDetails
